<template>
  <a-modal
      title="分类详情"
      :visible="visible"
      :footer="null"
      @cancel="close"
  >
    <p v-for="(item,index) in data" :key="index">{{ item.Type }} {{ item.Number }} QB</p>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      data: []
    }
  },
  methods: {
    open(data) {
      this.visible = true
      this.data = data
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">

</style>