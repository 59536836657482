<template>
  <div>
    <a-modal
        title="奖金详情"
        :visible="visible"
        :footer="null"
        @cancel="handleCancel"
        :width="900"
    >
      <a-table :columns="columns" :data-source="data" :pagination="false"
               :rowKey="(record, index) => { return index }"
               class="table"
               :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,getCheckboxProps:ongetCheckboxProps }"
               :loading="loading">
        <span slot="TransAmount" slot-scope="tags">
          {{ tags }}元
        </span>
        <span slot="InShow" slot-scope="tags,record">
             <a-tag color="red" v-if="record.InShow=='未发起奖金派发'">{{ record.InShow }}</a-tag>
             <a-tag color="orange" v-if="record.InShow=='未发放'">{{ record.InShow }}</a-tag>
             <a-tag color="green" v-if="record.InShow=='已发放'">{{ record.InShow }}</a-tag>
        </span>
        <span slot="PayClass" slot-scope="tags,record">
                    <a-tooltip>
                    <template slot="title">
                      <p>转账订单号:</p>
                      <p>{{record.OrderId}}</p>
                    </template>
                    {{tags}}
                  </a-tooltip>
        </span>
        <span slot="PayTime" slot-scope="tags">
                 {{tags}}
        </span>



      </a-table>
      <span style="margin-left: 8px;margin-top: 20px">
        <template>
          <span>已选中 <b>{{ selectedRowKeys.length }}</b> 人</span> <span>总金额 <b>{{ TolBonus }}</b> 元</span>
        </template>
      </span>
      <a-button type="link" @click="getBonus" style="position: absolute;right: 0;bottom: 22px"
                v-if="InShow=='签署完成'">批量奖金发放
      </a-button>
    </a-modal>
    <PayData ref="PayData"></PayData>
  </div>
</template>
<script>
import DistData from "../moble/DistData";
import PayData from './PayData.vue'
import {getfindContData, Payalipay} from "@/api/Admin/ObjectDetails/Bonus";

let {columns} = DistData
export default {
  data() {
    return {
      columns,
      visible: false,
      loading: false,
      data: [],
      TolBonus: 0,
      id: '',
      InShow: '',
      selectedRowKeys: []
    };
  },
  methods: {
    ongetCheckboxProps(record) {
      return {
        props: {
          disabled: record.InShow != '未发放',
        },
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      let TolBonus = 0
      let data = this.data
      for (let i in selectedRowKeys) {
        let k = selectedRowKeys[i]
        let ToNumber = data[k].TransAmount
        TolBonus = Number((ToNumber + TolBonus).toFixed(2))
      }
      this.TolBonus = TolBonus
    },
    //批量发放奖金
    getBonus() {
      let that=this
      if (this.InShow != '签署完成') {
        this.$message.error('请完成签署流程才能进行奖金发放');
      } else {
        let selectedRowKeys = this.selectedRowKeys
        if (selectedRowKeys.length == 0) {
          this.$message.error('请选择用户');
          return
        }
        let addData = []
        let data = this.data
        for (let i in selectedRowKeys) {
          let k = selectedRowKeys[i]
          addData.push({
            Bonus_ID: data[k].id,
          })
        }

        this.$confirm({
          title: '是否确认发放奖金吗?',
          onOk() {
            Payalipay({data: addData}).then(res => {
              that.$message.success('操作成功');
              that.opanPaySum(res.payload)
              that.getData()
            }).catch(() => {
              that.getData()
            })
          }
        })
      }
    },
    opanPaySum(data){
      this.$refs.PayData.open(data)
      this.getdata()
      this.selectedRowKeys=[]
    },
    //打开
    open(id, InShow) {
      this.InShow = InShow
      this.visible = true;
      this.id = id
      this.getdata()
    },
    getdata() {
      let Object_ID = this.id
      this.loading = true

      getfindContData(Object_ID).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload
          for (let i in data) {
            let time = data[i].PayTime
            let day = new Date(time);
            data[i].PayTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()+' '+day.getHours()+':'+day.getMinutes()
          }
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    opendata(data) {
      this.$refs.ClassDetails.open(data)
    },
    handleCancel() {
      this.visible = false;
      this.data = []
    },
  },
  components: {PayData}
};
</script>

<style lang="scss">
.table {
  height: 400px;
  margin-bottom: 20px;
}
</style>
