import request from '../../../utils/request';

//查询奖金列表
export const getBonusList = (CompanyID, ObjectID, skip) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Admin/Object/Details/Bonus/find/CompanyID/${CompanyID}/ObjectID/${ObjectID}/skip/${skip}`,
        method: 'get'
    });
};

//查询奖金对象
export const getFindObject = (id) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Admin/Object/Details/Bonus/find/Object/id/${id}`,
        method: 'get'
    });
};

//查询用户奖金详情
export const getfindContData = (id) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Admin/Object/Details/Bonus/find/ContData/id/${id}`,
        method: 'get',
    });
};

//生成文件
export const generateFile = (query) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Admin/Object/Details/Bonus/generateFile`,
        method: 'post',
        data: query
    });
};


//一步发起签署
export const InitiateObject = (query) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Admin/Object/Details/Bonus/InitiateObject`,
        method: 'post',
        data: query
    });
};


//取消&终止签署
export const ExitConfirm = (query) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Admin/Object/Details/Bonus/ExitConfirm`,
        method: 'post',
        data: query
    });
};

//签署用户状态
export const ConfirmUserList = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Bonus/ConfirmUserList',
        method: 'post',
        data: query
    });
};

//确认签署并更新状态
export const Confirm = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Bonus/Confirm',
        method: 'post',
        data: query
    });
};


//查看签署文件
export const lookFlowId = (id) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Admin/Object/Details/Bonus/lookFlowId/id/${id}`,
        method: 'get'
    });
};

//查看生成文件
export const LookFileId = (id) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Admin/Object/Details/Bonus/lookFileId/id/${id}`,
        method: 'get'
    });
};



export const Payalipay = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Bonus/Pay/alipay',
        method: 'post',
        data: query
    });
};

