import request from '../../utils/request';

//查询签署机构
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Enterprise/SignMech/find',
        method: 'get',
        params: query
    });
};

//添加签署机构
export const add = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Enterprise/SignMech/add',
        method: 'post',
        data: query,
        timeout: 30000
    });
};

//删除签署机构
export const deletes = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Enterprise/SignMech/delete',
        method: 'post',
        data: query,
    });
};