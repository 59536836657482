
export default {
    columns:[
        {
            title: '姓名',
            key: 'Name',
            dataIndex: 'Name',
        },
        {
            title: '职位',
            key: 'Class',
            dataIndex: 'Class',
        },
        {
            title: '手机号码',
            key: 'Phone',
            dataIndex: 'Phone',
        },
        {
            title: '添加时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
