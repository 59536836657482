<template>
  <div>
    <a-modal
        title="编辑标语"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="标语">
          <a-input
              placeholder="请输入标语"
              v-decorator="[
                            'Slogan',
                            {  rules: [{ message: '请输入标语' }] }
             ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
    };
  },
  methods: {
    //打开
    open() {
      this.visible = true;
      this.form.resetFields()
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Slogan = values.Slogan
          this.$parent.EditSlogan(Slogan)
          this.handleCancel()
        }
      })
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>
