<template>
  <div>
    <a-modal
        title="编辑项目报道"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="标题">
          <a-input
              placeholder="请输入标题"
              v-decorator="[
                            'Title',
                            {  rules: [{ required: true, message: '请输入标题' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="请输入文本">
          <a-input
              placeholder="请输入文本"
              v-decorator="[
                            'Subtitle',
                            {  rules: [{ required: true, message: '请输入文本' }] }
             ]"
          />
        </a-form-item>

        <a-form-item label="链接地址">
          <a-input
              placeholder="请输入链接地址"
              v-decorator="[
                            'ToUrl',
                            {  rules: [{ required: true, message: '请输入链接地址' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="封面图">
          <a-upload
              name="file"
              :multiple="false"
              @change="AddImage"
              :action="ImageUrl"
              :headers="{
                  'X-Requested-With':null
              }"
          >
            <a-button>
              <a-icon type="upload"/>
              上传图片
            </a-button>
          </a-upload>
          <img :src="LogoImg" v-if="LogoImg" alt="" style="width: 200px"/>
        </a-form-item>
        <a-form-item label="日期">
          <a-date-picker
              v-decorator="[
                            'Time',
                            {  rules: [{ required: true, message: '请选择日期' }] }
             ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>


import {edit} from "@/api/Admin/ObjectDetails/Report";
import moment from "moment/moment";

export default {
  data() {
    return {
      LogoImg:'',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      User: [],
      ImageUrl: process.env.VUE_APP_URL + 'Enterprise/Company/image',
      id: ''
    };
  },
  methods: {
    open(value) {
      this.visible = true
      this.id = value.id
      setTimeout(() => {
        this.LogoImg = value.ImageUrl
        this.form.setFieldsValue(
            {
              Time: moment(value.Time, 'YYYY-MM-DD'),
              Title: value.Title,
              Subtitle: value.Subtitle,
              ToUrl: value.ToUrl,
            }
        )
      })
    },
    AddImage(info) {
      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          let url = response.payload
          this.LogoImg = url
        }
      }
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (!this.LogoImg) {
            this.$message.error('未添加logo图片');
            return
          }

          let id=this.id

          let time = values.Time._d
          let day = new Date(time);
          let Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()

          let ImageUrl = this.LogoImg
          let Title = values.Title
          let Subtitle = values.Subtitle
          let ToUrl = values.ToUrl

          this.confirmLoading = true

          edit({ImageUrl, Title, Subtitle, ToUrl, Time,id}).then(res => {
            this.confirmLoading = false
            if (res.errorCode == 200) {
              this.$message.success(res.payload);
              this.$parent.getdata()
              this.visible = false
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      })

    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
.button {
  display: block;
}
</style>