<template>
  <div class="Object">
    <div class="topBlock"></div>
    <div>
      <a-button class="add" @click="openadd">新增</a-button>
    </div>
    <a-table :columns="columns" :data-source="data"
             :loading="loading"
             :pagination="false"
             :rowKey="(record, index) => { return index }"
             class="table">
      <span slot="Operate" slot-scope="text,record">
        <a-button type="link" @click="openEdit(record)">编辑</a-button>
        <a-button class="red" type="link" @click="deletes(record.id)">删除</a-button>
      </span>
    </a-table>
    <add ref="add" :isEdit="false" @child-event="getdata"></add>
    <add ref="edit" :isEdit="true" @child-event="getdata"></add>
  </div>
</template>

<script>
import add from './com/add.vue'
import indexTest from "@/pages/Admin/pages/ObjectDetails/Com/Vote/moble/indexTest";
import {deleteset, find} from "../../../../../../api/Admin/ObjectDetails/Vote";

let {columns, isVoteData} = indexTest

export default {
  data() {
    return {
      columns,
      isVoteData,
      loading: false,
      data: [],
    }
  },
  components: {add},
  mounted() {
    this.getdata()
  },
  methods: {
    openEdit(data) {
      this.$refs.edit.open(data)
    },
    deletes(id) {
      let that = this
      this.$confirm({
        title: '是否确认删除该投票吗?',
        onOk() {
          deleteset({id}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload)
              that.getdata()
            } else {
              that.$message.error(res.errorMsg)
            }
          })
        },
      });
    },
    openadd() {
      this.$refs.add.open()
    },
    getdata() {
      this.loading = true
      let CompanyID = this.$store.state.user.info.CompanyID
      let ProjectID = this.$route.query.Id
      this.data = []
      find({CompanyID, ProjectID}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload
          for (let i in data) {
            let time = data[i].NewTime
            let day = new Date(time);
            data[i].NewTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
            let isVote = data[i].isVote;
            let statusMatch = this.isVoteData.find(item => item.value == isVote);
            data[i].isVoteData = statusMatch.label;
          }
          this.data = data
        } else {
          this.$message.error(res.errorMsg)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.Object {
  width: 100%;
  height: 600px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }

  .add {
    margin-left: 20px;
    margin-bottom: 15px;
  }

  .table {
    width: 98%;
    height: 500px;
    margin: 0 auto;
  }
}

.red {
  color: red;
}

</style>
