<template>
  <div>
    <a-modal
        title="添加地址"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        :keyboard="false"
        :maskClosable="false"
    >
      <a-form :form="form">
        <a-form-item label="地址" :rules="[{ required: true, message: '请选择地址' }]">
          <a-cascader
              @change="onChange"
              :options="addressData"
              v-model="selectedAddress"
              placeholder="请选择地址"
              change-on-select
          />
        </a-form-item>
        <a-form-item label="详细地址">
          <a-input
              placeholder="请填写详细地址"
              v-decorator="[
                            'detail',
                            {  rules: [{required: true,message: '请填写详细地址' }] }
             ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="姓名">
          <a-input
              placeholder="请填写姓名"
              v-decorator="[
                            'name',
                            {  rules: [{required: true,message: '请填写姓名' }] }
             ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="联系方式">
          <a-input
              placeholder="请填写联系方式"
              v-decorator="[
                            'phone',
                            {  rules: [{required: true,message: '请填写联系方式' }] }
             ]"
          >
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import address from '../../../../utils/address'
import notification from 'ant-design-vue/es/notification';

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      selectedAddress: [],
      addressData: address,
    };
  },
  methods: {
    onChange(value) {
      console.log(value)
    },
    getkeyadders(keyword) {
      for (let i in address) {
        let I_index = address[i]
        if (keyword == I_index.value) {
          return I_index.label
        }
        let children = I_index.children
        for (let k in children) {
          if (children[k].value == keyword) {
            return children[k].label
          }
          let children_2 = children[k].children
          for (let j in children_2) {
            if (children_2[j].value == keyword) {
              return children_2[j].label
            }
          }
        }
      }
    },
    //打开
    open() {
      this.visible = true;
      this.selectedAddress = []
      this.form.resetFields()
    },
    //新增地址
    handleOk() {
      let selectedAddress = this.selectedAddress
      if (selectedAddress.length >= 3) {
        //根据地址编号转换文字
        let Address_1 = selectedAddress[0]
        let Address_2 = selectedAddress[1]
        let Address_3 = selectedAddress[2]

        Address_1 = this.getkeyadders(Address_1)
        Address_2 = this.getkeyadders(Address_2)
        Address_3 = this.getkeyadders(Address_3)

        this.form.validateFields(async (err, values) => {
          if (!err) {
            let detail = values.detail
            let name = values.name
            let phone = values.phone

            if (!this.isValidChineseMobile(phone)){
              notification.error({message: '手机号码格式错误', description: '系统'});
              return
            }


            console.log(Address_1, Address_2, Address_3)
            console.log(detail, name, phone)
          }
        })
      } else {
        notification.error({message: '请选择省市区', description: '系统'});
      }
    },
    //判断手机号码是否合法
    isValidChineseMobile(phoneNumber) {
      // 正则表达式，包括主要的手机号码段
      const regex = /^1(3|4|5|6|7|8|9)\d{9}$/;

      // 检查是否匹配主要长度和开头数字
      if (!regex.test(phoneNumber)) {
        return false;
      }

      // 进一步精确匹配具体运营商的号码段
      const detailedRegex = /^(13[0-9]|14[5-9]|15[0-3,5-9]|166|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
      return detailedRegex.test(phoneNumber);
    },
//取消并关闭
    handleCancel() {
      this.visible = false;
      this.form.resetFields()
    }
  },
};
</script>
