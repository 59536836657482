<template>
  <div>
    <a-modal
        title="添加机构"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="机构昵称">
          <a-input
              placeholder="请输入机构昵称"
              v-decorator="[
                                'CompanyName',
                                {  rules: [{ required: true, message: '请输入机构昵称' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="负责人姓名">
          <a-input
              placeholder="请输入负责人姓名"
              v-decorator="[
                                'UserName',
                                {  rules: [{ required: true, message: '请输入法定代表人' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="负责人联系方式">
          <a-input
              placeholder="请输入负责人联系方式"
              v-decorator="[
                                'UserPhone',
                                {  rules: [{ required: true, message: '请输入法定代表人联系方式' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="社会信用代码">
          <a-input
              placeholder="社会信用代码"
              v-decorator="[
                                'ThirdPartyUserId',
                                {  rules: [{ required: true, message: '请输入社会信用代码' }] },
                            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import {add} from "@/api/Enterprise/SignMech";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      LogoImg: ''
    };
  },
  methods: {
    //打开
    open() {
      this.visible = true;
      this.form.resetFields()
      this.LogoImg=''
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let CompanyName = values.CompanyName
          let UserName = values.UserName
          let UserPhone = values.UserPhone
          let ThirdPartyUserId = values.ThirdPartyUserId

          this.confirmLoading = true;
          add({ CompanyName, UserName,UserPhone,ThirdPartyUserId}).then(res => {
            this.confirmLoading = false;
            this.visible = false;
            if (res.errorCode == 200) {
              this.$message.success(res.payload);
              this.$parent.getdata()
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>
