<template>
  <div>
    <a-modal
        title="选择企业"
        :visible="visible"
        @cancel="handleCancel"
        :footer="null"
        :width="800"
    >
      <a-table :columns="columns" :data-source="data" :pagination="false"
               :rowKey="(record, index) => { return index }"
               class="table"

      >
        <span slot="CompanyLogo" slot-scope="tags">
            <a-avatar :size="30" icon="user" shape="square" :src="tags"/>
        </span>
        <span slot="CompanyName" slot-scope="tags,text">
           {{ tags }}<span class="CompanyName" v-if="text.CompanyID==CompanyID">主企业</span>
        </span>
        <!--        :disabled="tags.CompanyID=CompanyID"-->
        <span slot="Operate" slot-scope="tags,text">
             <a-button type="link" html-type="submit" @click="enter(text)"
                       :disabled="text.CompanyID==CompanyID">选择该企业</a-button>
        </span>
      </a-table>
    </a-modal>
  </div>
</template>
<script>


export default {
  data() {
    return {
      visible: false,
      CompanyID: '',
      data: [],
      columns: [
        {
          title: 'Logo',
          key: 'CompanyLogo',
          dataIndex: 'CompanyLogo',
          scopedSlots: {customRender: 'CompanyLogo'},
        },
        {
          title: '企业昵称',
          key: 'CompanyName',
          dataIndex: 'CompanyName',
          scopedSlots: {customRender: 'CompanyName'},
        },
        {
          title: '操作',
          key: 'Operate',
          dataIndex: 'Operate',
          scopedSlots: {customRender: 'Operate'},
        },
      ]
    };
  },
  mounted() {
    let info = this.$store.state.user.info
    this.CompanyID = info.CompanyID;
  },
  methods: {
    enter(value) {
      let that = this
      this.$confirm({
        title: '是否切换该企业吗?',
        onOk() {
          let info = JSON.parse(JSON.stringify(value))
          that.$store.state.user.info = info
          localStorage.setItem('info', JSON.stringify(info));
          that.handleCancel()
          that.$message.success('切换成功');
          setTimeout(() => {
            location.reload();
          }, 200)
        },
      });


    },
    //打开
    open(data) {
      this.visible = true;
      this.data = data
    },
    //取消并关闭
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
.table {
  height: 400px;
}

.CompanyName {
  background-color: #ff8310;
  color: #ffffff;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 10px;
}
</style>
