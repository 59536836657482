var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"confirm-loading":_vm.confirmLoading,"keyboard":false,"maskClosable":false,"visible":_vm.visible,"title":"新增投票","width":"900px"},on:{"cancel":_vm.handleCancel,"ok":_vm.handleOk}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Title',
                          {  rules: [{ required: true, message: '请输入项目标题' }] }
           ]),expression:"[\n                          'Title',\n                          {  rules: [{ required: true, message: '请输入项目标题' }] }\n           ]"}],attrs:{"placeholder":"请输入项目标题"}})],1),_c('a-form-item',{attrs:{"label":"活动类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'isVote',
                          {  rules: [{ required: true, message: '请选择活动类型' }] }
           ]),expression:"[\n                          'isVote',\n                          {  rules: [{ required: true, message: '请选择活动类型' }] }\n           ]"}],staticStyle:{"margin-right":"15px","width":"220px"},attrs:{"placeholder":"请选择活动类型"},on:{"change":_vm.changeisVote}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v("海报")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v("视频")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v("视频+海报")]),_c('a-select-option',{attrs:{"value":4}},[_vm._v("视频+普通文本")]),_c('a-select-option',{attrs:{"value":5}},[_vm._v("海报+普通文本")]),_c('a-select-option',{attrs:{"value":6}},[_vm._v("普通文本")])],1)],1),_c('a-form-item',{attrs:{"label":"活动时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'EventTime',
                          {  rules: [{ required: true, message: '请选择活动时间' }] }
           ]),expression:"[\n                          'EventTime',\n                          {  rules: [{ required: true, message: '请选择活动时间' }] }\n           ]"}],attrs:{"disabledDate":_vm.disabledDate}})],1),_c('a-form-item',{attrs:{"label":"地点类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'isMap',
                          {  rules: [{ required: true, message: '请选择地点类型' }] }
           ]),expression:"[\n                          'isMap',\n                          {  rules: [{ required: true, message: '请选择地点类型' }] }\n           ]"}],staticStyle:{"margin-right":"15px","width":"220px"},attrs:{"placeholder":"请选择地点类型"}},[_c('a-select-option',{attrs:{"value":"虚拟"}},[_vm._v("虚拟")]),_c('a-select-option',{attrs:{"value":"现实"}},[_vm._v("现实")])],1)],1),_c('a-form-item',{attrs:{"label":"地点"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Address',
                          {  rules: [{ required: true, message: '请输入地点' }] }
           ]),expression:"[\n                          'Address',\n                          {  rules: [{ required: true, message: '请输入地点' }] }\n           ]"}],attrs:{"placeholder":"请输入地点"}})],1),(_vm.isVote==1||_vm.isVote==3||_vm.isVote==5)?_c('a-form-item',{attrs:{"label":"海报"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'ImageposterFile',
                          {  rules: [{ required: true, message: '请上传海报' }] }
           ]),expression:"[\n                          'ImageposterFile',\n                          {  rules: [{ required: true, message: '请上传海报' }] }\n           ]"}],attrs:{"action":_vm.ImageUrl,"headers":{
                'X-Requested-With':null
            },"multiple":false,"showUploadList":false,"accept":".jpg,.png"},on:{"change":_vm.handleChange}},[_c('a-button',{staticStyle:{"margin-bottom":"30px"}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传海报 ")],1)],1),(_vm.ImageposterFile)?_c('img',{staticStyle:{"width":"100px","display":"block","margin-bottom":"10px"},attrs:{"src":_vm.ImageposterFile,"alt":""}}):_vm._e()],1):_vm._e(),(_vm.isVote==2||_vm.isVote==3||_vm.isVote==4)?_c('a-form-item',{attrs:{"label":"视频"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'VideoFile',
                          {  rules: [{ required: true, message: '请上传视频' }] }
           ]),expression:"[\n                          'VideoFile',\n                          {  rules: [{ required: true, message: '请上传视频' }] }\n           ]"}],attrs:{"action":_vm.VideoUrl,"beforeUpload":_vm.beforeUpload,"headers":{
                'X-Requested-With':null
            },"multiple":false,"showUploadList":false,"accept":".mp4"},on:{"change":_vm.handleChange1}},[_c('a-button',{staticStyle:{"margin-bottom":"30px"},attrs:{"loading":_vm.VideoFileLoading}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传视频 ")],1)],1),(_vm.VideoFile)?_c('video',{staticStyle:{"width":"400px","display":"block","margin-bottom":"10px"},attrs:{"src":_vm.VideoFile,"alt":"","controls":""}}):_vm._e()],1):_vm._e(),(_vm.isVote==4||_vm.isVote==5||_vm.isVote==6)?_c('a-form-item',{attrs:{"label":"普通文本"}},[_c('div',{ref:"editorContainer",staticClass:"editorContainer"})]):_vm._e(),_c('a-form-item',{attrs:{"label":"投票选项"}},[_c('a-button',{on:{"click":_vm.addObjectOptions}},[_vm._v("新增")]),_vm._l((_vm.ObjectOptions),function(item,index){return _c('div',{key:index,staticClass:"block"},[_c('a-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入投票选项"},model:{value:(_vm.ObjectOptions[index].text),callback:function ($$v) {_vm.$set(_vm.ObjectOptions[index], "text", $$v)},expression:"ObjectOptions[index].text"}}),_c('a-icon',{staticClass:"red",attrs:{"type":"delete"},on:{"click":function($event){return _vm.deleteObjectOptions(index)}}})],1)})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }