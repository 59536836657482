
export default {
    columns:[
        {
            title: '头像',
            dataIndex: 'Avatar',
            key: 'Avatar',
            scopedSlots: { customRender: 'Avatar' },
        },
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '手机号码',
            key: 'Phone',
            dataIndex: 'Phone',
        },
        {
            title: '贡献值',
            key: 'ToNumber',
            dataIndex: 'ToNumber',
            scopedSlots: { customRender: 'ToNumber' },
        },
        {
            title: '添加时间',
            key: 'AddTime',
            dataIndex: 'AddTime',
        },
        {
            title: '登录时间',
            key: 'LoginTime',
            dataIndex: 'LoginTime',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
