<template>
  <div class="Object">
    <div class="topBlock"></div>
    <div class="topNav">
      <a-button type="primary" class="search-btn" @click="openAddModel" icon="plus">
        添加合同
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             class="table"
             :rowKey="(record, index) => { return index }"
             :loading="loading">
       <span slot="InShow" slot-scope="tags">
               <a-tag v-if="tags=='待发起'" color="yellow">待发起</a-tag>
               <a-tag v-else-if="tags=='签署中'" color="blue">签署中</a-tag>
               <a-tag v-else-if="tags=='已取消'" color="red">已取消</a-tag>
               <a-tag v-else-if="tags=='签署完成'" color="green">签署完成</a-tag>
       </span>
      <span slot="operation" slot-scope="text,record">
       <a-button type="link" @click="openDetails(record.id)" icon="message">详情</a-button>
     </span>
    </a-table>
    <AddModel ref="AddModel" :isEdit="false"></AddModel>
    <Details ref="Details"></Details>
  </div>
</template>

<script>
import AddModel from './com/addModel.vue'
import indexTest from "./moble/index";
import Details from './com/details.vue'
import {find} from "@/api/Contract/List";

let {columns} = indexTest

export default {
  data() {
    return {
      columns: columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
      commodityDetail: {}
    }
  },
  components: {AddModel, Details},
  mounted() {
    this.getData();
  },
  methods: {
    openDetails(e) {
      this.$refs.Details.open(e)
    },
    openAddModel() {
      this.$refs.AddModel.open();
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getData();
    },
    getData() {
      this.loading = true
      let CompanyID = this.$store.state.user.info.CompanyID
      let skip = this.pagination.current
      find({CompanyID, skip}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          let total = res.payload.total

          for (let i in data) {
            let NewTime = data[i].NewTime
            let day = new Date(NewTime);
            data[i].NewTime = this.A_USA_TIME_COM(day)

            let EndTime = data[i].EndTime
            if (EndTime){
              let days = new Date(EndTime);
              data[i].EndTime = this.A_USA_TIME_COM(days)
            }
          }

          this.data = data
          this.pagination.total = total
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    A_USA_TIME_COM(days) {
      var date = new Date(days);
      var year = date.getFullYear();  //年
      var month = date.getMonth() + 1;  //月份
      var day = date.getDate();    //天
      var hour = date.getHours();  //小时
      var minute = date.getMinutes();  //分钟
      var second = date.getSeconds();  //秒数
      return year + '-' + (String(month).length > 1 ? month : '0' + month) + '-' +
          (String(day).length > 1 ? day : '0' + day) + ' ' + (String(hour).length > 1 ? hour : '0' + hour) + ':' + (String(minute).length > 1 ? minute : '0' + minute)
          + ':' + (String(second).length > 1 ? second : '0' + second)
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }

  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }


}

.table {
  width: 98%;
  margin: 0 auto;
  height: 700px;
  overflow-y: auto;
}

.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}
</style>
  