<template>
  <div class="Distribution">
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             :rowKey="(record, index) => { return index }"
             class="table"
             :loading="loading">
        <span slot="InShow" slot-scope="tags">
          <a-badge v-if="tags=='待生成'" status="warning" text="待生成"/>
          <a-badge v-if="tags=='已生成'" status="warning" text="已生成"/>
          <a-badge v-if="tags=='签署中'" status="processing" text="签署中"/>
          <a-badge v-if="tags=='已取消'" status="error" text="已取消"/>
          <a-badge v-if="tags=='签署完成'" status="success" text="签署完成"/>
    </span>
      <span slot="Operate" slot-scope="text,record">
                <a-button type="link" html-type="submit" @click="openDetails(record.id,record.InShow)">操作</a-button>
      </span>
    </a-table>
    <Sign ref="Sign"></Sign>
  </div>
</template>

<script>
import indexTest from "@/pages/Admin/pages/ObjectDetails/Com/Bonus/moble/indexTest";
import {getBonusList} from "@/api/Admin/ObjectDetails/Bonus";
import Sign from '@/pages/Admin/pages/ObjectDetails/Com/Bonus/com/Sign.vue'

let {columns} = indexTest
export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    openDetails(id){
      this.$refs.Sign.open(id)
    },
    getdata() {
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.$route.query.Id
      let skip = this.pagination.current
      this.loading = true
      getBonusList(CompanyID, ObjectID, skip).then(res => {
        this.loading = false
        let payload = res.payload
        this.pagination.total = payload.total
        let data = payload.data

        for (let i in data) {
          let time = data[i].NewTime
          let day = new Date(time);
          data[i].NewTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
        }
        this.data = data
      })
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
  },
  components:{Sign}
}
</script>

<style scoped lang="scss">

.Distribution {
  width: 100%;
  height: 600px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 10px;
  }

  .table {
    width: 98%;
    margin: 0 auto;
  }
}

.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}
</style>
