
// Contract  普通经办合同

export default {
    columns:[
        {
            title: '合同名称',
            key: 'Title',
            dataIndex: 'Title',
        },
        {
            title: '经办人员',
            key: 'User_Name',
            dataIndex: 'User_Name',
        },
        {
            title: '状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },
        {
            title: '新增时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
        },
        {
            title: '归档时间',
            key: 'EndTime',
            dataIndex: 'EndTime',
        },
        {
            title: '操作',
            key: 'operation',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
        }
    ]
}
