<template>
  <div>
    <a-modal
        title="贡献值详情"
        :visible="visible"
        :footer="null"
        @cancel="handleCancel"
        :width="1000"
    >
      <a-table :columns="columns" :data-source="data" :pagination="false"
               :rowKey="(record, index) => { return index }"
               class="table"
               :loading="loading">
          <span slot="ToNumber" slot-scope="tags">
            {{tags}}<div class="Token"><span class="img"></span></div>
          </span>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import indexTest from "@/pages/Admin/pages/User/com/indexTest";
import {UserDetailsData} from "@/api/Admin/User";

let {columns} = indexTest
export default {
  data() {
    return {
      columns,
      visible: false,
      loading: false,
      data: [],
    };
  },
  methods: {
    //打开
    open(id) {
      this.visible = true;
      this.id = id
      this.getdata()
    },
    getdata() {
      let id = this.id
      this.loading = true
      let CompanyID = this.$store.state.user.info.CompanyID
      UserDetailsData({id,CompanyID}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload

          for (let i in data) {
            let time = data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
            data[i].TolType=JSON.parse(data[i].TolType)
          }
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    handleCancel() {
      this.visible = false;
      this.data = []
    },
  },
};
</script>

<style lang="scss">
.table{
  width: 100%;
  height: 600px;
  overflow-y: auto;
}
.Token{
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  position: relative;
  .img{
    background-image: url("../../../../../assets/Token.png");
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 5px;
    display: inline-block;
  }
}
</style>
