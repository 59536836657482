var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Login"},[_c('div',{staticClass:"logo"}),_c('div',{staticClass:"main"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'Phone',
                            {  rules: [{ required: true, message: '请输入账号' }] }
             ]),expression:"[\n                            'Phone',\n                            {  rules: [{ required: true, message: '请输入账号' }] }\n             ]"}],attrs:{"placeholder":"请输入账号"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"label":"密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'Password',
                             {  rules: [{ required: true, message: '请输入密码' }] }
                        ]),expression:"[\n                            'Password',\n                             {  rules: [{ required: true, message: '请输入密码' }] }\n                        ]"}],attrs:{"placeholder":"请输入密码"}},[_vm._v(" > "),_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1)],1),_c('div',{staticClass:"nav"},[_c('div',{staticClass:"right"},[_c('a-button',{attrs:{"type":"link"},on:{"click":_vm.openForget}},[_vm._v(" 忘记密码 ")])],1)]),_c('a-button',{staticClass:"go-login",attrs:{"type":"primary","block":""},on:{"click":_vm.Login}},[_vm._v(" 登录 ")]),_c('a-button',{staticClass:"go-login",attrs:{"type":"link","block":""},on:{"click":_vm.openAdd}},[_vm._v(" 申请 ")])],1),_c('forget',{ref:"forget"}),_c('add',{ref:"add"}),_c('selectCompany',{ref:"selectCompany"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }