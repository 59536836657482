<template>
<div>
  <a-modal
      title="贡献值详情"
      :visible="visible"
      :footer="null"
      @cancel="close"
  >
    <p v-for="(item,index) in data" :key="index">{{ item.Type }} {{ item.Number }} OB</p>
  </a-modal>
</div>
</template>

<script>
export default {
  data(){
    return{
      visible:false,
      data:[]
    }
  },
  methods:{
    open(data){
      this.visible=true
      this.data=data
    },
    close(){
      this.visible=false
      this.data=[]
    }
  }
}
</script>


<style scoped lang="scss">

</style>
