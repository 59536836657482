
export default {
    columns:[
        {
            title: '商品编号',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '缩略图',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            scopedSlots: { customRender: 'commodityImage' },
        },
        {
            title: '名称',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '兑换贡献值',
            key: 'price',
            dataIndex: 'price',
        },
        {
            title: '剩余库存',
            key: 'remainNumber',
            dataIndex: 'remainNumber',
        },
        {
            title: '兑换权限',
            key: 'privilege',
            dataIndex: 'privilege',
            scopedSlots: { customRender: 'privilege' },
        },
        {
            title: '类型',
            key: 'class',
            dataIndex: 'class',
            scopedSlots: { customRender: 'class' },
        },
        {
            title: '操作',
            key: 'operation',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ],
    addModel:[
        {
            title: '商品编号',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '缩略图',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            scopedSlots: { customRender: 'commodityImage' },
        },
        {
            title: '名称',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '兑换贡献值',
            key: 'price',
            dataIndex: 'price',
        },
        {
            title: '剩余库存',
            key: 'remainNumber',
            dataIndex: 'remainNumber',
        },
        {
            title: '兑换权限',
            key: 'privilege',
            dataIndex: 'privilege',
            scopedSlots: { customRender: 'privilege' },
        },
        {
            title: '类型',
            key: 'class',
            dataIndex: 'class',
            scopedSlots: { customRender: 'class' },
        },
        {
            title: '盲盒类型',
            key: 'blindBox',
            dataIndex: 'blindBox',
            scopedSlots: { customRender: 'blindBox' },
        },
        {
            title: '操作',
            key: 'operation',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ],
    SelfBox:[
        {
            title: '地址',
            key: 'address',
            dataIndex: 'address',
        },
        {
            title: '添加时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
        },
        {
            title: '操作',
            key: 'operation',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ]
}
