import request from '../../utils/request';

//查询机构列表
export const findCompany = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Contract/List/find/company',
        method: 'get',
        params: query
    });
};

//查询合同
export const find = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Contract/List/find',
        method: 'get',
        params: query
    });
};

//查看生成文件
export const LookFileId = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Contract/List/find/LookFileId',
        method: 'get',
        params: query
    });
};


//查看签署文件
export const LookFlowId = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Contract/List/find/LookFlowId',
        method: 'get',
        params: query
    });
};


//查询合同详情
export const findid = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Contract/List/find/id',
        method: 'get',
        params: query
    });
};

//合同发起签署
export const startContract = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Contract/List/esign/start',
        method: 'post',
        data: query
    });
};

//归档合同
export const upContract = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Contract/List/esign/up',
        method: 'post',
        data: query
    });
};

//废止合同
export const stopContract = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Contract/List/esign/stop',
        method: 'post',
        data: query
    });
};

//创建合同
export const add = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Contract/List/add',
        method: 'post',
        data: query
    });
};
