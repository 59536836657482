<template>
  <div>
    <a-modal
        :confirm-loading="confirmLoading"
        :keyboard="false"
        :maskClosable="false"
        :visible="visible"
        title="新增投票"
        width="900px"
        @cancel="handleCancel"
        @ok="handleOk"
    >
      <a-form :form="form">
        <a-form-item label="标题">
          <a-input
              v-decorator="[
                            'Title',
                            {  rules: [{ required: true, message: '请输入项目标题' }] }
             ]"
              placeholder="请输入项目标题"
          />
        </a-form-item>
        <a-form-item label="活动类型">
          <a-select v-decorator="[
                            'isVote',
                            {  rules: [{ required: true, message: '请选择活动类型' }] }
             ]"
                    placeholder="请选择活动类型"
                    style="margin-right: 15px;width: 220px"
                    @change="changeisVote">
            <a-select-option :value="1">海报</a-select-option>
            <a-select-option :value="2">视频</a-select-option>
            <a-select-option :value="3">视频+海报</a-select-option>
            <a-select-option :value="4">视频+普通文本</a-select-option>
            <a-select-option :value="5">海报+普通文本</a-select-option>
            <a-select-option :value="6">普通文本</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="活动时间">
          <a-range-picker v-decorator="[
                            'EventTime',
                            {  rules: [{ required: true, message: '请选择活动时间' }] }
             ]"
                          :disabledDate="disabledDate"/>
        </a-form-item>

        <a-form-item label="地点类型">
          <a-select v-decorator="[
                            'isMap',
                            {  rules: [{ required: true, message: '请选择地点类型' }] }
             ]"
                    placeholder="请选择地点类型"
                    style="margin-right: 15px;width: 220px">
            <a-select-option value="虚拟">虚拟</a-select-option>
            <a-select-option value="现实">现实</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="地点">
          <a-input
              v-decorator="[
                            'Address',
                            {  rules: [{ required: true, message: '请输入地点' }] }
             ]"
              placeholder="请输入地点"
          />
        </a-form-item>
        <a-form-item v-if="isVote==1||isVote==3||isVote==5" label="海报">
          <a-upload
              v-decorator="[
                            'ImageposterFile',
                            {  rules: [{ required: true, message: '请上传海报' }] }
             ]"
              :action="ImageUrl"
              :headers="{
                  'X-Requested-With':null
              }"
              :multiple="false"
              :showUploadList="false"
              accept=".jpg,.png"
              @change="handleChange"
          >
            <a-button style="margin-bottom: 30px">
              <a-icon type="upload"/>
              上传海报
            </a-button>
          </a-upload>
          <img v-if="ImageposterFile" :src="ImageposterFile" alt=""
               style="width: 100px;display: block;margin-bottom: 10px"/>
        </a-form-item>

        <a-form-item v-if="isVote==2||isVote==3||isVote==4" label="视频">
          <a-upload
              v-decorator="[
                            'VideoFile',
                            {  rules: [{ required: true, message: '请上传视频' }] }
             ]"
              :action="VideoUrl"
              :beforeUpload="beforeUpload"
              :headers="{
                  'X-Requested-With':null
              }"
              :multiple="false"
              :showUploadList="false"
              accept=".mp4"
              @change="handleChange1"
          >
            <a-button :loading="VideoFileLoading" style="margin-bottom: 30px">
              <a-icon type="upload"/>
              上传视频
            </a-button>
          </a-upload>
          <video v-if="VideoFile" :src="VideoFile" alt="" controls
                 style="width: 400px;display: block;margin-bottom: 10px"/>
        </a-form-item>

        <a-form-item v-if="isVote==4||isVote==5||isVote==6" label="普通文本">
          <div ref="editorContainer" class="editorContainer"></div>
        </a-form-item>
        <a-form-item label="投票选项">
          <a-button @click="addObjectOptions">新增</a-button>
          <div v-for="(item,index) in ObjectOptions" :key="index" class="block">
            <a-input v-model="ObjectOptions[index].text" placeholder="请输入投票选项" style="width: 200px"></a-input>
            <a-icon class="red" type="delete" @click="deleteObjectOptions(index)"/>
          </div>
        </a-form-item>

      </a-form>
    </a-modal>

  </div>
</template>
<script>
import moment from 'moment'
import WangEditor from 'wangeditor'
import {add} from "@/api/Admin/ObjectDetails/Vote";

export default {
  data() {
    return {
      // 模式选择
      isVote: '',
      id: null,
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      ImageUrl: process.env.VUE_APP_URL + 'Website/Home/ImageAdd',
      VideoUrl: process.env.VUE_APP_URL + 'Website/Home/VideoAdd',
      editor: null,
      ContentText: null, // 用于保存编辑器内容
      ContentText1: null, // 用于保存编辑器内容的回显
      //海报地址
      ImageposterFile: '',
      //视频地址
      VideoFile: '',
      VideoFileLoading: false,
      fileList: [],
      //投票选项
      ObjectOptions: []
    };
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    }
  },
  components: {},
  mounted() {

  },
  methods: {
    deleteObjectOptions(e) {
      let that = this
      this.$confirm({
        title: '提示',
        content: '是否确认删除该投票选项吗？',
        centered: true,
        onOk() {
          let newArray = that.ObjectOptions
          newArray.splice(e, 1);
          that.ObjectOptions = newArray
          that.$message.success(`删除成功！`);
        },
      })
    },
    addObjectOptions() {
      this.ObjectOptions.push({text: null})
    },
    changeisVote(e) {
      this.isVote = e
      if (this.isVote == 4 || this.isVote == 5 || this.isVote == 6) {
        this.initializeEditor()
      } else {
        // 销毁编辑器实例
        if (this.editor) {
          this.editor.destroy()
          this.ContentText = null
        }
      }
    },
    //上传前
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        this.$message.error("文件大小不能超过20M");
        return new Promise((reslove, reject) => {
          reject();
        });
      }
    },
    formatDateTime(date) {
      function padZero(num) {
        return num < 10 ? '0' + num : num;
      }

      var year = date.getFullYear();
      var month = padZero(date.getMonth() + 1); // 月份是从0开始的
      var day = padZero(date.getDate());

      return year + '-' + month + '-' + day
    },
    disabledDate(current) {
      // 今天不可选
      // return current && current <= moment().endOf('day')
      // 今天可以选
      return current && current <= moment().subtract(1, 'days').endOf('day')
    },
    handleChange(info) {
      if (info.file.status === 'done') {
        let response = info.file.response
        let payload = response.payload
        this.ImageposterFile = payload
        this.$message.success(`${info.file.name} 上传成功!`);
        setTimeout(() => {
          this.form.setFieldsValue({
            ImageposterFile: payload
          })
        }, 500)
      } else if (info.file.status === 'error') {
        console.error('文件上传失败:', info.file.error);
      }
    },
    handleChange1(info) {
      this.VideoFileLoading = true
      if (info.file.status === 'done') {
        this.VideoFileLoading = false
        let response = info.file.response
        let payload = response.payload
        this.VideoFile = payload
        this.$message.success(`${info.file.name} 上传成功!`);
        setTimeout(() => {
          this.form.setFieldsValue({
            VideoFile: payload
          })
        }, 500)
      } else if (info.file.status === 'error') {
        console.error('文件上传失败:', info.file.error);
      }
    },
    initializeEditor() {
      if (this.editor == null) {
        setTimeout(() => {
          this.editor = new WangEditor(this.$refs.editorContainer)
          this.editor.config.onchange = (html) => {
            this.ContentText = html
          }
          this.editor.config.placeholder = '请输入内容...'
          this.editor.create() // 创建编辑器
          if (this.ContentText1) {
            // 回显的时候设置编辑器的内容
            this.editor.txt.html(this.ContentText1);
          }
        }, 500)
      }
    },
    beforeDestroy() {
      // 销毁编辑器实例
      this.editor.destroy()
    },
    //打开
    open(data) {
      this.visible = true;
      if (this.isEdit) {
        setTimeout(() => {
          //设置回显内容
          this.id = data.id
          let StartTime = data.StartTime
          let EndTime = data.EndTime
          let EventTime = [moment(StartTime), moment(EndTime)]
          data.EventTime = EventTime
          this.form.setFieldsValue({
            Title: data.Title,
            isMap: data.isMap,
            isVoteData: data.isVoteData,
            EventTime: data.EventTime,
            isVote: data.isVote,
            Address: data.Address,
            VideoFile: data.VideoFile ? data.VideoFile : null,
            ImageposterFile: data.ImageposterFile ? data.ImageposterFile : null
          })
          let ObjectOptions = data.ObjectOptions
          ObjectOptions = JSON.parse(ObjectOptions)
          this.ObjectOptions = ObjectOptions
          this.ContentText = data.ContentText ? data.ContentText : null
          this.ContentText1 = data.ContentText ? data.ContentText : null
          this.VideoFile = data.VideoFile ? data.VideoFile : null
          this.ImageposterFile = data.ImageposterFile ? data.ImageposterFile : null
          this.changeisVote(data.isVote)
        })
      }
    },
    handleOk() {
      let CompanyID = this.$store.state.user.info.CompanyID
      let ProjectID = this.$route.query.Id
      let id = this.id
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let EventTime = values.EventTime
          let StartTime = this.formatDateTime(new Date(EventTime[0]))
          let EndTime = this.formatDateTime(new Date(EventTime[1]))
          let ContentText = this.ContentText
          let ObjectOptions = JSON.parse(JSON.stringify(this.ObjectOptions))
          ObjectOptions = JSON.stringify(ObjectOptions)

          let query = {
            id,
            CompanyID,
            ProjectID,
            ...values,
            StartTime,
            EndTime,
            ContentText,
            ObjectOptions
          }
          add(query).then(res => {
            if (res.errorCode == 200) {
              this.$message.success(res.payload)
              this.visible = false
              this.$emit('child-event');
            } else {
              this.$message.error(res.errorMsg)
            }
          })

        }
      })
    },
    handleCancel() {
      this.visible = false;
      this.$parent.getdata()
    },
  },
};
</script>

<style lang="scss">
.button {
  display: block;
}

.editorContainer {
  width: 100%;
  height: 500px;
}

.red {
  color: red;
  margin-left: 10px;
  cursor: pointer;
}
</style>