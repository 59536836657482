<template>
  <div>
    <a-modal
        title="贡献值派发"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :width="1000"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-descriptions bordered>
        <a-descriptions-item label="活动昵称" :span="2">
          <a-input class="Name" v-model="Title"></a-input>
        </a-descriptions-item>
        <a-descriptions-item label="操作" :span="1">
          <a-button class="buttons" type="link" @click="openAddLabel">标签设置</a-button>
          <a-button class="buttons" type="link" @click="exportTemplate">导出模板</a-button>
          <a-upload
              name="file"
              :multiple="false"
              @change="AddData"
              :action="fileUrl"
              :headers="{
                  'X-Requested-With':null
              }"
              :showUploadList="false"
          >
            <a-button type="link">导入数据</a-button>
          </a-upload>
        </a-descriptions-item>
        <a-descriptions-item label="标签分类" :span="4">
          <a-tag v-for="(item,index) in form.Label" :key="index" closable @close="deleteLabel(index)">{{ item }}</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="数据">
          <a-table :columns="columns" :data-source="data"
                   :pagination="false"
                   :rowKey="(record, index) => { return index }"
                   class="table">
               <span slot="TolType" slot-scope="tags">
                  <a-button type="link" @click="openLab(tags)">详情</a-button>
               </span>
            <span slot="ToNumber" slot-scope="tags">
                {{ tags }}QB
            </span>
          </a-table>
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
    <AddLabel ref="AddLabel"></AddLabel>
    <ConDetails ref="ConDetails"></ConDetails>
  </div>
</template>
<script>
import CommitteeUserAdd from "./moble/CommitteeUserAdd";
import AddLabel from './com/AddLabel.vue'
import {add, exportTemplate} from "@/api/Admin/ObjectDetails/Main/ApprovalIntegral";
import ConDetails from './com/ConDetails.vue'

let {columns} = CommitteeUserAdd
export default {
  data() {
    return {
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      fileUrl: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Main/Introduction/importData',
      form: {
        Label: []
      },
      Title: ''
    };
  },
  methods: {
    handleOk() {
      // this.confirmLoading = true;
      let Data = this.data
      let Title = this.Title
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.$route.query.Id

      add({ObjectID, CompanyID, Title, Data}).then(res => {
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.visible=false
          this.$parent.UpDistribution()
        } else {
          this.$message.error(res.errorMsg);
        }
      })

    },
    openLab(data) {
      this.$refs.ConDetails.open(data)
    },
    AddData(info) {
      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          let payload = response.payload
          this.$message.success(payload.message);
          let data = payload.data
          this.data = data
        }
      }
    },
    deleteLabel(value) {
      let Label = this.form.Label
      Label.splice(Label.indexOf((value + 1)), 1)
    },
    exportTemplate() {
      let Label = this.form.Label

      if (Label.length == 0) {
        this.$message.error('未添加标签分类');
        return;
      }

      exportTemplate({Label}).then(res => {
        if (res.errorCode == 200) {
          this.$message.success(res.payload.message);
          let file = res.payload.file
          let files = process.env.VUE_APP_URL + file
          let regex = /\/api/;

          if (regex.test(files)) {
            files = files.replace("/api", "")
          }
          window.open(files)
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    AddLabelData(data) {
      let Label = this.form.Label
      Label.push(data)
    },
    openAddLabel() {
      this.$refs.AddLabel.open()
    },
    //打开
    open() {
      this.visible = true;
      this.data = []
      this.Title = ''
    },

    handleCancel() {
      this.visible = false;
    },
  },
  components: {AddLabel, ConDetails}
};
</script>

<style lang="scss">
.buttons {
  display: inline-block;
  margin-right: 10px;
}

.table {
  overflow-y: auto;
  height: 400px;
}

//textarea.ant-input{
//  resize: none;
//  border: none;
//}
///* 取消 多行文本框 获取焦点的 边框 效果 */
//textarea.ant-input:focus {
//  border: none;
//  box-shadow: none;
//}
</style>
