import request from '../../utils/request';

//查询成员
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Enterprise/AdminUser/find',
        method: 'get',
        params: query
    });
};

// 查询机构
export const fetchDataCompany = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Enterprise/AdminUser/find/Company',
        method: 'get',
        params: query
    });
};

//添加机构
export const addData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Enterprise/AdminUser/add',
        method: 'post',
        data: query
    });
};

//删除机构
export const deleteData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Enterprise/AdminUser/delete',
        method: 'delete',
        data: query
    });
};


