<template>
  <div>
    <a-modal
        title="编辑发展历程"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="标题">
          <a-input
              placeholder="请输入标题"
              v-decorator="[
                            'Text',
                            {  rules: [{ required: true, message: '请输入标题' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="日期">
          <a-date-picker
              v-decorator="[
                            'Time',
                            {  rules: [{ required: true, message: '请选择日期' }] }
             ]"
          />
        </a-form-item>

      </a-form>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {editHistory} from "@/api/Admin/ObjectDetails/Introduction";

export default {
  data() {
    return {
      id: null,
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      User: [],
      ImageUrl: process.env.VUE_APP_URL + 'Enterprise/Company/image',
    };
  },
  methods: {
    //打开
    open(value) {

      this.visible = true;
      this.id = value.id

      setTimeout(() => {
        this.form.setFieldsValue({
          Text: value.Text,
          Time: moment(value.Time, 'YYYY-MM-DD'),
        })
      })
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let time = values.Time._d
          let day = new Date(time);
          let Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          let Text = values.Text
          let id = this.id

          editHistory({id, Text, Time}).then(res => {
            if (res.errorCode == 200) {
              this.handleCancel()
              this.$message.success(res.payload);
              this.$parent.getdata()
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      })

    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
.button {
  display: block;
}
</style>