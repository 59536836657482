<template>
  <div class="Object">
    <div class="topBlock"></div>
    <div class="topNav">
      <a-button class="search-btn" @click="openListAdd">添加机构/企业</a-button>
    </div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             class="table"
             :rowKey="(record, index) => { return index }"
             :loading="loading">
  <span slot="LogoImg" slot-scope="tags">
        <a-avatar :size="30" icon="user" shape="square" :src="tags"/>
  </span>

      <span slot="EnableStart" slot-scope="tags">
      <a-tag v-if="tags=='0'" color="green">
        正常
      </a-tag>
            <a-tag v-else color="red">
        封禁
      </a-tag>
    </span>
      <span slot="Operate" slot-scope="text,record">
                <a-button type="link" @click="SetOperate(record.id)" class="red">删除</a-button>
            </span>
    </a-table>
    <Add ref="Add"></Add>
  </div>
</template>

<script>
import indexTest from "./moble/indexTest";
import Add from './com/add.vue'
import {fetchData, deleteData} from "@/api/Enterprise/Company";

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      query: {
        name: "",
      },
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  components: {Add},
  mounted() {
    this.getdata()
  },
  methods: {
    openListAdd() {
      this.$refs.Add.open()
    },
    SetOperate(value) {
      let that = this
      this.$confirm({
        title: '是否确认删除该机构吗?',
        content: () => <div style="color:red;">请小心操作！</div>,
        onOk() {
          deleteData({id: value}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.payload);
            }
          })
        },
      });
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
    },
    getdata() {
      this.loading = true
      fetchData().then(res => {
        this.loading = false
        let data = res.payload

        for (let i in data) {
          let time = data[i].Time
          let day = new Date(time);
          data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
        }

        this.data = data
      })
      // let userInfo = this.$store.state.user.info
      // let HospitalID = userInfo.HospitalID
      // let Class = userInfo.Class

    },
    getSumRegexData(data) {
      this.loading = false
      this.data = data
      this.pagination.current = 1
      this.pagination.total = 0
    },
    getRegexData() {
      this.loading = true
    },
    resetting() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.getdata()
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }

  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }

  .table {
    width: 98%;
    margin: 0 auto;
  }
}


.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}

.col-sql {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100px;
}
</style>
