export default {
    columns: [
        {
            title: '机构/企业',
            dataIndex: 'CompanyName',
            key: 'CompanyName',
            width: 200
        },
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '手机号码',
            dataIndex: 'Phone',
            key: 'Phone',
        },
        {
            title: '权限',
            dataIndex: 'Jurisdiction',
            key: 'Jurisdiction',
        },
        {
            title: '添加时间',
            key: 'Time',
            dataIndex: 'Time',
            width: 150
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: {customRender: 'Operate'},
        },
    ]
}
