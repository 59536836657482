var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"添加机构/企业管理员","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"机构/企业"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'CompanyID',
            {  rules: [{ required: true, message: '请选择机构/企业' }] },
        ]),expression:"[\n              'CompanyID',\n            {  rules: [{ required: true, message: '请选择机构/企业' }] },\n        ]"}]},_vm._l((_vm.Company),function(item,inedx){return _c('a-select-option',{key:inedx,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.Name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Name',
                              {  rules: [{ required: true, message: '请输入姓名' }] },
                          ]),expression:"[\n                              'Name',\n                              {  rules: [{ required: true, message: '请输入姓名' }] },\n                          ]"}],attrs:{"placeholder":"请输入昵称"}})],1),_c('a-form-item',{attrs:{"label":"手机号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Phone',
                              {  rules: [{ required: true, message: '请输入手机号码' }] },
                          ]),expression:"[\n                              'Phone',\n                              {  rules: [{ required: true, message: '请输入手机号码' }] },\n                          ]"}],attrs:{"placeholder":"请输入手机号码"}})],1),_c('a-form-item',{attrs:{"label":"密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Password',
                          {  rules: [{ required: true, message: '请输入密码' }] }
                      ]),expression:"[\n                          'Password',\n                          {  rules: [{ required: true, message: '请输入密码' }] }\n                      ]"}],attrs:{"placeholder":"请输入密码"}},[_vm._v(" > "),_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"label":"确认密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Passwords',
                          {  rules: [{ required: true, message: '请输入确认密码' }] }
                      ]),expression:"[\n                          'Passwords',\n                          {  rules: [{ required: true, message: '请输入确认密码' }] }\n                      ]"}],attrs:{"placeholder":"请输入确认密码"}},[_vm._v(" > "),_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"label":"权限"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'Jurisdiction',
            {  rules: [{ required: true, message: '请选择权限' }] },
        ]),expression:"[\n              'Jurisdiction',\n            {  rules: [{ required: true, message: '请选择权限' }] },\n        ]"}]},[_c('a-select-option',{attrs:{"value":"企业管理员"}},[_vm._v("企业管理员")]),_c('a-select-option',{attrs:{"value":"普通管理员"}},[_vm._v("普通管理员")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }