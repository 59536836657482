<template>
  <div class="ObjectMain">
    <div class="logo">
      <a-upload
          :headers="{
                  'X-Requested-With':null
              }"
          name="file"
          list-type="picture-card"
          :show-upload-list="false"
          :action="ImageUrl"
          @change="AddImage"
          class="avatar-uploader"
      >
        <img v-if="data.LogoImage" :src="data.LogoImage" alt="avatar"/>
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'"/>
          <div class="ant-upload-text">
            上传头像
          </div>
        </div>
      </a-upload>
    </div>
    <div class="cover">封面图</div>
    <a-button icon="edit" class="EditCover" @click="openEditCover(data.CoverImage)">编辑封面图</a-button>
    <div class="coverMain">
      <img v-if="data.CoverImage" :src="data.CoverImage" alt="avatar"/>
    </div>
    <div class="title">{{ data.Title }}</div>
    <div class="examine">
      <a-icon type="safety-certificate" class="icon"/>
      已审核
    </div>
    <div class="info">
      <div class="block">委员会：<span class="number">{{ data.Committee }}人</span></div>
      <div class="block">关注：<span class="number">{{ data.Interest }}人</span></div>
      <div class="block">贡献值额度：<span class="number">{{ data.ToNumber }}</span></div>
      <div class="block">剩余额度：<span class="number">{{ data.SetNumber }}</span></div>
      <div class="block">成立时间：<span class="number">{{ data.Time }}</span></div>
    </div>
    <div class="operate">
      <a-button icon="plus" class="button" @click="openCommitteeUserAdd">添加委员会成员</a-button>
      <a-button icon="line-chart" class="button" @click="openApprovalIntegral">贡献值派发</a-button>
      <a-button icon="edit" class="button" @click="openLimit">额度管理</a-button>
    </div>
    <CommitteeUserAdd ref="CommitteeUserAdd"></CommitteeUserAdd>
    <ApprovalIntegral ref="ApprovalIntegral"></ApprovalIntegral>
    <Limit ref="Limit"></Limit>
    <EditCover ref="EditCover"></EditCover>
    <div class="loading" v-if="loading">
      <a-spin size="large"></a-spin>
    </div>
  </div>
</template>

<script>
import CommitteeUserAdd from './Com/CommitteeUserAdd/index.vue'
import ApprovalIntegral from './Com/ApprovalIntegral/index.vue'
import Limit from './Com/Limit/index.vue'
import {find, UpLogo, UpCover, ConvertImage} from "@/api/Admin/ObjectDetails/Main";
import EditCover from './Com/EditCover.vue'

export default {
  mounted() {
    this.getdata()
  },
  data() {
    return {
      data: {},
      ImageUrl: process.env.VUE_APP_URL + 'Enterprise/Company/image',
      loading: false,
    }
  },
  components: {CommitteeUserAdd, ApprovalIntegral, Limit, EditCover},
  methods: {
    openEditCover(url) {

      if (url){
        this.loading = true
        ConvertImage({url}).then(res => {
          this.loading = false
          let payload = res.payload
          this.$refs.EditCover.open(payload)
        })
      }else {
        this.$refs.EditCover.open()
      }

    },
    UpCommittee() {
      this.$parent.UpCommittee()
    },
    UpDistribution() {
      this.$parent.UpDistribution()
    },
    getdata() {
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.$route.query.Id
      find({CompanyID, ObjectID}).then(res => {
        if (res.errorCode == 200) {
          let data = res.payload
          let time = data.Time
          let day = new Date(time);
          data.Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    openCommitteeUserAdd() {
      this.$refs.CommitteeUserAdd.open()
    },
    openApprovalIntegral() {
      this.$refs.ApprovalIntegral.open()
    },
    openLimit() {
      this.$message.error('模块尚未开放');
      // this.$refs.Limit.open()
    },
    AddImage(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let url = response.payload
          this.upLogo(url)
        }
      }
    },
    upcover(url) {
      let ObjectID = this.$route.query.Id
      this.loading = true
      UpCover({ObjectID, CoverImage: url}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.getdata()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    upLogo(url) {
      let ObjectID = this.$route.query.Id
      this.loading = true
      UpLogo({ObjectID, LogoImage: url}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.getdata()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    }
  }
}

</script>

<style scoped lang="scss">
.ObjectMain {
  width: 100%;
  height: 400px;
  background-color: #ffffff;
  position: relative;

  .logo {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 35px;
    left: 20px;

    .avatar {
      width: 80px;
      height: 80px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .EditCover {
    position: absolute;
    left: 190px;
    top: 160px;
  }

  .cover {
    font-size: 13px;
    color: #999999;
    position: absolute;
    top: 170px;
    left: 120px;
  }

  .coverMain {
    width: 300px;
    height: 150px;
    position: absolute;
    top: 210px;
    left: 120px;

    img {
      width: 300px;
      height: 150px;
    }
  }

  .title {
    position: absolute;
    left: 120px;
    top: 30px;
    color: #666666;
    font-weight: bold;
    font-size: 18px;
  }

  .examine {
    position: absolute;
    left: 340px;
    top: 35px;
    color: #aaaaaa;

    .icon {
      color: #58b99d;
    }
  }

  .info {
    position: absolute;
    left: 120px;
    top: 65px;
    color: #aaaaaa;
    font-size: 13px;

    .block {
      display: inline-block;
      margin-right: 15px;

      .number {
        margin-left: 5px;
        color: #666666;
      }
    }
  }

  .operate {
    position: absolute;
    left: 120px;
    top: 100px;

    .button {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

//.ant-upload .ant-upload-select-picture-card ::v-deep{
//  width: 80px;
//  height: 80px;
//}


.avatar-uploader ::v-deep .ant-upload {
  width: 80px;
  height: 80px;
  border: none;
  background: none;
}

.coverImage ::v-deep .ant-upload {
  width: 300px;
  height: 150px;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  //text-align: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  /*垂直居中*/
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
}


</style>
