<template>
  <div>
    <a-modal
        title="编辑商品详情"
        okText="提交"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        width="550px"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-item label="缩略图" :required=true>
          <img :src="thumbnailUrl" v-if="thumbnailUrl" style="width: 80px;height: 80px;display: block" alt=""/>
          <a-button @click="openEditThumbnail" icon="upload">上传缩略图</a-button>
        </a-form-item>
        <a-form-item label="视频">
          <a-upload
              name="file"
              :multiple="false"
              :before-upload="beforePosterUploadVideo"
              :remove="handlePosterRemoveVideo"
              :file-list="videoFile"
              :action="videoUrl"
              @change="addCoverVideo"
              :showUploadList="false"
              :headers="{
                        'X-Requested-With':null
                    }"
          >
            <a-button>
              <a-icon type="video-camera"/>
              上传视频
            </a-button>
          </a-upload>
          <a-button type="link" style="margin-left: 10px;color: red" v-if="CoverVideoUrl" @click="deleteVideo">
            删除视频
          </a-button>
          <video :src="CoverVideoUrl" style="width: 300px" v-if="CoverVideoUrl" controls></video>
        </a-form-item>
        <a-form-item label="海报">
          <img :src="posterUrl" v-if="posterUrl" alt="" style="width: 300px;display: block">
          <a-upload
              name="file"
              :multiple="false"
              :before-upload="beforePosterUpload"
              :remove="handlePosterRemove"
              :action="imageUrl"
              @change="addPoster"
              :headers="{
                        'X-Requested-With':null
                    }"
              :show-upload-list="false"
          >
            <a-button>
              <a-icon type="upload"/>
              上传海报
            </a-button>
          </a-upload>
          <a-button @click="deleteImg" icon="delete" style="margin-left: 20px" v-if="posterUrl">清除图片</a-button>
        </a-form-item>
        <a-form-item label="商品简介" :required=true>
          <a-textarea
              placeholder="请输入商品简介"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                                'commodityText',
                                {  rules: [{ required: true, message: '请输入商品简介' }], initialValue: isEdit ? commodityDetail.commodityText : '' },
                            ]"
          />
        </a-form-item>
        <a-form-item label="兑换须知" :required=true>
          <a-textarea
              placeholder="请输入兑换须知"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                                'notification',
                                {  rules: [{ required: true, message: '请输入兑换须知' }], initialValue: isEdit ? commodityDetail.notice : '' },
                            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <editThumbnail ref="editThumbnail"></editThumbnail>
  </div>
</template>

<script>
import {ConvertImage} from "@/api/Admin/ObjectDetails/Main";
import editThumbnail from './editThumbnail.vue'
import notification from "ant-design-vue/lib/notification";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      index: '',
      visible: false,
      confirmLoading: false,
      videoUrl: process.env.VUE_APP_URL + 'Website/exchange/GetFileVideo',
      imageUrl: process.env.VUE_APP_URL + 'Enterprise/Company/image',
      posterUrl: '',
      form: this.$form.createForm(this),
      CoverVideoUrl: '',
      thumbnailUrl: '',
      commodityDetail: {},
      videoFile: [],
      posterFile: []
    };
  },
  components: {editThumbnail},
  methods: {
    open(index, data) {
      this.visible = true
      this.index = index
     setTimeout(()=>{
       this.form.resetFields()
       this.CoverVideoUrl = ''
       this.posterUrl = ''
       this.thumbnailUrl=''
       if (data) {
         data = JSON.parse(data)
         this.form.setFieldsValue({
           name: data.name,
           commodityText: data.commodityText,
           notification: data.notification,
         })
         this.CoverVideoUrl = data.CoverVideoUrl ? data.CoverVideoUrl : ''
         this.posterUrl = data.posterUrl ? data.posterUrl : ''
         this.thumbnailUrl = data.thumbnailUrl ? data.thumbnailUrl : ''
       }
     })
    },
    handleOk() {
      if (this.thumbnailUrl == '') {
        notification.error({message: '缩略图未上传或未上传成功'});
        return;
      }

      this.form.validateFields(async (err, values) => {
        if (!err) {
          values.CoverVideoUrl = this.CoverVideoUrl
          values.posterUrl = this.posterUrl
          values.thumbnailUrl = this.thumbnailUrl
          values = JSON.stringify(values)
          this.$parent.upProductDetails(this.index, values)
          this.form.resetFields()
          this.visible = false
        }
      })
    },
    handleCancel() {
      this.visible = false
    },
    deleteImg() {
      this.posterUrl = ''
    },
    handlePosterRemoveVideo() {
      this.videoFile = [];
    },
    openEditThumbnail() {
      let url = this.thumbnailUrl
      if (url) {
        this.loading = true
        ConvertImage({url}).then(res => {
          this.loading = false
          let payload = res.payload
          this.$refs.editThumbnail.open(payload)
        })
      } else {
        this.$refs.editThumbnail.open()
      }
    },
    addCoverVideo(info) {
      let response = info.file.response;
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let url = response.payload;
          this.CoverVideoUrl = url;
          this.$message.success('上传成功');
        }
      }
    },
    beforePosterUploadVideo(file) {
      const isMp4 = file.type === 'video/mp4';
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isMp4) {
        this.$message.error('只能上传Mp4格式的视频!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('视频大小不能超过 25MB!');
        return false;
      }
      this.videoFile = [file];
      this.loading = true
      return true;
    },
    upThumbnail(value) {
      this.thumbnailUrl = value
    },
    deleteVideo() {
      let that = this
      this.$confirm({
        title: '是否删除该视频吗?',
        onOk() {
          that.CoverVideoUrl = ''
          that.videoFile = []
          that.$message.success('删除成功');
        },
      });
    },
    beforePosterUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isGIF = file.type === 'image/gif';
      // const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG && !isGIF) {
        this.$message.error('只能上传JPG/PNG/GIF格式的图片!');
        return false;
      }
      this.posterUrl = file;
      return true;
    },
    handlePosterRemove() {
      this.posterFile = [];
    },
    addPoster(info) {
      let response = info.file.response;
      if (response) {
        if (response.errorCode == 200) {
          let url = response.payload;
          this.posterUrl = url;
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>