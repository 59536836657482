import request from '../../utils/request';

//查询成员
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/find',
        method: 'get',
        params: query
    });
};

//查询申请新项目
export const findApplyProject = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/find/ApplyProject',
        method: 'get',
        params: query
    });
};


//搜索用户的手机号码
export const findUserPhone = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/findUser',
        method: 'get',
        params: query
    });
};

//添加申请新项目
export const addObjectApply = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/add/ApplyObject',
        method: 'post',
        data: query
    });
};

//取消申请新项目
export const canApplyObject = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/can/ApplyObject',
        method: 'post',
        data: query
    });
};

export const findApplyProjectID = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/find/ApplyProject/id',
        method: 'get',
        params: query
    });
};

//生成文件
export const ApplyObjectGenerateFile = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/ApplyObject/GenerateFile',
        method: 'post',
        data: query
    });
};

//查看签署文件
export const LookFileId = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/ApplyObject/LookFileId',
        method: 'get',
        params: query
    });
};

//发起签署
export const InitiateObject = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/ApplyObject/InitiateObject',
        method: 'post',
        data: query
    });
};

//终止签署
export const ExitCon = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/ApplyObject/ExitConfir',
        method: 'post',
        data: query
    });
};

//签署用户状态
export const ConfirmUserList = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/ApplyObject/ConfirmUserList',
        method: 'post',
        data: query
    });
};

//签署用户状态
export const Confirm = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/ApplyObject/Confirm',
        method: 'post',
        data: query
    });
};