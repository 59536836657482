//测试权限
export const RouterData = () => {
    return[
        {
            icon:'unordered-list',
            title: '总管理',
            item_link:'/Enterprise'
        },
        {
            icon:'flag',
            title: '企业管理',
            item_link:'/AdminCompany'
        },
        {
            icon:'home',
            title: '项目管理',
            item_link:'/Admin'
        },
        {
            icon:'shopping-cart',
            title: '商场管理',
            item_link:'/Commodities'
        },
        {
            icon:'container',
            title: '合同管理',
            item_link:'/Contract'
        },
        {
            icon:'setting',
            title: '系统管理',
            item_link:'/SetUp'
        }
    ]
}

//Root
export const RootRouterData = () => {
    return[
        {
            icon:'unordered-list',
            title: '总管理',
            item_link:'/Enterprise'
        },
        {
            icon:'home',
            title: '项目管理',
            item_link:'/Admin'
        },
        {
            icon:'shopping-cart',
            title: '商场管理',
            item_link:'/Commodities'
        },
        {
            icon:'setting',
            title: '系统管理',
            item_link:'/SetUp'
        }
    ]
}


//企业管理权限
export const AdminCompanyRouterData = () => {
    return[
        {
            icon:'flag',
            title: '企业管理',
            item_link:'/AdminCompany'
        },
        {
            icon:'home',
            title: '项目管理',
            item_link:'/Admin'
        },
        {
            icon:'shopping-cart',
            title: '商场管理',
            item_link:'/Commodities'
        },
        {
            icon:'container',
            title: '合同管理',
            item_link:'/Contract'
        },
        {
            icon:'setting',
            title: '系统管理',
            item_link:'/SetUp'
        }
    ]
}

//普通管理员权限
export const AdminRouterData = () => {
    return[
        {
            icon:'home',
            title: '项目管理',
            item_link:'/Admin'
        },
        {
            icon:'shopping-cart',
            title: '商场管理',
            item_link:'/Commodities'
        },
        {
            icon:'setting',
            title: '系统管理',
            item_link:'/SetUp'
        }
    ]
}
