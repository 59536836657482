import LoginView from '@/pages/Login/index';
import Layout from '@/pages/Layout';
import SearchView from '@/pages/Admin/index';
import EnterpriseView from '@/pages/Enterprise/index'
import CommoditiesView from '@/pages/Commodities/index'
import SetUpView from '@/pages/SetUp/index'
import AdminCompanyView from '@/pages/AdminCompany/index.vue'
import ContractView from '@/pages/Contract/index'


const AdminTab = [
    {
        path: '/',
        name: '/',
        redirect: '/Admin/Object',
        component: Layout,
        children: [
            {
                path: '/Admin',
                redirect: '/Admin/Object',
                component: SearchView,
                children: [
                    {
                        path: 'Object',
                        component: () => import('@/pages/Admin/pages/Object'),
                        meta: {
                            title: '项目列表'
                        },
                    },
                    {
                        path: 'Object/Details',
                        component: () => import('@/pages/Admin/pages/ObjectDetails/index.vue'),
                        meta: {
                            title: '项目详情'
                        },
                    },
                    {
                        path: 'User',
                        component: () => import('@/pages/Admin/pages/User/index.vue'),
                        meta: {
                            title: '用户管理'
                        },
                    },
                ]
            }, {
                path: '/AdminCompany',
                redirect: '/AdminCompany/User',
                component: AdminCompanyView,
                children: [
                    {
                        path: 'User',
                        component: () => import('@/pages/AdminCompany/User/index.vue'),
                        meta: {
                            title: '企业管理员管理'
                        },
                    },
                ]
            },
            {
                path: '/Enterprise',
                redirect: '/Enterprise/Company',
                component: EnterpriseView,
                children: [
                    {
                        path: 'Company',
                        component: () => import('@/pages/Enterprise/Company/index.vue'),
                        meta: {
                            title: '企业管理'
                        },
                    },
                    {
                        path: 'AdminUser',
                        component: () => import('@/pages/Enterprise/AdminUser/index.vue'),
                        meta: {
                            title: '企业管理员管理'
                        },
                    },
                    {
                        path: 'ExamineCompany',
                        component: () => import('@/pages/Enterprise/ExamineCompany/index.vue'),
                        meta: {
                            title: '企业审核'
                        },
                    },
                    {
                        path: 'ExamineObject',
                        component: () => import('@/pages/Enterprise/ExamineObject/index.vue'),
                        meta: {
                            title: '项目审核'
                        },
                    },
                    {
                        path: 'SignMech',
                        component: () => import('@/pages/Enterprise/SignMech/index.vue'),
                        meta: {
                            title: '签署机构管理'
                        },
                    },

                ]
            },
            {
                path: '/Contract',
                redirect: '/Contract/List',
                component: ContractView,
                children: [
                    {
                        path: 'List',
                        component: () => import('@/pages/Contract/List/index.vue'),
                        meta: {
                            title: '经办合同'
                        },
                    }
                ]
            },
            {
                path: '/Commodities',
                redirect: '/Commodities/List',
                component: CommoditiesView,
                children: [
                    {
                        path: 'List',
                        component: () => import('@/pages/Commodities/List/index.vue'),
                        meta: {
                            title: '商品列表'
                        },
                    },
                    {
                        path: 'Order',
                        component: () => import('@/pages/Commodities/Order.vue'),
                        meta: {
                            title: '兑换订单'
                        },
                    },
                ]
            },
            {
                path: '/SetUp',
                redirect: '/SetUp/Info',
                component: SetUpView,
                children: [
                    {
                        path: 'Info',
                        component: () => import('@/pages/SetUp/page/Info/index.vue'),
                        meta: {
                            title: '账户信息'
                        },
                    },
                    {
                        path: 'Secure',
                        component: () => import('@/pages/SetUp/page/Secure/index.vue'),
                        meta: {
                            title: '账户安全'
                        },
                    },
                ]
            }
        ]
    }
]


const LoginTab = [
    {
        path: '/Login',
        component: LoginView,
        children: [],
        meta: {
            title: '贡献值系统'
        }
    },
]


const routes = [...AdminTab, ...LoginTab];

export default routes;