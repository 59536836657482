var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"添加机构","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"机构昵称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'CompanyName',
                              {  rules: [{ required: true, message: '请输入机构昵称' }] },
                          ]),expression:"[\n                              'CompanyName',\n                              {  rules: [{ required: true, message: '请输入机构昵称' }] },\n                          ]"}],attrs:{"placeholder":"请输入机构昵称"}})],1),_c('a-form-item',{attrs:{"label":"负责人姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'UserName',
                              {  rules: [{ required: true, message: '请输入法定代表人' }] },
                          ]),expression:"[\n                              'UserName',\n                              {  rules: [{ required: true, message: '请输入法定代表人' }] },\n                          ]"}],attrs:{"placeholder":"请输入负责人姓名"}})],1),_c('a-form-item',{attrs:{"label":"负责人联系方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'UserPhone',
                              {  rules: [{ required: true, message: '请输入法定代表人联系方式' }] },
                          ]),expression:"[\n                              'UserPhone',\n                              {  rules: [{ required: true, message: '请输入法定代表人联系方式' }] },\n                          ]"}],attrs:{"placeholder":"请输入负责人联系方式"}})],1),_c('a-form-item',{attrs:{"label":"社会信用代码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'ThirdPartyUserId',
                              {  rules: [{ required: true, message: '请输入社会信用代码' }] },
                          ]),expression:"[\n                              'ThirdPartyUserId',\n                              {  rules: [{ required: true, message: '请输入社会信用代码' }] },\n                          ]"}],attrs:{"placeholder":"社会信用代码"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }