<template>
  <div>
      <a-modal
        title="额度管理"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="handleCancel"
        :footer="null"
        :width="1000"
    >
        <a-button icon="money-collect" class="plus" @click="openAdd">额度调整</a-button>
        <a-table :columns="columns" :data-source="data"
                 class="table"
                 :rowKey="(record, index) => { return index }"
                 :pagination="false"
                 :loading="loading">
            <span slot="Operate" slot-scope="text,record" >
                <a-button  type="link" @click="OpenDetails(record.Id)">详情</a-button>
                 <a-button  type="link" class="red" @click="ExitObject(record.Id)">取消</a-button>
            </span>
            <span slot="InShow" slot-scope="tags">
               <a-tag v-if="tags=='处理中'" color="yellow">处理中</a-tag>
               <a-tag v-else-if="tags=='未通过'" color="red">未通过</a-tag>
               <a-tag v-else-if="tags=='已取消'" color="red">已取消</a-tag>
               <a-tag v-else-if="tags=='已通过'" color="green">已通过</a-tag>
            </span>
        </a-table>
    </a-modal>
    <add ref="add"></add>
    <Details ref="Details"></Details>
  </div>
</template>
<script>
import add from './add.vue'
import Details from './moble/Details.vue'
import indexTest from "./moble/index"
let {columns} = indexTest
export default {
  data() {
    return {
      columns,
      loading: false,
      visible: false,
      confirmLoading: false,
      data:[{
        UID:'1110000',
        Title: '测试列表',
        InShow:'处理中',
        Time:'2023-9-14'
      },]
    };
  },
  methods: {
    //打开
    open() {
      this.visible = true;
    },
    OpenDetails(){
      this.$refs.Details.open()
    },
    openAdd(){
      this.$refs.add.open()
    },
    handleCancel() {
      this.visible = false;
    },
    GoToDetails(){

    },
    ExitObject(){
      this.$confirm({
        title: '是否取消该申请吗?',
        onOk() {
          console.log(1)
        },
      });
    }
  },
  components:{add,Details}
};
</script>

<style lang="scss">
.plus{
  margin-bottom: 10px;
  margin-right: 10px;
}
.red{
  color: red;
}
.green{
  color: green;
}
.yellow{
  color: yellow;
}
.table{
  height: 500px;
}
</style>
