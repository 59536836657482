
export default {
    columns:[
        {
            title: '活动昵称',
            key: 'ActivityName',
            dataIndex: 'ActivityName',
        },
        {
            title: '操作类型',
            key: 'Class',
            dataIndex: 'Class',
        },
        {
            title: '管理员',
            key: 'UserName',
            dataIndex: 'UserName',
        },
        {
            title: '时间',
            key: 'Time',
            dataIndex: 'Time',
        },
    ],

    data:[{
        UID: '10000000000',
        Title:'3QMap共建项目',
        TolNumber:2000000,
        Name: 'XXXX',
        Time: '2023-5-29',
        InShow: 0
    }]
}
