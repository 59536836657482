var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"添加地址","visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"keyboard":false,"maskClosable":false},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"地址","rules":[{ required: true, message: '请选择地址' }]}},[_c('a-cascader',{attrs:{"options":_vm.addressData,"placeholder":"请选择地址","change-on-select":""},on:{"change":_vm.onChange},model:{value:(_vm.selectedAddress),callback:function ($$v) {_vm.selectedAddress=$$v},expression:"selectedAddress"}})],1),_c('a-form-item',{attrs:{"label":"详细地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'detail',
                          {  rules: [{required: true,message: '请填写详细地址' }] }
           ]),expression:"[\n                          'detail',\n                          {  rules: [{required: true,message: '请填写详细地址' }] }\n           ]"}],attrs:{"placeholder":"请填写详细地址"}})],1),_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'name',
                          {  rules: [{required: true,message: '请填写姓名' }] }
           ]),expression:"[\n                          'name',\n                          {  rules: [{required: true,message: '请填写姓名' }] }\n           ]"}],attrs:{"placeholder":"请填写姓名"}})],1),_c('a-form-item',{attrs:{"label":"联系方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'phone',
                          {  rules: [{required: true,message: '请填写联系方式' }] }
           ]),expression:"[\n                          'phone',\n                          {  rules: [{required: true,message: '请填写联系方式' }] }\n           ]"}],attrs:{"placeholder":"请填写联系方式"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }