
export default {
    columns:[
        {
            title: '活动昵称',
            dataIndex: 'Title',
            key: 'Title',
        },
        {
            title: '投票类型',
            key: 'isVoteData',
            dataIndex: 'isVoteData',
        },
        {
            title: '地点类型',
            key: 'isMap',
            dataIndex: 'isMap',
        },
        {
            title: '地点',
            key: 'Address',
            dataIndex: 'Address',
        },
        {
            title: '投票人数',
            key: 'SignUpNumber',
            dataIndex: 'SignUpNumber',
        },
        {
            title: '开始时间',
            key: 'StartTime',
            dataIndex: 'StartTime',
        },
        {
            title: '结束时间',
            key: 'EndTime',
            dataIndex: 'EndTime',
        },
        {
            title: '添加时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
        },

        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ],
    isVoteData:[
        {value: 1, label: '海报'},
        {value: 2, label: '视频'},
        {value: 3, label: '视频+海报'},
        {value: 4, label: '视频+普通文本'},
        {value: 5, label: '海报+普通文本'},
        {value: 6, label: '普通文本'},
    ]
}
