var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"编辑发展历程","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Text',
                          {  rules: [{ required: true, message: '请输入标题' }] }
           ]),expression:"[\n                          'Text',\n                          {  rules: [{ required: true, message: '请输入标题' }] }\n           ]"}],attrs:{"placeholder":"请输入标题"}})],1),_c('a-form-item',{attrs:{"label":"日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Time',
                          {  rules: [{ required: true, message: '请选择日期' }] }
           ]),expression:"[\n                          'Time',\n                          {  rules: [{ required: true, message: '请选择日期' }] }\n           ]"}]})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }