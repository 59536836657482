<template>
  <div class="Object">
    <div class="main">
      <div class="Phone">安全手机号码：{{ info.Phone }}</div>
      <a-button type="primary" class="pow" @click="openPow">修改密码</a-button>
    </div>
    <forget ref="forget"></forget>
  </div>
</template>

<script>
import forget from './com/forget.vue'

export default {
  data() {
    return {
      info: this.$store.state.user.info
    }
  },
  components:{forget},
  mounted() {

  },
  methods: {
    openPow(){
      this.$refs.forget.open()
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .main {
    width: 98%;
    height: 200px;
    border-bottom: 1px solid #e4e4e4;
    margin: 0 auto;
    position: relative;

    .Phone{
      position: absolute;
      left: 25px;
      top: 25px;
    }
    .pow{
      position: absolute;
      left: 25px;
      top: 70px;
    }
  }
}

</style>
