import request from '../../../utils/request';

//查询合同列表
export const find = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Contract/find',
        method: 'get',
        params: query
    });
};

export const lookFlowId = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Contract/lookFlowId',
        method: 'get',
        params: query
    });
};