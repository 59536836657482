export default {
    columns: [
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '手机号码',
            key: 'Phone',
            dataIndex: 'Phone',
        },
        {
            title: '分类贡献值',
            key: 'TolType',
            dataIndex: 'TolType',
            scopedSlots: { customRender: 'TolType' },
        },
        {
            title: '贡献值数值',
            key: 'ToNumber',
            dataIndex: 'ToNumber',
            scopedSlots: { customRender: 'ToNumber' },
        },
        {
            title: '奖金状态',
            key: 'BonusClass',
            dataIndex: 'BonusClass',
            scopedSlots: { customRender: 'BonusClass' },
        },
        {
            title: '奖金金额',
            key: 'BonusTransAmount',
            dataIndex: 'BonusTransAmount',
            scopedSlots: { customRender: 'BonusTransAmount' },
        }
    ],
    columns_1: [
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '手机号码',
            key: 'Phone',
            dataIndex: 'Phone',
        },
        {
            title: '分类贡献值',
            key: 'TolType',
            dataIndex: 'TolType',
            scopedSlots: { customRender: 'TolType' },
        },
        {
            title: '贡献值数值',
            key: 'ToNumber',
            dataIndex: 'ToNumber',
            scopedSlots: { customRender: 'ToNumber' },
        },
    ],
}
