<template>
  <div>
    <a-modal
        title="添加项目报道"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="标题">
          <a-input
              placeholder="请输入标题"
              v-decorator="[
                            'Title',
                            {  rules: [{ required: true, message: '请输入标题' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="文本">
          <a-input
              placeholder="请输入文本"
              v-decorator="[
                            'Subtitle',
                            {  rules: [{ required: true, message: '请输入文本' }] }
             ]"
          />
        </a-form-item>

        <a-form-item label="链接地址">
          <a-input
              placeholder="请输入链接地址"
              v-decorator="[
                            'ToUrl',
                            {  rules: [{ required: true, message: '请输入链接地址' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="封面图">
          <a-upload
              name="file"
              :multiple="false"
              @change="AddImage"
              :action="ImageUrl"
              :headers="{
                  'X-Requested-With':null
              }"
          >
            <a-button>
              <a-icon type="upload"/>
              上传图片
            </a-button>
          </a-upload>
        </a-form-item>
        <a-form-item label="日期">
          <a-date-picker
              v-decorator="[
                            'Time',
                            {  rules: [{ required: true, message: '请选择日期' }] }
             ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>


import {add} from "@/api/Admin/ObjectDetails/Report";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      User: [],
      ImageUrl: process.env.VUE_APP_URL + 'Admin/Enterprise/Company/image',
    };
  },
  methods: {
    open() {
      this.visible = true
      this.form.resetFields()
    },
    AddImage(info) {
      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          let url = response.payload
          this.LogoImg = url
        }
      }
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (!this.LogoImg) {
            this.$message.error('未添加封面图');
            return
          }

          let ImageUrl = this.LogoImg
          let Title = values.Title
          let Subtitle = values.Subtitle
          let ToUrl = values.ToUrl
          let Time=values.Time

          let CompanyID = this.$store.state.user.info.CompanyID
          let ObjectID = this.$route.query.Id

          this.confirmLoading = true

          add({ImageUrl, Title, Subtitle, ToUrl, CompanyID, ObjectID,Time}).then(res => {
            this.confirmLoading = false
            if (res.errorCode == 200) {
              this.$message.success(res.payload);
              this.$parent.getdata()
              this.visible = false
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      })

    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
.button {
  display: block;
}
</style>