<template>
  <a-modal v-model="visible" title="奖金发放确认情况"  @ok="handleOk">
    <p v-for="(item,index) in addData" :key="index">{{ item.Name }}——{{ item.Phone }}——{{ item.TransAmount }}元——{{item.InShow}}</p>
  </a-modal>
</template>

<script>

export default {
  data() {
    return {
      visible: false,
      addData: [],
    }
  },
  methods: {
    open(addData) {
      this.visible = true
      this.addData = addData
    },
    handleOk(){
      this.close()
    },
    close() {
      this.visible = false
      this.$parent.getdata()
    }
  }
}
</script>

<style scoped lang="scss">

</style>