<template>
  <div>
    <a-modal
        title="编辑委员会成员"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="头像">
          <a-upload
              :headers="{
                  'X-Requested-With':null
              }"
              name="file"
              list-type="picture-card"
              :show-upload-list="false"
              :action="ImageUrl"
              @change="AddImage"
          >
            <img v-if="Avatar" :src="Avatar" alt="avatar" class="avatar"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">
                上传头像
              </div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="成员姓名">
          <a-input
              disabled
              placeholder="请输入成员姓名"
              v-decorator="[
                            'Name',
                            {  rules: [{ required: false, message: '请输入成员姓名' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="手机号码">
          <a-input
              disabled
              placeholder="请输入手机号码"
              v-decorator="[
                            'Phone',
                            {  rules: [{ required: false, message: '请输入手机号码' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="身份证号码">
          <a-input
              placeholder="请填写身份证号码"
              v-decorator="[
                            'UserID',
                            {  rules: [{ required: false, message: '请填写身份证号码' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="职能">
          <a-input
              placeholder="请填写职位"
              v-decorator="[
                            'Class',
                            {  rules: [{ required: true, message: '请填写职位' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="简介">
          <a-textarea
              placeholder="请填写简介"
              v-decorator="[
                            'Brief',
                            {  rules: [{ required: true, message: '请填写简介' }] }
             ]"
              :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>

      </a-form>
    </a-modal>
  </div>
</template>
<script>

import {up} from "@/api/Admin/ObjectDetails/Committee";

export default {
  data() {
    return {
      id: '',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      ImageUrl: process.env.VUE_APP_URL + 'Enterprise/Company/image',
      loading: false,
      Avatar: ''
    };
  },
  methods: {
    //打开
    open(value) {
      this.visible = true;
      this.id = value.id
      setTimeout(() => {
        this.Avatar = value.Avatar
        this.form.setFieldsValue({
          Brief: value.Brief,
          Class: value.Class,
          Name: value.Name,
          Phone: value.Phone,
          UserID: value.UserID
        })
      })
    },
    handleOk() {
      let id = this.id

      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.confirmLoading = true;

          let Brief = values.Brief
          let UserID = values.UserID
          let Class = values.Class
          let Avatar=this.Avatar

          up({id, Brief, Class, UserID,Avatar}).then(res => {
            this.confirmLoading = false
            if (res.errorCode == 200) {
              this.visible = false;
              this.$message.success(res.payload);
              this.$parent.getdata()
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    handleCancel() {
      this.visible = false;
    },
    AddImage(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let url = response.payload
          this.Avatar = url
        }
      }
    },
  },
};
</script>

<style lang="scss">
.avatar {
  border-radius: 60px;
  width: 50px;
  height: 50px;
}
</style>
