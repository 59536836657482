
export default {
    columns:[
        {
            title: '合同昵称',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
