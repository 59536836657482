var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"添加共同发起人","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                  'Name',
                                  {  rules: [{ required: true, message: '请输入姓名' }] }
                   ]),expression:"[\n                                  'Name',\n                                  {  rules: [{ required: true, message: '请输入姓名' }] }\n                   ]"}],staticStyle:{"width":"400px"},attrs:{"show-search":"","default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":null},on:{"search":_vm.handleSearch,"change":_vm.handleChange}},_vm._l((_vm.data),function(item,index){return _c('a-select-option',{key:index},[_vm._v(" "+_vm._s(item.Text)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"手机号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Phone',
                          {  rules: [{ required: true, message: '请输入手机号码' }] }
           ]),expression:"[\n                          'Phone',\n                          {  rules: [{ required: true, message: '请输入手机号码' }] }\n           ]"}],attrs:{"placeholder":"请输入手机号码"}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Remarks',
                          {  rules: [{ message: '请输入备注' }] }
           ]),expression:"[\n                          'Remarks',\n                          {  rules: [{ message: '请输入备注' }] }\n           ]"}],attrs:{"placeholder":"请输入备注"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }