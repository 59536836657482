import request from '../../../utils/request';

//查询处理信息
export const find = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Interest/find',
        method: 'get',
        params: query
    });
};

//处理意见
export const opinion = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Interest/Opinion',
        method: 'post',
        data: query
    });
};