var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"编辑委员会成员","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"头像"}},[_c('a-upload',{attrs:{"headers":{
                'X-Requested-With':null
            },"name":"file","list-type":"picture-card","show-upload-list":false,"action":_vm.ImageUrl},on:{"change":_vm.AddImage}},[(_vm.Avatar)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.Avatar,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上传头像 ")])],1)])],1),_c('a-form-item',{attrs:{"label":"成员姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Name',
                          {  rules: [{ required: false, message: '请输入成员姓名' }] }
           ]),expression:"[\n                          'Name',\n                          {  rules: [{ required: false, message: '请输入成员姓名' }] }\n           ]"}],attrs:{"disabled":"","placeholder":"请输入成员姓名"}})],1),_c('a-form-item',{attrs:{"label":"手机号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Phone',
                          {  rules: [{ required: false, message: '请输入手机号码' }] }
           ]),expression:"[\n                          'Phone',\n                          {  rules: [{ required: false, message: '请输入手机号码' }] }\n           ]"}],attrs:{"disabled":"","placeholder":"请输入手机号码"}})],1),_c('a-form-item',{attrs:{"label":"身份证号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'UserID',
                          {  rules: [{ required: false, message: '请填写身份证号码' }] }
           ]),expression:"[\n                          'UserID',\n                          {  rules: [{ required: false, message: '请填写身份证号码' }] }\n           ]"}],attrs:{"placeholder":"请填写身份证号码"}})],1),_c('a-form-item',{attrs:{"label":"职能"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Class',
                          {  rules: [{ required: true, message: '请填写职位' }] }
           ]),expression:"[\n                          'Class',\n                          {  rules: [{ required: true, message: '请填写职位' }] }\n           ]"}],attrs:{"placeholder":"请填写职位"}})],1),_c('a-form-item',{attrs:{"label":"简介"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Brief',
                          {  rules: [{ required: true, message: '请填写简介' }] }
           ]),expression:"[\n                          'Brief',\n                          {  rules: [{ required: true, message: '请填写简介' }] }\n           ]"}],attrs:{"placeholder":"请填写简介","auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }