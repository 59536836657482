<template>
  <div class="Object">
    <div class="topBlock"></div>
    <div class="topNav">
      <div class="nav-title">订单列表</div>
      <a-select
          show-search
          placeholder="选择商品名称"
          style="width: 200px"
          class="search-dropdown"
          @focus="getAllCommodityName"
          option-filter-prop="children"
          :filter-option="filterOption"
          @change="handleCommoditySelected"
      >
        <a-select-option v-for="d in commodityName" :key="d.id">
          {{ d.name }}
        </a-select-option>
      </a-select>
      <a-button type="primary" class="search-btn" icon="search" @click="filterByCommodityId">
        查询
      </a-button>
      <a-button type="primary" class="search-btn" icon="search" @click="getData(activeTab)">
        查看全部
      </a-button>
    </div>

    <a-tabs default-active-key="1" @change="changeTab">
      <a-tab-pane key="1" tab="待发货订单">
        <a-table :columns="columns_1" :data-source="uncompletedData" :pagination="pagination"
                 @change="tablePaginationChange"
                 class="table"
                 :rowKey="(record, index) => { return index }"
                 :loading="loading">
                <span slot="address" slot-scope="text,record">
                    <a-button type="link" @click="CopyAddr(record.id)" icon="file"
                              :id="'copy-btn-' + record.id">复制</a-button>
                </span>
          <span slot="operation" slot-scope="text,record">
                    <a-button type="link" @click="fillExpressNum(record.id)" icon="message">发货</a-button>
                </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="已发货订单">
        <a-table :columns="columns_2" :data-source="completedData" :pagination="pagination"
                 @change="tablePaginationChange"
                 class="table"
                 :rowKey="(record, index) => { return index }"
                 :loading="loading">
                <span slot="address" slot-scope="text,record">
                    <a-button type="link" @click="CopyAddr(record.id)" icon="file"
                              :id="'copy-btn-' + record.id">复制</a-button>
                </span>
          <span slot="operation" slot-scope="text,record">
                    <a-popconfirm
                        title="是否取消发货"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="cancelExpress(record.id)"
                    >
                        <a-button type="link" icon="delete">取消</a-button>
                    </a-popconfirm>
                </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <a-modal
        title="订单发货"
        okText="提交"
        :visible="modalVisible"
        :confirm-loading="modalLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-item label="快递名称">
          <a-input
              placeholder="请输入快递名称"
              v-decorator="[
                                        'expressName',
                                        {  rules: [{ required: true, message: '请输入快递名称'}], initialValue: '' },
                                    ]"
          />
        </a-form-item>
        <a-form-item label="快递单号">
          <a-input
              placeholder="请输入快递称单号"
              v-decorator="[
                                        'expressNum',
                                        {  rules: [{ required: true, message: '请输入快递单号'}], initialValue: '' },
                                    ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {
  getAllCommodityName,
  getExchangeRecordList,
  getExchangeRecordListByCommodityId,
  updateExpressInfo,
  cancelExpressById
} from "@/api/Commodities/Order";
import notification from 'ant-design-vue/es/notification';
import Clipboard from 'clipboard';
import indexTest from './Order/moble/index'

let {columns_1, columns_2} = indexTest
// import AddModel from './com/addModel.vue'

export default {
  data() {
    return {
      columns_1: columns_1,
      columns_2: columns_2,
      loading: false,
      commodityName: [],
      selectedCommodityId: -1,
      uncompletedData: [], // 未发货订单
      completedData: [], // 发货订单
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
      activeTab: 1,
      modalVisible: false,
      modalLoading: false,
      selectedOrderId: -1,
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {name: 'express_form'});
  },
  mounted() {
    this.getData(this.activeTab);
  },
  methods: {
    async getAllCommodityName() {
      try {
        // get addrs
        let {errorCode, payload} = await getAllCommodityName();
        // console.log(errorCode, payload);
        if (errorCode == 200) {
          this.commodityName = payload;
        } else {
          notification.error({message: '获取商品名称失败', description: errorCode});
        }
      } catch (err) {
        console.log(err);
        notification.error({message: '获取商品名称失败', description: err});
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    fillExpressNum(id) {
      this.modalVisible = true;
      this.selectedOrderId = id;

      this.form.resetFields()
    },
    handleCancel() {
      this.modalVisible = false;
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            this.modalLoading = true;
            let {errorCode} = await updateExpressInfo(this.selectedOrderId, values);
            // console.log(errorCode, payload);
            if (errorCode == 200) {
              notification.success({message: '发货成功'});
              this.modalLoading = false;
              this.modalVisible = false;
              this.getData(this.activeTab);
            } else {
              notification.error({message: '发货失败', description: errorCode});
            }
          } catch (err) {
            console.log(err);
            this.modalLoading = false;
            this.modalVisible = false;
            notification.error({message: '发货失败', description: err});
          }
        }
      })
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getData(this.activeTab);
    },
    async getData(type) {
      this.loading = true
      const page = this.pagination.current;
      const size = this.pagination.pageSize;
      this.uncompletedData = []

      try {
        let query = {page, size, type}
        let {errorCode, payload} = await getExchangeRecordList(query);
        // console.log(payload);
        if (errorCode == 200) {
          if (type == 1) {
            this.uncompletedData = payload.records;
          } else {
            this.completedData = payload.records;
          }
          this.pagination.total = payload.total;
          this.loading = false;
        } else {
          notification.error({message: '获取订单列表失败', description: errorCode});
        }
      } catch (err) {
        console.log(err);
        notification.error({message: '获取订单列表失败', description: err});
      }
    },
    handleCommoditySelected(value) {
      this.selectedCommodityId = value;
    },
    async filterByCommodityId() {
      this.loading = true
      const page = this.pagination.current;
      const pageSize = this.pagination.pageSize;

      try {
        // get addrs
        let {
          errorCode,
          payload
        } = await getExchangeRecordListByCommodityId(this.selectedCommodityId, page, pageSize, this.activeTab);
        if (errorCode == 200) {
          if (this.activeTab == 1) {
            this.uncompletedData = payload.records;
          } else {
            this.completedData = payload.records;
          }
          // this.data = payload.records;
          this.pagination.total = payload.total;
          this.loading = false;
        } else {
          notification.error({message: '获取商品筛选订单列表失败', description: errorCode});
        }
      } catch (err) {
        console.log(err);
        notification.error({message: '获取商品筛选订单列表失败', description: err});
      }
    },
    changeTab(key) {
      this.activeTab = key;
      this.pagination.current = 1
      this.getData(key);
    },
    CopyAddr(id) {
      let record;
      if (this.activeTab == 1) {
        record = this.uncompletedData.find(item => item.id == id);
      } else {
        record = this.completedData.find(item => item.id == id);
      }
      // const record = this.data.find(item => item.id == id);
      const detailAddr = record.addr.province + record.addr.city + record.addr.region + record.addr.detail + " " + record.addr.name + " " + record.addr.phone;
      const clipboard = new Clipboard('#copy-btn-' + id, {
        text: () => detailAddr
      });
      clipboard.on('success', () => {
        this.$message.success('复制成功: ' + detailAddr);
      });
      clipboard.on('error', () => {
        this.$message.error('复制失败');
      });
    },
    async cancelExpress(id) {
      try {
        // get addrs
        let {errorCode} = await cancelExpressById(id);
        if (errorCode == 200) {
          notification.success({message: '取消发货成功'});
          this.getData(this.activeTab);
        } else {
          notification.error({message: '取消发货失败', description: errorCode});
        }
      } catch (err) {
        console.log(err);
        notification.error({message: '取消发货失败', description: err});
      }
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 1000px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }

  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .search-btn {
      margin-right: 10px;
    }

    .nav-title {
      font-size: 20px;
      margin-right: 30px;
    }

    .search-dropdown {
      margin-right: 30px;
    }
  }

  .table {
    width: 98%;
    margin: 0 auto;
    height: 800px;
    overflow-y: auto;
  }
}


.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}
</style>
  