<template>
  <div>
    <a-modal
        title="申请管理"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="handleCancel"
        :footer="null"
        :width="1000"
    >
      <a-button icon="plus" class="plus" @click="openAdd">发起项目</a-button>
      <a-table :columns="columns" :data-source="data"
               class="table"

               :rowKey="(record, index) => { return index }"
               :pagination="false"
               :loading="loading">
          <span slot="LogoImage" slot-scope="tags,record">
        <a-avatar :size="30" icon="user" shape="square" :src="record.LogoImage"/>
       </span>
            <span slot="Operate" slot-scope="text,record">
                 <a-button type="link" @click="OpenDetails(record.id)">详情</a-button>
                 <a-button type="link" class="red" @click="ExitObject(record.id)" v-if="record.InShow=='审核中'">取消</a-button>
            </span>
        <span slot="InShow" slot-scope="tags">
               <a-tag v-if="tags=='审核中'" color="yellow">审核中</a-tag>
               <a-tag v-else-if="tags=='未通过'" color="red">未通过</a-tag>
               <a-tag v-else-if="tags=='已取消'" color="red">已取消</a-tag>
               <a-tag v-else-if="tags=='项目已成立'" color="green">项目已成立</a-tag>
               <a-tag v-else color="yellow">{{tags}}</a-tag>
            </span>
      </a-table>

    </a-modal>
    <add ref="add"></add>
    <Details ref="Details"></Details>
  </div>
</template>
<script>
import add from './add.vue'
import Details from './moble/Details.vue'
import indexTest from "./moble/index"
import {canApplyObject, findApplyProject} from "@/api/Admin/Object";

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      visible: false,
      confirmLoading: false,
      data: []
    };
  },
  methods: {
    getdata() {
      let CompanyID = this.$store.state.user.info.CompanyID

      findApplyProject({CompanyID}).then(res => {
        let data = res.payload

        for (let i in data) {
          let time = data[i].Time
          let day = new Date(time);
          data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
        }

        this.data = data
      })
    },
    //打开
    open() {
      this.visible = true;
      this.getdata()
    },
    OpenDetails(record) {
      this.$refs.Details.open(record)
    },
    openAdd() {
      this.$refs.add.open()
    },
    handleCancel() {
      this.visible = false;
      this.$parent.getdata()
    },
    ExitObject(id) {
      let that=this
      this.$confirm({
        title: '是否取消该项目申请吗?',
        onOk() {
          canApplyObject({id}).then(res=>{
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    }
  },
  components: {add, Details}
};
</script>

<style lang="scss">
.plus {
  margin-bottom: 10px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.yellow {
  color: yellow;
}

.table {
  height: 500px;
  overflow-y: auto;
}
</style>
