// 对网络请求的封装
import axios from 'axios';
import notification from 'ant-design-vue/es/notification'
import {base_url } from '../config.js';

const onError = error => {
    // 目前还没有那么多状态码
    if (error.response) {
        const status = error.response.status
        const message = error.response.statusText

        if (status === 401) {
            notification.error({ message: '用户状态不存在', description: message })
            localStorage.removeItem("auth_token");
            window.location.href = '/';
        }

        if (status === 403) {
            localStorage.removeItem("auth_token");
            window.location.href = '/';
            setTimeout(()=>{
                notification.error({ message: '用户状态超时', description: message })
            },1000)
        }

        if (status === 404) {
            notification.error({ message: '未知资源', description: message })
        }

        if (status === 500) {
            notification.error({
                message: '服务器错误',
                description: message
            })
        }
    } else {
        notification.error({ message: '未知错误', description: '服务器无法访问，请刷新重试' });
    }
    return Promise.reject(error)
}

const request = axios.create({
    baseURL: base_url,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    // transformRequest: 在向服务器发送前，修改请求数据
    transformRequest: [
        function (data, headers) {
            headers['access-token'] = localStorage.getItem("auth_token");
            if (headers['Content-Type'] === 'multipart/form-data') {
                return data
            } else {
                return JSON.stringify(data)
            }
        }
    ]
})

// 请求拦截器
request.interceptors.request.use(
    config => {
        // 开发环境下，如果请求是 post,put,patch,则打印数据体，方便调试
        if (process.env.NODE_ENV === 'development') {
            const { method } = config
            if (['post', 'put', 'patch'].includes(method)) {
                console.log(config.data)
            }
        }
        return config
    },
    error => {
        notification.error({
            message: '请求失败',
            description: '发送请求失败，请检查您的网络'
        })
        return Promise.reject(error)
    }
)

// 响应拦截器
request.interceptors.response.use(res => {
    let {errorCode, errorMessage } = res.data;
    if (errorCode == 401) {
        notification.error({ message: '用户状态不存在', description: errorMessage });
        localStorage.removeItem("auth_token");
        window.location.href = '/';
    }
    if (errorCode == 403) {
        notification.error({ message: '用户状态超时', description: errorMessage });
        localStorage.removeItem("auth_token");
        window.location.href = '/';
    }
    const jsonPattern = /application\/json/gi
    if (jsonPattern.test(res.headers['content-type'])) {
        return res.data
    } else {
        return res
    }
}, onError)

export default request;
