
export default {
    columns:[
        {
            title: '留言内容',
            key: 'Text',
            dataIndex: 'Text',
        },
        {
            title: '图片',
            key: 'ImgUrl',
            dataIndex: 'ImgUrl',
            scopedSlots: { customRender: 'ImgUrl' },
        },
        {
            title: '用户',
            key: 'UserName',
            dataIndex: 'UserName',
        },
        {
            title: '状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },
        {
            title: '提交时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '用户联系方式',
            key: 'Phone',
            dataIndex: 'Phone',
        },
        {
            title: '联系方式',
            key: 'UserPhone',
            dataIndex: 'UserPhone',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
