import request from '../../utils/request';

//查询成员
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/User/find',
        method: 'get',
        params: query
    });
};

//用户贡献值详情
export const UserDetailsData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/User/details',
        method: 'get',
        params: query
    });
};

//精准查询用户（link匹配）
export const fetchAccurate = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/User/find/accurate',
        method: 'get',
        params: query
    });
};

//导出用户数据
export const ExportUser = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/User/exportUser',
        method: 'get',
        params: query
    });
};

//刷新机构所在用户索引表
export const UpUserCompanyInfo = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/User/UpUserCompanyInfo',
        method: 'get',
        params: query
    });
};


//测试生成文件
export const TestIndex3 = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Test/index3',
        method: 'get',
        params: query
    });
};