<template>
  <div>
    <a-modal
        title="添加机构/企业"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="机构/企业昵称">
          <a-input
              placeholder="请输入昵称"
              v-decorator="[
                                'Name',
                                {  rules: [{ required: true, message: '请输入昵称' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="法定代表人">
          <a-input
              placeholder="请输入法定代表人"
              v-decorator="[
                                'UserName',
                                {  rules: [{ required: true, message: '请输入法定代表人' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="法定代表人联系方式">
          <a-input
              placeholder="请输入法定代表人联系方式"
              v-decorator="[
                                'UserPhone',
                                {  rules: [{ required: true, message: '请输入法定代表人联系方式' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="工商注册号/社会信用代码">
          <a-input
              placeholder="工商注册号/社会信用代码"
              v-decorator="[
                                'ThirdPartyUserId',
                                {  rules: [{ required: true, message: '请输入工商注册号/社会信用代码' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="标语">
          <a-input
              placeholder="请填写标语"
              v-decorator="[
                                'ActivityName',
                                {  rules: [{ required: true, message: '请输入标语' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="Logo">
          <a-upload
              name="file"
              :multiple="false"
              @change="handleChange"
              :action="ImageUrl"
              :headers="{
                  'X-Requested-With':null
              }"
          >
            <a-button>
              <a-icon type="upload"/>
              上传图片
            </a-button>
          </a-upload>
        </a-form-item>

      </a-form>
    </a-modal>
  </div>
</template>
<script>

import {addData} from "@/api/Enterprise/Company";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      ImageUrl: process.env.VUE_APP_URL + 'Enterprise/Company/image',
      LogoImg: ''
    };
  },
  methods: {
    handleChange(info) {
      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          let url = response.payload
          this.LogoImg = url
        }
      }
    },
    //打开
    open() {
      this.visible = true;
      this.form.resetFields()
      this.LogoImg=''
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Name = values.Name
          let ActivityName = values.ActivityName
          let LogoImg = this.LogoImg
          let UserName = values.UserName
          let UserPhone = values.UserPhone
          let ThirdPartyUserId = values.ThirdPartyUserId

          this.confirmLoading = true;
          addData({Name, ActivityName, LogoImg,UserName,UserPhone,ThirdPartyUserId}).then(res => {
            this.confirmLoading = false;
            this.visible = false;
            if (res.errorCode == 200) {
              this.$message.success(res.payload);
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    handleCancel() {

      this.visible = false;
    },
  },
};
</script>
