<template>
  <a-layout>
    <a-layout-sider class="sub-sider">
      <sub-sider :menu="menu" :title="menuTitle"/>
    </a-layout-sider>
    <a-layout-content>
      <content-header :title="contentTitle"/>
      <div class="content">
        <router-view></router-view>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import SubSider from '@/components/SubSider.vue';
import ContentHeader from '@/components/ContentHeader.vue';

export default {
  components: {
    'sub-sider': SubSider,
    'content-header': ContentHeader,
  },
  data() {
    return {
      menuTitle: '项目管理',
      contentTitle: "项目管理",
      menu: [
        {
          icon:'unordered-list',
          title: '项目列表',
          item_link:'/Admin/Object'
        },
        {
          icon:'user',
          title: '用户管理',
          item_link:'/Admin/User'
        },
        // {
        //   icon:'block',
        //   title: '投票管理',
        //   item_link:'/Admin/Vote'
        // }
      ]
    };
  },
  methods: {},
}
</script>

<style scoped>

.sub-sider {
  overflow: auto;
  height: 100vh;
  background: #ebedf1;
}

.content {
  background: #f0f2f5;
  padding: 20px;
}

</style>