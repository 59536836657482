<template>
  <div>
    <a-modal
        title="添加共同发起人"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="姓名">
          <a-select
              show-search
              style="width: 400px"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearch"
              @change="handleChange"
              v-decorator="[
                                    'Name',
                                    {  rules: [{ required: true, message: '请输入姓名' }] }
                     ]"
          >
            <a-select-option v-for="(item,index) in data" :key="index">
              {{ item.Text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="手机号码">
          <a-input
              placeholder="请输入手机号码"
              v-decorator="[
                            'Phone',
                            {  rules: [{ required: true, message: '请输入手机号码' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="备注">
          <a-input
              placeholder="请输入备注"
              v-decorator="[
                            'Remarks',
                            {  rules: [{ message: '请输入备注' }] }
             ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import {findUserPhone} from "@/api/Admin/Object";

export default {
  data() {
    return {
      data: [],
      value: '',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
    };
  },
  methods: {
    handleSearch(e) {
      findUserPhone({Name: e}).then(res => {
        if (res.errorCode == 200) {
          this.data = res.payload
        }
      })
    },
    handleChange(e) {
      let data = JSON.parse(JSON.stringify(this.data))
      if (e == data.length) {
        let info = data[e]
        setTimeout(() => {
          this.form.setFieldsValue({
            Name: info.Name
          })
          this.data = []
        })
      } else {
        let info = data[e]
        setTimeout(() => {
          this.form.setFieldsValue({
            Name: info.Name,
            Phone: info.Phone
          })
          this.data = []
        })
      }

    },
    //打开
    open() {
      this.visible = true;
      this.form.resetFields()
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Name = values.Name
          let Phone = values.Phone
          let Remarks = values.Remarks
          this.$parent.AddUser(Name, Phone, Remarks)
          this.handleCancel()
        }
      })
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>
