export default {
    columns: [
        {
            title: '用户',
            key: 'Name',
            dataIndex: 'Name',
        },
        {
            title: '商品名称',
            key: 'Title',
            dataIndex: 'Title',
        },
        {
            title: '兑换贡献值',
            key: 'Price',
            dataIndex: 'Price',
        },
        {
            title: '状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: {customRender: 'InShow'},
        },
        {
            title: '类型',
            key: 'Class',
            dataIndex: 'Class',
            scopedSlots: {customRender: 'Class'},
        },
        {
            title: '操作',
            key: 'operation',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
        },
    ],
    columns_1: [
        {
            title: '商品名称',
            key: 'commodityName',
            dataIndex: 'commodityName',
        },
        {
            title: '规格',
            key: 'specName',
            dataIndex: 'specName',
        },
        {
            title: '兑换贡献值',
            key: 'price',
            dataIndex: 'price',
        },
        {
            title: '用户',
            key: 'userName',
            dataIndex: 'userName',
        },
        {
            title: '兑换时间',
            key: 'exchangeTime',
            dataIndex: 'exchangeTime',
        },
        {
            title: '贡献值类型',
            key: 'projectName',
            dataIndex: 'projectName',
        },
        {
            title: '收货地址',
            scopedSlots: {customRender: 'address'},
        },
        {
            title: '操作',
            key: 'operation',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
        },
    ],
    columns_2: [
        {
            title: '订单编号',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '商品名称',
            key: 'commodityName',
            dataIndex: 'commodityName',
        },
        {
            title: '规格',
            key: 'specName',
            dataIndex: 'specName',
        },
        {
            title: '兑换贡献值',
            key: 'price',
            dataIndex: 'price',
        },
        {
            title: '用户',
            key: 'userName',
            dataIndex: 'userName',
        },
        {
            title: '兑换时间',
            key: 'exchangeTime',
            dataIndex: 'exchangeTime',
        },
        {
            title: '物流号码',
            key: 'expressNum',
            dataIndex: 'expressNum',
        },
        {
            title: '收货地址',
            scopedSlots: {customRender: 'address'},
        },
        {
            title: '操作',
            key: 'operation',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
        },
    ]
}
