
export default {
    columns:[
        {
            title: 'Logo',
            dataIndex: 'LogoImage',
            key: 'LogoImage',
            scopedSlots: { customRender: 'LogoImage' },
        },
        {
            title: '社区项目',
            dataIndex: 'Title',
            key: 'Title',
        },
        {
            title: '贡献值总数',
            key: 'ToNumber',
            dataIndex: 'ToNumber',
        },
        {
            title: '成立时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
