<template>
  <div>
    <a-modal
        :title="isEdit ? '普通合同' : '新建合同'"
        okText="提交"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        width="1000px"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-item label="合同昵称">
          <a-input
              placeholder="请输入合同昵称"
              v-decorator="[
                                    'Title',
                                    {  rules: [{ required: true, message: '请输入合同昵称'}], initialValue: isEdit ? commodityDetail.Title : '' },
                                ]"
          />
        </a-form-item>
        <a-form-item label="合同文件">
          <div class="file">
            <div class="file_1">
              <a-upload
                  :headers="{
                  'X-Requested-With':null
              }"
                  name="file"
                  :show-upload-list="false"
                  :action="fileUrl"
                  @change="AddTaskId"
                  :beforeUpload="beforeUpload"
              >
                <a-button>
                  <a-icon type="upload"/>
                  添加签署文件
                </a-button>
              </a-upload>
              <div v-if="TaskId">
                <a-icon type="check" class="check"/>
                已上传
                <span class="fileSucces">{{ TaskId }}</span>
              </div>
            </div>

            <div class="file_2">
              <a-upload
                  :headers="{
                  'X-Requested-With':null
             }"
                  name="file"
                  :show-upload-list="false"
                  :action="fileUrl"
                  @change="AddCc"
                  :beforeUpload="beforeUpload"
              >
                <a-button>
                  <a-icon type="upload"/>
                  添加附件文件
                </a-button>
              </a-upload>
              <div v-if="CcId">
                <a-icon type="check" class="check"/>
                已上传
                <span class="fileSucces">{{ CcId }}</span>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="任务参与人员">
          <div class="nav">
            <a-button class="in-i" @click="openCompany_Task">
              <a-icon type="plus"/>
              添加企业
            </a-button>
            <a-button class="in-i" @click="addUser_Task">
              <a-icon type="plus"/>
              添加个人
            </a-button>
          </div>
          <div class="Participate" v-for="(item,index) in Task" :key="index">
            <div v-if="item.Type=='企业'">
              <div class="name">
                <div class="block is">参与类型</div>
                <div class="block">参与主体</div>
                <div class="block">负责人</div>
                <div class="block">手机号码</div>
              </div>
              <div class="data">
                <div class="block is">{{ item.Type }}</div>
                <div class="block">
                  {{ item.CompanyName }}
                </div>
                <div class="block">
                  <span style="margin-right: 10px">{{ item.UserName }}</span>
                  <span>{{ item.UserPhone }}</span>
                </div>
                <div class="block it">
                  <a-button size="small" type="text" @click="removeTask(index)">删除</a-button>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="name">
                <div class="block is">参与类型</div>
                <div class="block">参与人</div>
                <div class="block">手机号码</div>
              </div>
              <div class="data">
                <div class="block is">{{ item.Type }}</div>
                <div class="block">
                  <a-input v-model="item.UserName" size="small" class="t"></a-input>
                </div>
                <div class="block">
                  <a-input v-model="item.UserPhone" size="small" class="t"></a-input>
                </div>
                <div class="block it">
                  <a-button size="small" type="text" @click="removeTask(index)">删除</a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>

        <a-form-item label="抄送人员">
          <div class="nav">
            <a-button class="in-i" @click="openCompany_Cc">
              <a-icon type="plus"/>
              添加企业
            </a-button>
            <a-button class="in-i" @click="addUser_Cc">
              <a-icon type="plus"/>
              添加个人
            </a-button>
          </div>
          <div class="Participate" v-for="(item,index) in Cc" :key="index">
            <div v-if="item.Type=='企业'">
              <div class="name">
                <div class="block is">参与类型</div>
                <div class="block">参与主体</div>
                <div class="block">负责人</div>
                <div class="block">手机号码</div>
              </div>
              <div class="data">
                <div class="block is">{{ item.Type }}</div>
                <div class="block">
                  {{ item.CompanyName }}
                </div>
                <div class="block">
                  <span style="margin-right: 10px">{{ item.UserName }}</span>
                  <span>{{ item.UserPhone }}</span>
                </div>
                <div class="block it">
                  <a-button size="small" type="text" @click="removeTask(index)">删除</a-button>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="name">
                <div class="block is">参与类型</div>
                <div class="block">参与人</div>
                <div class="block">手机号码</div>
              </div>
              <div class="data">
                <div class="block is">{{ item.Type }}</div>
                <div class="block">
                  <a-input v-model="item.UserName" size="small" class="t"></a-input>
                </div>
                <div class="block">
                  <a-input v-model="item.UserPhone" size="small" class="t"></a-input>
                </div>
                <div class="block it">
                  <a-button size="small" type="text" @click="removeTask(index)">删除</a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>

      </a-form>
    </a-modal>

    <div class="loading" v-if="loading">
      <p>
        <a-spin/>
      </p>
      <p>上传中...</p>
    </div>
    <choiceCompany ref="choiceCompany" :InShow="InShow"></choiceCompany>
  </div>
</template>
<script>
import choiceCompany from './choiceCompany.vue'
import {add} from "@/api/Contract/List";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectData: [],
      fileUrl: process.env.VUE_APP_URL + 'Admin/Contract/List/add/file',
      form: this.$form.createForm(this),
      confirmLoading: false,
      visible: false,
      loading: false,
      InShow: null,
      TaskId: null,
      CcId: null,
      Task: this.isEdit ? this.commodityDetail.Task : [],
      Cc: this.isEdit ? this.commodityDetail.Cc : [],
      commodityDetail: {}
    };
  },
  methods: {
    beforeUpload(file) {
      const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      if (!isDOCX) {
        this.$message.error('请上传docx文件');
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('文件大小应在10MB之内');
      }

      this.loading = true
      return isDOCX && isLt2M
    },
    AddTaskId(e) {
      let response = e.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let payload = response.payload
          this.TaskId = payload
          this.$message.success('上传成功');
        }
      }
    },
    AddCc(e) {
      let response = e.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let payload = response.payload
          this.CcId = payload
          this.$message.success('上传成功');
        }
      }
    },
    addUser_Task() {
      let Task = this.Task
      Task.push({UserName: '', Type: '个人', UserPhone: ''})
      this.$message.success('添加个人成功');
    },
    addUser_Cc() {
      let Cc = this.Cc
      Cc.push({UserName: '', Type: '个人', UserPhone: ''})
      this.$message.success('添加个人成功');
    },
    openCompany_Task() {
      this.InShow = '参与'
      this.$refs.choiceCompany.open()
    },
    openCompany_Cc() {
      this.InShow = '抄送'
      this.$refs.choiceCompany.open()
    },
    addCompany_Task(value) {
      let Task = this.Task
      Task.push({UserName: value.UserName, Type: '企业', UserPhone: value.UserPhone, CompanyName: value.CompanyName})
    },
    addCompany_Cc(value) {
      let Cc = this.Cc
      Cc.push({UserName: value.UserName, Type: '企业', UserPhone: value.UserPhone, CompanyName: value.CompanyName})
    },
    removeTask(index) {
      let that = this
      this.$confirm({
        title: '是否确定删除该任务参与人吗?',
        onOk() {
          that.Task.splice(index, 1)
          that.$message.success('删除成功');
        },
      });

    },
    handleOk() {
      let that = this
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Title = values.Title

          let Task = this.Task
          let Cc = this.Cc

          if (Task.length == 0) {
            this.$message.error('参与人未添加');
            return;
          }

          let TaskId = this.TaskId
          let CcId = this.CcId

          if (!TaskId) {
            this.$message.error('签署文件未添加');
            return;
          }

          let User_id = this.$store.state.user.info.UserID
          let CompanyID = this.$store.state.user.info.CompanyID

          add({CompanyID,Title, Task, Cc, TaskId, CcId, User_id}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.handleCancel()
              that.$parent.getData()
            } else {
              that.$message.error(res.errorMsg);
            }
          })

        }
      })
    },
    upThumbnail(value) {
      this.thumbnailUrl = value
    },
    open(commodity) {
      this.visible = true;
      this.form.resetFields();
      this.Task = []
      this.commodityDetail = commodity;

    },
    handleCancel() {
      this.visible = false;
    },
  },
  components: {choiceCompany}
};
</script>

<style lang="scss">
.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  p {
    margin-right: 10px;
    height: 50px;
    line-height: 50px;
    color: #f3f3f3;
  }
}

.file {
  width: 480px;
  height: 100px;
  position: relative;

  .file_1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 400px;
  }

  .file_2 {
    position: absolute;
    left: 343px;
    top: 0;
    width: 400px;
  }
}

.in-i {
  display: inline-block;
  margin-right: 50px;
}

.Participate {
  width: 100%;
  height: 100px;
  margin-top: 20px;
  font-size: 13px;

  .name {
    width: 100%;
    height: 50px;
    background-color: #eff0f2;

    .block {
      display: inline-block;
      height: 50px;
      line-height: 50px;
      font-weight: bold;
      color: #000000;
      width: 250px;
      text-indent: 1rem;
    }

    .is {
      width: 100px;
    }

  }

  .data {
    width: 100%;
    height: 50px;
    background-color: #fafafa;
    position: relative;

    .block {
      display: inline-block;
      height: 50px;
      line-height: 50px;
      color: #000000;
      width: 250px;
      text-indent: 1rem;
      float: left;
    }

    .t {
      width: 200px;
    }

    .is {
      text-indent: 1rem;
      width: 100px;
    }

    .it {
      width: 40px;
      position: absolute;
      right: 40px;
    }
  }

}

.check {
  color: green;
}
</style>