<template>
  <div class="Login">
    <div class="logo">
<!--      <img src="/images/logo.png" alt="logo"/>-->
    </div>
<!--    <div class="title">贡献值系统</div>-->
    <div class="main">
      <a-form :form="form">
        <a-form-item label="账号">
          <a-input
              placeholder="请输入账号"
              v-decorator="[
                            'Phone',
                            {  rules: [{ required: true, message: '请输入账号' }] }
             ]"
          >
            <a-icon slot="prefix" type="user"/>
          </a-input>
        </a-form-item>
        <a-form-item label="密码">
          <a-input-password
              placeholder="请输入密码"
              v-decorator="[
                            'Password',
                             {  rules: [{ required: true, message: '请输入密码' }] }
                        ]">
            >
            <a-icon slot="prefix" type="lock"/>
          </a-input-password>
        </a-form-item>
      </a-form>
      <div class="nav">
        <!--        <a-checkbox @change="onChange">-->
        <!--          记住密码-->
        <!--        </a-checkbox>-->
        <div class="right">
          <a-button type="link" @click="openForget">
            忘记密码
          </a-button>
        </div>
      </div>
      <a-button type="primary" block class="go-login" @click="Login">
        登录
      </a-button>
      <a-button type="link" block class="go-login" @click="openAdd">
        申请
      </a-button>
    </div>
    <forget ref="forget"></forget>
    <add ref="add"></add>
    <selectCompany ref="selectCompany"></selectCompany>
  </div>
</template>

<script>
import forget from "@/pages/Login/com/forget";
import add from "@/pages/Login/com/add";
import selectCompany from './com/select.vue'
import {verify} from "@/api/Login";

export default {
  data() {
    return {
      form: this.$form.createForm(this)
    }
  },
  mounted() {
    this.autoPsthInfo()
  },
  methods: {
    //检查是否存在账号状态自动登录
    autoPsthInfo() {
      let token = localStorage.getItem("access-token");

      if (token) {
        console.log(token)
      }
    },
    Login() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Phone = values.Phone
          let Password = values.Password
          verify({Phone, Password}).then(res => {
            if (res.errorCode == 200) {
              let payload = res.payload

              let info = payload.info
              this.$store.state.user.info = info

              localStorage.setItem('auth_token', info.Token);
              localStorage.setItem('info', JSON.stringify(info));

              this.$message.success(payload.message);
              setTimeout(() => {
                this.$router.push('/')
              }, 200)
            } else if (res.errorCode == 202) {
              let payload = res.payload
              this.$refs.selectCompany.open(payload)
              localStorage.setItem('selectCompany', JSON.stringify(payload));
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    openForget() {
      this.$refs.forget.open()
    },
    openAdd() {
      this.$refs.add.open()
    },
    onChange() {

    }
  },
  components: {
    forget,
    add,
    selectCompany
  }
}
</script>

<style scoped lang="scss">
.Login {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  position: relative;

  .logo {
    width: 90px;
    height: 90px;
    position: absolute;
    left: 50%;
    margin-left: -45px;
    top: 50%;
    margin-top: -400px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    position: absolute;
    top: 50%;
    font-size: 20px;
    font-weight: bold;
    margin-top: -280px;
    text-align: center;
    width: 100%;
  }

  .main {
    width: 420px;
    height: 390px;
    padding: 20px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -205px;
    margin-top: -215px;

    .nav {
      width: 100%;
      height: 30px;
      position: relative;

      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .go-login {
      margin-top: 20px;
    }
  }
}
</style>
