import request from '../../../../utils/request';

//查询活动项目基本信息
export const find = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Main/index/find',
        method: 'get',
        params: query
    });
};


//更新项目信息Logo
export const UpLogo = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Main/index/upLogo',
        method: 'post',
        data: query
    });
};

//更新项目信息封面图
export const UpCover = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Main/index/upCover',
        method: 'post',
        data: query
    });
};

//线上图片转换Base64
export const ConvertImage = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Main/index/ConvertImage',
        method: 'post',
        data: query
    });
};


//图片Base64换取线上地址
export const ImageBase = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Main/index/ImageBase',
        method: 'post',
        data: query
    });
};

