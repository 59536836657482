var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":_vm.isEdit ? '编辑盲盒商品' : '添加盲盒商品',"okText":"提交","visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"width":"850px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-form-item',{attrs:{"label":"商品类型","required":true}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                  'type',
                                  {  rules: [{ required: true, message: '请选择商品类型' }],initialValue: _vm.isEdit ? _vm.commodityDetail.class : 1},
                              ]),expression:"[\n                                  'type',\n                                  {  rules: [{ required: true, message: '请选择商品类型' }],initialValue: isEdit ? commodityDetail.class : 1},\n                              ]"}]},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 实物 ")]),_c('a-radio',{attrs:{"value":2}},[_vm._v(" 虚拟 ")])],1)],1),_c('a-form-item',{attrs:{"label":"商品名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                  'name',
                                  {  rules: [{ required: true, message: '请输入商品'}], initialValue: _vm.isEdit ? _vm.commodityDetail.name : '' },
                              ]),expression:"[\n                                  'name',\n                                  {  rules: [{ required: true, message: '请输入商品'}], initialValue: isEdit ? commodityDetail.name : '' },\n                              ]"}],attrs:{"placeholder":"请输入商品名称"}})],1),_c('a-form-item',{attrs:{"label":"兑换贡献值"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                  'price',
                                  {  rules: [{ required: true, message: '请输入兑换贡献值' }], initialValue: _vm.isEdit ? _vm.commodityDetail.price : 0 },
                              ]),expression:"[\n                                  'price',\n                                  {  rules: [{ required: true, message: '请输入兑换贡献值' }], initialValue: isEdit ? commodityDetail.price : 0 },\n                              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入兑换贡献值"}})],1),_c('a-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":"商品奖池","required":true}},_vm._l((_vm.form.getFieldValue('specKeys')),function(k,index){return _c('a-form-item',{key:index,staticStyle:{"margin-bottom":"0"}},[_c('a-form-item',{style:({ display: 'inline-block', width: '180px' })},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              `specs[${index}]`,
                              {
                                  validateTrigger: ['change', 'blur'],
                                  rules: [
                                  {
                                      required: true,
                                      whitespace: true,
                                      message: '请输入商品标题',
                                  },
                                  ],
                                  initialValue: _vm.isEdit && index < _vm.commodityDetail.specDetail.length ? _vm.commodityDetail.specDetail[index].specName : '',
                              },
                          ]),expression:"[\n                              `specs[${index}]`,\n                              {\n                                  validateTrigger: ['change', 'blur'],\n                                  rules: [\n                                  {\n                                      required: true,\n                                      whitespace: true,\n                                      message: '请输入商品标题',\n                                  },\n                                  ],\n                                  initialValue: isEdit && index < commodityDetail.specDetail.length ? commodityDetail.specDetail[index].specName : '',\n                              },\n                          ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入商品标题"}})],1),_c('span',{style:({ display: 'inline-block', width: '60px', textAlign: 'center' })},[_vm._v(" 库存 ")]),_c('a-form-item',{style:({ display: 'inline-block', width: '100px' })},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              `remainNumbers[${index}]`,
                              {
                                  validateTrigger: ['change', 'blur'],
                                  rules: [
                                  {
                                      required: true,
                                      message: '请输入库存数',
                                  },
                                  ],
                                  initialValue: _vm.isEdit && index < _vm.commodityDetail.specDetail.length ? ''+_vm.commodityDetail.specDetail[index].remainNumber : '0',
                              },
                          ]),expression:"[\n                              `remainNumbers[${index}]`,\n                              {\n                                  validateTrigger: ['change', 'blur'],\n                                  rules: [\n                                  {\n                                      required: true,\n                                      message: '请输入库存数',\n                                  },\n                                  ],\n                                  initialValue: isEdit && index < commodityDetail.specDetail.length ? ''+commodityDetail.specDetail[index].remainNumber : '0',\n                              },\n                          ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入库存数"},on:{"change":_vm.countProbability}})],1),_c('span',{style:({ display: 'inline-block', width: '80px', textAlign: 'center' })},[_vm._v(" 概率 ")]),_c('a-form-item',{style:({ display: 'inline-block', width: '100px' })},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              `probability[${index}]`,
                              {
                                  validateTrigger: ['change', 'blur'],
                                  rules: [
                                  {
                                      required: true,
                                      message: '请输入概率',
                                  },
                                  ],
                                  initialValue: _vm.isEdit && index < _vm.commodityDetail.specDetail.length ? ''+_vm.commodityDetail.specDetail[index].remainNumber : '0',
                              },
                          ]),expression:"[\n                              `probability[${index}]`,\n                              {\n                                  validateTrigger: ['change', 'blur'],\n                                  rules: [\n                                  {\n                                      required: true,\n                                      message: '请输入概率',\n                                  },\n                                  ],\n                                  initialValue: isEdit && index < commodityDetail.specDetail.length ? ''+commodityDetail.specDetail[index].remainNumber : '0',\n                              },\n                          ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":"","addon-after":"%","placeholder":"请输入概率"}})],1),_c('a-button',{staticStyle:{"display":"inline-block","width":"80px","text-align":"center","margin-left":"20px"},attrs:{"type":"link"},on:{"click":function($event){return _vm.openProductDetails(index)}}},[_vm._v("商品详情 ")])],1)}),1),_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayoutWithOutLabel,false),[_c('a-button',{staticStyle:{"width":"100%"},attrs:{"type":"dashed"},on:{"click":function($event){return _vm.addSpecInput(true)}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 添加新商品 ")],1),_c('a-button',{staticStyle:{"width":"100%"},attrs:{"type":"dashed"},on:{"click":_vm.deleteSpecInput}},[_c('a-icon',{attrs:{"type":"minus"}}),_vm._v(" 删除最后一个新商品 ")],1)],1),_c('a-form-item',{attrs:{"label":"视频","required":true}},[_c('a-upload',{attrs:{"name":"file","multiple":false,"before-upload":_vm.beforePosterUploadVideo,"remove":_vm.handlePosterRemoveVideo,"file-list":_vm.videoFile,"action":_vm.videoUrl,"showUploadList":false,"headers":{
                      'X-Requested-With':null
                  }},on:{"change":_vm.addCoverVideo}},[_c('a-button',[_c('a-icon',{attrs:{"type":"video-camera"}}),_vm._v(" 上传视频 ")],1)],1),(_vm.CoverVideoUrl)?_c('a-button',{staticStyle:{"margin-left":"10px","color":"red"},attrs:{"type":"link"},on:{"click":_vm.deleteVideo}},[_vm._v(" 删除视频 ")]):_vm._e(),(_vm.CoverVideoUrl)?_c('video',{staticStyle:{"width":"300px"},attrs:{"src":_vm.CoverVideoUrl,"controls":""}}):_vm._e()],1),_c('a-form-item',{attrs:{"label":"缩略图","required":true}},[(_vm.thumbnailUrl)?_c('img',{staticStyle:{"width":"80px","height":"80px","display":"block"},attrs:{"src":_vm.thumbnailUrl,"alt":""}}):_vm._e(),_c('a-button',{attrs:{"icon":"upload"},on:{"click":_vm.openEditThumbnail}},[_vm._v("上传缩略图")])],1),_c('a-form-item',{attrs:{"label":"海报"}},[(_vm.posterUrl)?_c('img',{staticStyle:{"width":"300px","display":"block"},attrs:{"src":_vm.posterUrl,"alt":""}}):_vm._e(),_c('a-upload',{attrs:{"name":"file","multiple":false,"before-upload":_vm.beforePosterUpload,"remove":_vm.handlePosterRemove,"action":_vm.imageUrl,"headers":{
                      'X-Requested-With':null
                  },"show-upload-list":false},on:{"change":_vm.addPoster}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传海报 ")],1)],1),(_vm.posterUrl)?_c('a-button',{staticStyle:{"margin-left":"20px"},attrs:{"icon":"delete"},on:{"click":_vm.deleteImg}},[_vm._v("清除图片")]):_vm._e()],1),_c('a-form-item',{attrs:{"label":"兑换权限","required":true}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'privilege',
                      {  rules: [{ required: true, message: '请选择兑换权限' }],  initialValue: _vm.isEdit ? _vm.commodityDetail.privilege : 1 },
                  ]),expression:"[\n                      'privilege',\n                      {  rules: [{ required: true, message: '请选择兑换权限' }],  initialValue: isEdit ? commodityDetail.privilege : 1 },\n                  ]"}]},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 每个ID仅一次 ")]),_c('a-radio',{attrs:{"value":2}},[_vm._v(" 不限 ")])],1)],1),_c('a-form-item',{attrs:{"label":"商品简介"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'commodityText',
                              {  rules: [{ required: true, message: '请输入商品简介' }], initialValue: _vm.isEdit ? _vm.commodityDetail.commodityText : '' },
                          ]),expression:"[\n                              'commodityText',\n                              {  rules: [{ required: true, message: '请输入商品简介' }], initialValue: isEdit ? commodityDetail.commodityText : '' },\n                          ]"}],attrs:{"placeholder":"请输入商品简介","auto-size":{ minRows: 3, maxRows: 5 }}})],1),_c('a-form-item',{attrs:{"label":"兑换须知"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'notification',
                              {  rules: [{ required: true, message: '请输入兑换须知' }], initialValue: _vm.isEdit ? _vm.commodityDetail.notice : '' },
                          ]),expression:"[\n                              'notification',\n                              {  rules: [{ required: true, message: '请输入兑换须知' }], initialValue: isEdit ? commodityDetail.notice : '' },\n                          ]"}],attrs:{"placeholder":"请输入兑换须知","auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1),(_vm.loading)?_c('div',{staticClass:"loading"},[_c('p',[_c('a-spin')],1),_c('p',[_vm._v("加载中...")])]):_vm._e(),_c('editThumbnail',{ref:"editThumbnail"}),_c('ProductDetails',{ref:"ProductDetails"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }