<template>
  <a-modal
      title="图像编辑"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :width="700"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <div class="main">
      <div class="heaedr"></div>
      <div class="cut">
        <vue-cropper ref="cropper" :img="option.img" :output-size="option.size" :output-type="option.outputType"
                     :info="true" :full="option.full" :fixed="fixed" :fixed-number="fixedNumber"
                     :can-move="option.canMove" :can-move-box="option.canMoveBox" :fixed-box="option.fixedBox"
                     :original="option.original"
                     :auto-crop="option.autoCrop" :auto-crop-width="option.autoCropWidth"
                     :auto-crop-height="option.autoCropHeight" :center-box="option.centerBox"
                     :high="option.high"
                     :max-img-size="option.max"></vue-cropper>
      </div>
      <div class="test-button">
        <button @click="changeImg" class="btn">清除图片</button>
        <label class="btn" for="uploads">上传图片</label>
        <input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);"
               accept="image/png, image/jpeg, image/gif, image/jpg"
               @change="uploadImg($event, 1)">
      </div>
      <div class="fin">上传大小限制10MB</div>
    </div>
  </a-modal>
</template>

<script>
import {VueCropper} from "vue-cropper";
import {ImageBase} from "@/api/Admin/ObjectDetails/Main";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      avatar: false,
      model: false,
      modelSrc: '',
      crap: false,
      previews: {},
      lists: [
        {
          img: '',
        },
      ],
      option: {
        img: '',
        size: 1,
        outputSize: 0.1,
        full: false,
        outputType: 'jpeg',
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        mode: 'cover',
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 100,
        autoCropHeight: 100,
        centerBox: true,
        high: true,
        max: 99999,
      },
      show: true,
      fixed: true,
      fixedNumber: [2, 1],
    }
  },
  methods: {
    handleOk() {
      this.down()
    },
    open(img) {
      this.visible = true
      if (img){
        this.option.img = img
        this.lists[0].img = img
      }
    },
    handleCancel() {
      this.visible = false
      this.lists[0].img = ''
      this.option.img = ''
    },
    changeImg() {
      this.option.img = this.lists[~~(Math.random() * this.lists.length)].img;
    },
    down() {
      let that = this
      this.$refs.cropper.getCropData((data) => {
        if (data) {
          that.confirmLoadin = true
          ImageBase({data}).then(res => {
            that.confirmLoadin = false
            let payload = res.payload
            this.$parent.upcover(payload)
            this.handleCancel()
          })

        } else {
          console.log('图片为空')
        }
      });
    },

    uploadImg(e) {
      //上传图片
      let file = e.target.files[0];
      // this.option.img
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种');
        return false;
      }

      if (file.size / (1024 * 1024) >= 10) {
        console.log('上传图片大小限制为10MB之内')
        return;
      }


      let reader = new FileReader();
      // console.log(reader)
      reader.onload = (k) => {
        let data;

        data = k.target.result
        this.option.img = data

      }
      reader.readAsDataURL(file)
      // reader.readAsDataURL(file)
      //   let data;
      //   if (typeof e.target.result === 'object') {
      //     // 把Array Buffer转化为blob 如果是base64不需要
      //     data = window.URL.createObjectURL(new Blob([e.target.result]));
      //   } else {
      //     data = e.target.result;
      //   }
      //   if (num === 1) {
      //     this.option.img = data;
      //   } else if (num === 2) {
      //     this.example2.img = data;
      //   }
      // };
      // // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      // reader.readAsArrayBuffer(file);
    }
  },
  components: {VueCropper},
}
</script>


<style scoped lang="scss">
.main {
  width: 650px;
  height: 370px;

  .heaedr {
    width: 100%;
    height: 30px;
  }

  .cut {
    width: 500px;
    height: 240px;
    margin: 0 auto;
  }

  .test-button {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }

  .btn {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid #000;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0 0 0 0;
    padding: 9px 15px;
    margin-right: 10px;
    font-size: 11px;
    border-radius: 4px;
    color: #000;
    background-color: #f3f3f3;
    transition: all .2s ease;
    text-decoration: none;
    user-select: none;
  }

  .img {
    position: absolute;
    width: 1000px;
    height: 500px;
    background-color: #898989;
    left: 50%;
    margin-left: -135px;
    top: 30px;
  }

  .fin {
    width: 100%;
    height: 36px;
    line-height: 36px;
    color: #973733;
    text-indent: 1rem;
    font-size: 12px;
    text-align: center;
  }
}
</style>