<template>
  <div class="Object">
    <div class="topBlock"></div>
    <div class="topNav">
      <a-button type="primary" class="search-btn" @click="openAddModel" icon="plus">
        添加商品
      </a-button>
      <a-button type="primary" class="search-btn" @click="openAddModel_Blindbox" icon="plus">
        添加盲盒商品
      </a-button>
      <a-button type="primary" class="search-btn" @click="openSelfBox_Admin" icon="medicine-box">
        自提点管理
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             class="table"
             :rowKey="(record, index) => { return index }"
             :loading="loading">
        <span slot="commodityImage" slot-scope="tags,record">
            <a-avatar :size="30" icon="user" shape="square" :src="record.thumbnail"/>
        </span>
      <span slot="privilege" slot-scope="text,record">
            <a-tag v-if="record.privilege == 1" color="green">仅一次</a-tag>
            <a-tag v-else color="blue">不限ID</a-tag>
        </span>
      <span slot="class" slot-scope="text,record">
            <a-tag v-if="record.class == 1" color="green">实物</a-tag>
            <a-tag v-else color="blue">虚拟</a-tag>
        </span>
      <span slot="blindBox" slot-scope="text,record">
            <a-tag v-if="record.blindBox == 0">非盲盒</a-tag>
            <a-tag v-else color="blue">盲盒</a-tag>
        </span>
      <span slot="operation" slot-scope="text,record">
            <a-button type="link" @click="EditCommodity(record.id)" icon="edit"
                      v-if="record.blindBox==0">查看编辑</a-button>
            <a-button type="link" @click="EditCommodityBox(record.id)" icon="edit"
                      v-if="record.blindBox==1">查看编辑</a-button>
            <a-button type="link" @click="DeleteCommodity(record.id)" icon="delete" style="color: red">删除</a-button>
      </span>
    </a-table>
    <AddModel ref="AddModel" @refetch="getData" :isEdit="false"></AddModel>
    <AddModel ref="EditModel" @refetch="getData" :isEdit="true" :commodityDetail="commodityDetail"></AddModel>

    <addModels_Blindbox ref="addModels_Blindbox" @refetch="getData" :isEdit="false"></addModels_Blindbox>
    <addModels_Blindbox ref="editModels_Blindbox" @refetch="getData" :isEdit="true"
                        :commodityDetail="commodityDetail"></addModels_Blindbox>
    <SelfBox_Admin ref="SelfBox_Admin"></SelfBox_Admin>
  </div>
</template>

<script>
import {getCommodityList, deleteCommodity} from "@/api/Commodities/List";
import notification from 'ant-design-vue/es/notification';
import AddModel from './com/addModel.vue'
import addModels_Blindbox from './com/addModels_Blindbox.vue'
import indexTest from "./moble/index";
import SelfBox_Admin from './com/SelfBox_Admin.vue'

let {addModel} = indexTest

export default {
  data() {
    return {
      columns: addModel,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
      commodityDetail: {}
    }
  },
  components: {AddModel, addModels_Blindbox,SelfBox_Admin},
  mounted() {
    this.getData();
  },
  methods: {
    //打开自提点管理
    openSelfBox_Admin(){
      this.$refs.SelfBox_Admin.open()
    },
    //打开盲盒分类
    openAddModel_Blindbox() {
      this.$refs.addModels_Blindbox.open()
    },
    openAddModel() {
      this.$refs.AddModel.open({});
    },
    EditCommodityBox(id) {
      const commodity = this.data.find(item => item.id == id);
      this.$refs.editModels_Blindbox.open(commodity);
    },
    EditCommodity(id) {
      const commodity = this.data.find(item => item.id == id);
      this.$refs.EditModel.open(commodity);
    },
    async DeleteCommodity(id) {
      const that = this;
      this.$confirm({
        title: '是否删除该商品?',
        onOk() {
          try {
            deleteCommodity(id).then(res => {
              if (res.errorCode == 200) {
                notification.success({message: '删除成功!'});
                that.getData();
              } else {
                notification.error({message: '删除失败', description: res.errorMsg});
              }
            })
          } catch (err) {
            console.log(err);
            notification.error({message: '删除失败', description: err});
          }
        },
        class: 'test',
      });
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getData();
    },
    async getData() {
      this.loading = true
      const page = this.pagination.current;
      const pageSize = this.pagination.pageSize;
      let CompanyID = this.$store.state.user.info.CompanyID

      try {
        // get addrs
        let {errorCode, payload} = await getCommodityList(page, pageSize, CompanyID);
        if (errorCode == 200) {
          this.data = payload.commodities;
          this.pagination.total = payload.total;
          this.loading = false;
        } else {
          notification.error({message: '获取商品列表失败', description: errorCode});
        }
      } catch (err) {
        console.log(err);
        notification.error({message: '获取商品列表失败', description: err});
      }
    },
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }

  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }


}

.table {
  width: 98%;
  margin: 0 auto;
  height: 700px;
  overflow-y: auto;
}

.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}
</style>
  