<template>
  <div class="Distribution">
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             :rowKey="(record, index) => { return index }"
             class="table"
             :loading="loading">
            <span slot="Operate" slot-scope="text,record">
                <a-button type="link" html-type="submit" @click="GoToDetails(record.id)">处理</a-button>
            </span>
      <span slot="ImgUrl" slot-scope="text">
          <a-avatar :size="30" icon="user" shape="square" :src="text" v-if="text"/>
            </span>

    </a-table>
    <edit ref="edit"></edit>
  </div>
</template>

<script>
import indexTest from "@/pages/Admin/pages/ObjectDetails/Com/Message/moble/indexTest";
import edit from './com/edit.vue'
import {find} from "@/api/Admin/ObjectDetails/Interest";

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  components: {edit},
  mounted() {
    this.getdata()
  },
  methods: {
    GoToDetails(value) {
      this.$refs.edit.open(value)
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.loading = true
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.$route.query.Id
      let Current = this.pagination.current

      find({CompanyID, ObjectID, Current}).then(res => {
        this.loading = false
        let payload = res.payload
        this.pagination.total = payload.Total

        let data=payload.data
        for (let i in data) {
          let time = data[i].Time
          let day = new Date(time);
          data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
        }

        this.data = data
      })
    },
    getSumRegexData(data) {
      this.loading = false
      this.data = data
      this.pagination.current = 1
      this.pagination.total = 0
    },
    getRegexData() {
      this.loading = true
    },
    resetting() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.getdata()
    }
  },
}
</script>

<style scoped lang="scss">

.Distribution {
  width: 100%;
  height: 600px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 10px;
  }

  .table {
    width: 98%;
    margin: 0 auto;
  }
}

.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}
</style>
