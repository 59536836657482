<template>
  <div class="Object">
    <div class="topBlock"></div>
    <div class="topNav">
      <a-button class="search-btn" @click="openUserAdd">添加管理员</a-button>
    </div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             class="table"
             :rowKey="(record, index) => { return index }"
             :loading="loading">
        <span slot="Avatar" slot-scope="tags,record">
          <a-avatar :size="30" icon="user" shape="square" :src="record.Avatar"/>
        </span>
      <span slot="Operate" slot-scope="text,record">
        <a-button type="link" @click="deleteUser(record.id)" class="red" :disabled="record.Jurisdiction=='Root'||record.Jurisdiction=='企业管理员'">删除</a-button>
      </span>
    </a-table>
    <AddUser ref="AddUser"></AddUser>
  </div>
</template>

<script>
import indexTest from "./moble/indexTest";
import AddUser from './com/AddUser.vue'
import {find,deleteUser} from "@/api/AdminCompany/User";
let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  components: {AddUser},
  mounted() {
    this.getdata()
  },
  methods: {
    openUserAdd(){
      this.$refs.AddUser.open()
    },
    deleteUser(id) {
      let that = this
      this.$confirm({
        title: '是否删除该管理员吗?',
        onOk() {
          deleteUser({id}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.loading = true
      let Current=this.pagination.current
      let CompanyID = this.$store.state.user.info.CompanyID

      find({Current,CompanyID}).then(res=>{
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload

          for (let i in data) {
            let time = data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }

          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    getRegexData() {
      this.loading = true
    },
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }

  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }

  .table {
    width: 98%;
    margin: 0 auto;
  }
}


.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}
</style>
