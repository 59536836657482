import request from '../../../utils/request';

//查询项目报道
export const find = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Report/find',
        method: 'get',
        params: query
    });
};

//添加项目报道
export const add = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Report/add',
        method: 'post',
        data: query
    });
};

// 编辑项目报道
export const edit = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Report/edit',
        method: 'post',
        data: query
    });
};

//删除项目报道
export const deleteset = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Report/delete',
        method: 'delete',
        data: query
    });
};