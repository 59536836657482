<template>
  <div>
    <a-modal
        :title="isEdit ? '编辑商品' : '添加商品'"
        okText="提交"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-item label="商品类型" :required="true">
          <a-radio-group
              v-decorator="[
                                    'type',
                                    {  rules: [{ required: true, message: '请选择商品类型' }],  initialValue: isEdit ? commodityDetail.class : 1 },
                                ]"
          >
            <a-radio :value="1">
              实物
            </a-radio>
            <a-radio :value="2">
              虚拟
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="商品名称">
          <a-input
              placeholder="请输入商品名称"
              v-decorator="[
                                    'name',
                                    {  rules: [{ required: true, message: '请输入商品'}], initialValue: isEdit ? commodityDetail.name : '' },
                                ]"
          />
        </a-form-item>
        <a-form-item label="兑换贡献值">
          <a-input-number style="width: 100%;"
                          placeholder="请输入兑换贡献值"
                          v-decorator="[
                                    'price',
                                    {  rules: [{ required: true, message: '请输入兑换贡献值' }], initialValue: isEdit ? commodityDetail.price : 0 },
                                ]"
          />
        </a-form-item>
        <a-form-item label="规格" :required="true" style="margin-bottom: 0;">
          <a-form-item
              style="margin-bottom:0;"
              v-for="(k, index) in form.getFieldValue('specKeys')"
              :key="k + '' + index"
          >
            <a-form-item
                :style="{ display: 'inline-block', width: 'calc(50% - 30px)' } "
            >
              <a-input
                  placeholder="请输入规格"
                  v-decorator="[
                                `specs[${k}]`,
                                {
                                    validateTrigger: ['change', 'blur'],
                                    rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入规格',
                                    },
                                    ],
                                    initialValue: isEdit && index < commodityDetail.specDetail.length ? commodityDetail.specDetail[index].specName : '',
                                },
                            ]"
                  style="width: 100%"
              />
            </a-form-item>
            <span :style="{ display: 'inline-block', width: '60px', textAlign: 'center' }">
                        库存
                    </span>
            <a-form-item :style="{ display: 'inline-block', width: 'calc(50% - 30px)' }">
              <a-input-number style="width: 100%;"
                              placeholder="请输入库存数"
                              v-decorator="[
                                `remainNumbers[${k}]`,
                                {
                                    validateTrigger: ['change', 'blur'],
                                    rules: [
                                    {
                                        required: true,
                                        message: '请输入库存数',
                                    },
                                    ],
                                    initialValue: isEdit && index < commodityDetail.specDetail.length ? ''+commodityDetail.specDetail[index].remainNumber : '0',
                                },
                            ]"
              />
            </a-form-item>
          </a-form-item>

        </a-form-item>
        <a-form-item v-bind="formItemLayoutWithOutLabel">
          <a-button type="dashed" style="width: 100%" @click="addSpecInput(true)">
            <a-icon type="plus"/>
            添加新规格
          </a-button>
          <a-button type="dashed" style="width: 100%" @click="deleteSpecInput">
            <a-icon type="minus"/>
            删除最后一个规格
          </a-button>
        </a-form-item>
        <a-form-item label="视频">
          <!--          <img :src="thumbnailUrl" v-if="thumbnailUrl" style="width: 80px;height: 80px;display: block" alt=""/>-->
          <!--          <a-button @click="openEditThumbnail" icon="video-camera">上传视频</a-button>-->
          <a-upload
              name="file"
              :multiple="false"
              :before-upload="beforePosterUploadVideo"
              :remove="handlePosterRemoveVideo"
              :file-list="videoFile"
              :action="videoUrl"
              @change="addCoverVideo"
              :showUploadList="false"
              :headers="{
                        'X-Requested-With':null
                    }"
          >
            <a-button>
              <a-icon type="video-camera"/>
              上传视频
            </a-button>
          </a-upload>
          <a-button type="link" style="margin-left: 10px;color: red" v-if="CoverVideoUrl" @click="deleteVideo">
            删除视频
          </a-button>
          <video :src="CoverVideoUrl" style="width: 300px" v-if="CoverVideoUrl" controls></video>
        </a-form-item>
        <a-form-item label="缩略图" :required=true>
          <img :src="thumbnailUrl" v-if="thumbnailUrl" style="width: 80px;height: 80px;display: block" alt=""/>
          <a-button @click="openEditThumbnail" icon="upload">上传缩略图</a-button>
        </a-form-item>
<!--        beforeThumbnailUpload-->
        <a-form-item label="海报">
          <img :src="posterUrl" v-if="posterUrl" alt="" style="width: 300px">
          <a-upload
              name="file"
              :multiple="false"
              :before-upload="beforePosterUpload"
              :remove="handlePosterRemove"
              :action="imageUrl"
              @change="addPoster"
              :headers="{
                        'X-Requested-With':null
                    }"
              :show-upload-list="false"
          >
            <a-button>
              <a-icon type="upload"/>
              上传海报
            </a-button>
          </a-upload>
          <a-button @click="deleteImg" icon="delete" style="margin-left: 20px" v-if="posterUrl">清除图片</a-button>
        </a-form-item>
        <a-form-item label="兑换权限" :required="true">
          <a-radio-group
              v-decorator="[
                        'privilege',
                        {  rules: [{ required: true, message: '请选择兑换权限' }],  initialValue: isEdit ? commodityDetail.privilege : 1 },
                    ]"
          >
            <a-radio :value="1">
              每个ID仅一次
            </a-radio>
            <a-radio :value="2">
              不限
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="商品简介">
          <a-textarea
              placeholder="请输入商品简介"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                                'commodityText',
                                {  rules: [{ required: true, message: '请输入商品简介' }], initialValue: isEdit ? commodityDetail.commodityText : '' },
                            ]"
          />
        </a-form-item>
        <a-form-item label="兑换须知">
          <a-textarea
              placeholder="请输入兑换须知"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                                'notification',
                                {  rules: [{ required: true, message: '请输入兑换须知' }], initialValue: isEdit ? commodityDetail.notice : '' },
                            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <editThumbnail ref="editThumbnail"></editThumbnail>
    <div class="loading" v-if="loading">
      <p>
        <a-spin/>
      </p>
      <p>加载中...</p>
    </div>
  </div>
</template>
<script>

import {addCommodity, editCommodity} from '@/api/Commodities/List';
import notification from 'ant-design-vue/es/notification';
import editThumbnail from './editThumbnail.vue'
import {ConvertImage} from "@/api/Admin/ObjectDetails/Main";
// import { readFileAsync } from '@/utils/general';

let specId = 0;
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      formLayout: 'horizontal',
      thumbnailFile: [],
      thumbnailUrl: '',
      videoFile: [],
      CoverVideoUrl: '',
      posterUrl: '',
      videoUrl: process.env.VUE_APP_URL + 'Website/exchange/GetFileVideo',
      imageUrl: process.env.VUE_APP_URL + 'Enterprise/Company/image',
      formItemLayout: {
        labelCol: {
          xs: {span: 24},
          sm: {span: 5},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 19},
        },
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: {span: 24, offset: 0},
          sm: {span: 19, offset: 5},
        },
      },
      commodityDetail: {},
      loading: false
    };
  },
  components: {editThumbnail},
  beforeCreate() {
    this.form = this.$form.createForm(this, {name: 'dynamic_form_item'});
    this.form.getFieldDecorator('specKeys', {initialValue: [], preserve: true});
  },
  methods: {
    openEditThumbnail() {
      let url = this.thumbnailUrl
      if (url) {
        this.loading = true
        ConvertImage({url}).then(res => {
          this.loading = false
          let payload = res.payload
          this.$refs.editThumbnail.open(payload)
        })
      } else {
        this.$refs.editThumbnail.open()
      }
    },
    upThumbnail(value) {
      this.thumbnailUrl = value
    },
    open(commodity) {
      this.visible = true;
      this.form.resetFields();
      this.LogoImg = '';
      this.commodityDetail = commodity;
      this.thumbnailUrl = commodity.thumbnail || '';
      this.posterUrl = commodity.poster || '';
      this.CoverVideoUrl = commodity.video || '';
      specId = 0;
      this.addSpecInput(false);
    },
    addSpecInput(isBlank) {
      const {form} = this;

      const keys = form.getFieldValue('specKeys');
      let nextKeys = keys;

      if (!isBlank && this.commodityDetail && this.commodityDetail.specDetail) {
        for (let i = 0; i < this.commodityDetail.specDetail.length; i++) {
          nextKeys = nextKeys.concat(specId);
          specId++;
        }
      } else {
        nextKeys = keys.concat(specId++);
      }

      form.setFieldsValue({
        specKeys: nextKeys,
      });
    },
    deleteSpecInput() {
      const {form} = this;
      const keys = form.getFieldValue('specKeys');

      if (keys.length === 1 || (this.commodityDetail && this.commodityDetail.specDetail && keys.length === this.commodityDetail.specDetail.length)) {
        return;
      }
      form.setFieldsValue({
        specKeys: keys.slice(0, -1),
      });
    },
    handleOk() {
      if (this.thumbnailUrl == '') {
        notification.error({message: '缩略图未上传或未上传成功'});
        return;
      }
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            let CompanyID = this.$store.state.user.info.CompanyID
            values.company_ID = CompanyID
            this.confirmLoading = true;
            values.thumbnailUrl = this.thumbnailUrl || '';
            values.posterUrl = this.posterUrl || '';
            values.video = this.CoverVideoUrl || '';

            if (this.$props.isEdit) {
              let {errorCode} = await editCommodity(values, this.commodityDetail.id);
              if (errorCode == 200) {
                notification.success({message: '编辑商品成功'});
                this.$emit('refetch');
                this.handleCancel()
              } else {
                notification.error({message: '编辑商品失败'});
              }
            } else {
              let {errorCode} = await addCommodity(values);
              if (errorCode == 200) {
                notification.success({message: '添加商品成功'});
                this.$emit('refetch');
                this.handleCancel()
              } else {
                notification.error({message: '添加商品失败'});
              }
            }
            this.confirmLoading = false;
          } catch (err) {
            console.log(err);
            this.confirmLoading = false;
            notification.error({message: '添加商品失败'});
          }
        }
      })
    },
    beforeThumbnailUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isGIF = file.type === 'image/gif';
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isJPG && !isPNG && !isGIF) {
        this.$message.error('只能上传JPG/PNG/GIF格式的图片!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 20MB!');
        return false;
      }
      this.thumbnailFile = [file];
      return true;
    },
    beforePosterUploadVideo(file) {
      const isMp4 = file.type === 'video/mp4';
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isMp4) {
        this.$message.error('只能上传Mp4格式的视频!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('视频大小不能超过 25MB!');
        return false;
      }
      this.videoFile = [file];
      this.loading = true
      return true;
    },
    addCoverVideo(info) {
      let response = info.file.response;
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let url = response.payload;
          this.CoverVideoUrl = url;
          this.$message.success('上传成功');
        }
      }
    },
    deleteVideo() {
      let that = this
      this.$confirm({
        title: '是否删除该视频吗?',
        onOk() {
          that.CoverVideoUrl = ''
          that.videoFile = []
          that.$message.success('删除成功');
        },
      });
    },
    beforePosterUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isGIF = file.type === 'image/gif';
      // const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG && !isGIF) {
        this.$message.error('只能上传JPG/PNG/GIF格式的图片!');
        return false;
      }
      this.posterUrl = file;
      return true;
    },
    handleCancel() {
      this.visible = false;
    },
    deleteImg(){
      this.posterUrl=''
    },
    handlePosterRemove() {
      this.posterFile = [];
    },
    handlePosterRemoveVideo() {
      this.videoFile = [];
    },
    addThumbnail(info) {
      let response = info.file.response;
      if (response) {
        if (response.errorCode == 200) {
          let url = response.payload;
          this.thumbnailUrl = url;
        }
      }
    },
    addPoster(info) {
      let response = info.file.response;
      if (response) {
        if (response.errorCode == 200) {
          let url = response.payload;
          this.posterUrl = url;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  p {
    margin-right: 10px;
    height: 50px;
    line-height: 50px;
    color: #f3f3f3;
  }
}
</style>