<template>
  <a-modal
      title="编辑器"
      :visible="visible"
      :width="1000"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <quill-editor
        :content="content"
        :options="editorOption"
        @change="onEditorChange($event)"
    />
  </a-modal>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import {quillEditor} from 'vue-quill-editor'

export default {
  components: {quillEditor},
  data() {
    return {
      content: '',
      editorOption: {
        placeholder: '请输入内容',
      },
      visible: false
    }
  },
  mounted() {

  },
  methods: {
    handleCancel() {
      this.html = ''
      this.visible = false
    },
    open(data) {
      this.visible = true
      setTimeout(() => {
        this.html = data
      })
    },
    onEditorChange({html}) {
      this.content = html
    },
    handleSubmit() {
      let data = this.content
      let that = this
      this.$confirm({
        title: '是否确认提交?',
        onOk() {
          that.$parent.AddTextdata(data)
          that.handleCancel()
        },
      });
    }
  },
  computed: {
    // editor() {
    //   console.log(1)
    //   // return this.$refs.myQuillEditor.quill
    // }
  },
}
</script>


<style scoped lang="scss">
::v-deep .ql-editor {
  min-height: 300px;
}
</style>