<template>
  <div>
    <a-modal
        title="详情"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="handleCancel"
        :width="1000"
    >
      <a-steps :current="steps" class="steps" v-if="data.InShow!='已取消'">
        <a-step title="管理员审核"/>
        <a-step title="等待发起签署"/>
        <a-step title="确认发起签字"/>
        <a-step title="等待签字完毕"/>
        <a-step title="完成"/>
      </a-steps>
      <a-descriptions bordered>
        <a-descriptions-item label="社区项目">
          {{ data.Title }}
        </a-descriptions-item>
        <a-descriptions-item label="申请时间">
          {{ data.Time }}
        </a-descriptions-item>
        <a-descriptions-item label="状态" :span="3">
          <a-badge v-if="data.InShow=='审核中'" status="processing" text="审核中"/>
          <a-badge v-else-if="data.InShow=='待发起'" status="warning" text="待发起"/>
          <a-badge v-if="data.InShow=='待生成'" status="warning" text="待生成"/>
          <a-badge v-if="data.InShow=='已生成'" status="warning" text="已生成"/>
          <a-badge v-if="data.InShow=='签署中'" status="processing" text="签署中"/>
          <a-badge v-if="data.InShow=='已取消'" status="error" text="已取消"/>
          <a-badge v-if="data.InShow=='项目已成立'" status="success" text="项目已成立"/>
        </a-descriptions-item>
        <a-descriptions-item label="Logo图标">
          <a-avatar :size="30" icon="user" shape="square" :src="data.LogoImage"/>
        </a-descriptions-item>
        <a-descriptions-item label="委员会数量">
          {{ data.CommitteeNumber }}
        </a-descriptions-item>
        <a-descriptions-item label="贡献值额度">
          {{ data.ToNumber }}
        </a-descriptions-item>
        <a-descriptions-item label="共同发起人" :span="3">
          <a-tag v-for="(item,index) in data.PinUser" :key="index">{{ item.Name }}-{{ item.Phone }}<span v-if="item.Remarks">({{item.Remarks}})</span></a-tag>
        </a-descriptions-item>
      </a-descriptions>
      <template slot="footer">
        <a-button class="button" @click="generateFile" v-if="data.InShow=='待生成'" :loading="confirmLoading">生成文件
        </a-button>
        <a-button class="button" @click="lookFileId" v-if="data.InShow=='已生成'">查看签署文件</a-button>
        <a-button class="button" @click="initiateObject" v-if="data.InShow=='已生成'" :loading="confirmLoading">
          发起签署
        </a-button>
        <a-button class="button" v-if="data.InShow=='签署中'" @click="ConfirmUserlist" :loading="confirmLoading">
          确认签署状态
        </a-button>
        <a-button class="button" v-if="data.InShow=='已取消'||data.InShow=='项目已成立'" @click="lookFileId"
                  :loading="confirmLoading">
          查看签署文件
        </a-button>
        <a-button class="button" v-if="data.InShow=='签署中'||data.InShow=='待生成'||data.InShow=='已生成'"
                  @click="ExitConfir" :loading="confirmLoading" type="danger">
          取消&终止签署流程
        </a-button>
      </template>

    </a-modal>
  </div>
</template>
<script>
import {ApplyObjectGenerateFile, ExitCon, findApplyProjectID, InitiateObject, LookFileId,Confirm, ConfirmUserList} from "@/api/Admin/Object";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      data: {},
      steps: 0
    };
  },
  methods: {
    //生成签署文件
    generateFile() {
      let id = this.data.id
      this.confirmLoading = true
      ApplyObjectGenerateFile({id}).then(res => {
        this.confirmLoading = false
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.getdata()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    //查看签署文件
    lookFileId() {
      let FileId = this.data.FileId
      this.confirmLoading = true
      LookFileId({FileId}).then(res => {
        this.confirmLoading = false
        if (res.errorCode == 200) {
          this.$message.success('获取成功');
          window.open(res.payload)
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    //发起签署
    initiateObject() {
      let id = this.data.id
      let that = this
      this.$confirm({
        title: '是否确认发起签署吗?',
        onOk() {
          that.confirmLoading = true
          InitiateObject({id}).then(res => {
            that.confirmLoading = false
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    //确认签署状态
    ConfirmUserlist() {
      let id = this.id
      this.confirmLoading = true
      let that = this
      ConfirmUserList({id}).then(res => {
        if (res.errorCode == 200) {
          let payload = res.payload
          let userText = ''
          let Tnumber = 0
          for (let i in payload) {
            if (payload[i].class == '未签') {
              userText += payload[i].name + '-未签署' + ' '
              Tnumber++
            }
          }
          if (Tnumber > 0) {
            this.confirmLoading = false
            this.$message.error(userText);
          } else {
            Confirm({id}).then(res => {
              this.confirmLoading = false
              if (res.errorCode == 200) {
                that.$message.success(res.payload);
                that.getData()
              } else {
                that.$message.error(res.errorMsg);
              }
            })
          }
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    //取消&终止签署流程
    ExitConfir() {
      let id = this.data.id
      let that = this
      this.$confirm({
        title: '是否确认终止该流程吗?',
        onOk() {
          that.confirmLoading = true
          ExitCon({id}).then(res=>{
            that.confirmLoading = false
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    //打开
    open(id) {
      this.visible = true
      this.id = id

      this.getdata()
    },
    getdata() {
      let id = this.id
      findApplyProjectID({id}).then(res => {
        if (res.errorCode == 200) {
          let data = res.payload
          let PinUser = JSON.parse(data.PinUser)
          data.PinUser = PinUser

          this.stepsClass(data.InShow)
          let time = data.Time
          let day = new Date(time);
          data.Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          this.data = data;
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    handleCancel() {
      this.visible = false;
      this.$parent.getdata()
    },
    stepsClass(value) {
      if (value == '审核中') {
        this.steps = 0
      } else if (value == '待发起') {
        this.steps = 1
      } else if (value == '待发起') {
        this.steps = 2
      } else if (value == '签署中') {
        this.steps = 3
      } else if (value == '已通过') {
        this.steps = 4
      }
    }
  },
};
</script>

<style lang="scss">
.steps {
  margin-top: 20px;
  margin-bottom: 40px;
}

.button {
  display: inline-block;
  margin-top: 10px;
}
</style>