<template>
  <div>
    <a-modal
        title="贡献值详情"
        :visible="visible"
        :footer="null"
        @cancel="handleCancel"
        :width="900"
    >
      <a-table :columns="columns" :data-source="data" :pagination="false"
               :rowKey="(record, index) => { return index }"
               class="table"
               :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,getCheckboxProps:ongetCheckboxProps,hideDefaultSelections:true }"
               :loading="loading"
               v-if="InShow=='签署完成'"
      >
        <span slot="TolType" slot-scope="tags">
             <a-button type="link" html-type="submit" @click="opendata(tags)">详情</a-button>
        </span>
        <span slot="ToNumber" slot-scope="tags">
          {{ tags }}QB
        </span>
        <span slot="BonusClass" slot-scope="tags,record">
             <a-tag color="red" v-if="record.Bonus.InShow=='未发起奖金派发'">{{ record.Bonus.InShow }}</a-tag>
             <a-tag color="orange" v-if="record.Bonus.InShow=='未发放'">{{ record.Bonus.InShow }}</a-tag>
             <a-tag color="green" v-if="record.Bonus.InShow=='已发放'">{{ record.Bonus.InShow }}</a-tag>
        </span>
        <span slot="BonusTransAmount" slot-scope="tags,record">
          <span v-if="record.Bonus.InShow!='未发起奖金派发'"><b>{{ record.Bonus.TransAmount }}</b>元</span>
        </span>
      </a-table>
      <a-table :columns="columns_1" :data-source="data" :pagination="false"
               :rowKey="(record, index) => { return index }"
               class="table"
               :loading="loading"
               v-else
      >
        <span slot="TolType" slot-scope="tags">
             <a-button type="link" html-type="submit" @click="opendata(tags)">详情</a-button>
        </span>
        <span slot="ToNumber" slot-scope="tags">
          {{ tags }}QB
        </span>
      </a-table>



      <span style="margin-left: 8px;margin-top: 20px" v-if="InShow=='签署完成'">
        <template>
          <span>已选中 <b>{{ selectedRowKeys.length }}</b> 人</span> <span>总金额 <b>{{ TolBonusSum }}</b> 元</span>
          <span style="margin-left: 10px">奖金比例</span>
           <a-input addon-before="1：" placeholder="请输入奖金比例"
                    style="width: 120px;display: inline-block;margin-left: 15px;margin-top: -5px" v-model="proportion"
                    @change="propData"/>
        </template>
      </span>
      <a-button type="link" @click="addBonusObject" style="position: absolute;right: 0;bottom: 22px" v-if="InShow=='签署完成'">奖金发放发起
      </a-button>
    </a-modal>
    <ClassDetails ref="ClassDetails"></ClassDetails>
    <Bonus_Confirm ref="Bonus_Confirm"></Bonus_Confirm>
  </div>
</template>
<script>
import DistData from "../moble/DistData";
import ClassDetails from './ClassDetails.vue'
import Bonus_Confirm from './Bonus_Confirm.vue'
import {findContData} from "@/api/Admin/ObjectDetails/Distribution";
import notification from "ant-design-vue/lib/notification";

let {columns,columns_1} = DistData
export default {
  data() {
    return {
      columns,
      columns_1,
      visible: false,
      loading: false,
      data: [],
      selectedRowKeys: [],
      TolBonus: 0,
      TolBonusSum: 0,
      proportion: 1,
      ObjectID: '',
      Cont_ID: '',
      InShow:null
    };
  },
  components: {Bonus_Confirm, ClassDetails},
  methods: {
    ongetCheckboxProps(record) {
      return {
        props: {
          disabled: record.Bonus.InShow != '未发起奖金派发',
        },
      }
    },
    addBonusObject() {
      let selectedRowKeys = this.selectedRowKeys
      if (selectedRowKeys.length == 0) {
        notification.error({message: '请选择用户'});
        return
      }
      let data = this.data
      let addData = []
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.ObjectID
      let proportion = this.proportion
      let Cont_ID = this.Cont_ID

      for (let i in selectedRowKeys) {
        let k = selectedRowKeys[i]
        addData.push({
          User_ID: data[k].User_ID,
          Name: data[k].Name,
          Phone: data[k].Phone,
          TransAmount: data[k].ToNumber * proportion,
        })
      }
      this.$refs.Bonus_Confirm.open(CompanyID, ObjectID, Cont_ID, addData)
    },
    //实时比例换算结果
    propData() {
      let proportion = JSON.parse(JSON.stringify(this.proportion))
      if (Number(proportion)) {
        let TolBonus = JSON.parse(JSON.stringify(this.TolBonus))
        let sum = TolBonus * proportion
        this.TolBonusSum = sum
      } else {
        notification.error({message: '输入单位必须数字类型'});
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      let TolBonus = 0
      let data = this.data
      for (let i in selectedRowKeys) {
        let k = selectedRowKeys[i]
        let ToNumber = data[k].ToNumber
        TolBonus = Number((ToNumber + TolBonus).toFixed(2))
      }
      this.TolBonus = TolBonus
      //比例计算
      let proportion = this.proportion
      this.TolBonusSum = TolBonus * proportion
    },
    //打开
    open(Cont_ID, ObjectID,InShow) {
      this.selectedRowKeys = []
      this.proportion = 1
      this.visible = true;
      this.ObjectID = ObjectID
      this.Cont_ID = Cont_ID
      this.InShow=InShow
      this.getdata()
    },
    getdata() {
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.ObjectID
      let Cont_ID = this.Cont_ID
      this.loading = true
      this.selectedRowKeys = []
      this.TolBonusSum = 0
      findContData({CompanyID, ObjectID, Cont_ID}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    opendata(data) {
      this.$refs.ClassDetails.open(data)
    },
    handleCancel() {
      this.visible = false;
      this.data = []
      this.selectedRowKeys = []
    },
  },
};
</script>

<style lang="scss">
.table {
  height: 400px;
  margin-bottom: 20px;
}
</style>
