<template>
  <div>
    <a-modal
        title="合同详情"
        :visible="visible"
        @cancel="handleCancel"
        width="1000px"
        :footer="null"
    >
      <a-steps :current="steps" class="steps" v-if="data&&data.InShow!='已取消'">
        <a-step title="等待发起签署">
          <a-icon slot="icon" type="solution" v-if="data.InShow=='待发起'"/>
          <a-icon slot="icon" type="check" style="color: green" v-if="data.InShow=='签署中'"/>
          <a-icon slot="icon" type="check" style="color: green" v-if="data.InShow=='签署完成'"/>
        </a-step>
        <a-step title="等待签字完毕">
          <a-icon slot="icon" type="file-sync" style="color: yellowgreen" v-if="data.InShow=='待发起'"/>
          <a-icon slot="icon" type="loading" v-if="data.InShow=='签署中'"/>
          <a-icon slot="icon" type="check" style="color: green" v-if="data.InShow=='签署完成'"/>
        </a-step>
        <a-step title="完成">
          <a-icon slot="icon" type="info-circle" style="color: yellowgreen" v-if="data.InShow=='待发起'"/>
          <a-icon slot="icon" type="info-circle" style="color: yellowgreen" v-if="data.InShow=='签署中'"/>
          <a-icon slot="icon" type="check" style="color: green" v-if="data.InShow=='签署完成'"/>
        </a-step>
      </a-steps>
      <a-descriptions>
        <a-descriptions-item label="合同昵称" :span="1" v-if="data">
          {{ data.Title }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions v-if="data" :column="4">
        <a-descriptions-item label="新建合同时间" :span="1">
          {{ data.NewTime }}
        </a-descriptions-item>
        <a-descriptions-item label="状态" :span="1">
          <a-badge v-if="data.InShow=='待发起'" status="warning" text="待发起"/>
          <a-badge v-if="data.InShow=='签署中'" status="processing" text="签署中"/>
          <a-badge v-if="data.InShow=='已取消'" status="error" text="已取消"/>
          <a-badge v-if="data.InShow=='签署完成'" status="success" text="签署完成"/>
        </a-descriptions-item>
        <a-descriptions-item label="结束流程时间" :span="1">
          <span v-if="data.EndTime">{{ data.EndTime }}</span><span v-else>待定</span>
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions v-if="data" layout="vertical">
        <a-descriptions-item label="参与任务人" :span="3">
          <a-tag v-for="(item,index) in data.Task" :key="index">【{{ item.Type }}】{{ item.CompanyName }}-{{
              item.UserName
            }}{{ item.UserPhone }}<span v-if="item.Remarks">({{ item.Remarks }})</span>}<span v-if="item.Status"
                                                                                              v-bind:class="{red: item.Status=='未签',green: item.Status=='已签'}">({{
                item.Status
              }})</span></a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="抄送人" :span="3">
          <a-tag v-for="(item,index) in data.Cc" :key="index">【{{ item.Type }}】{{ item.CompanyName }}-{{
              item.UserName
            }}{{ item.UserPhone }}<span v-if="item.Remarks">({{ item.Remarks }})</span></a-tag>
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions v-if="data" :column="2">
        <a-descriptions-item label="签署文件" :span="1" xxx>
          <span @click="openTaskId(data.TaskId)" class="open">{{ data.TaskId }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="附件" :span="1">
          <span @click="openTaskId(data.CcId)" class="open">{{ data.CcId }}</span>
        </a-descriptions-item>
      </a-descriptions>

      <div class="operation" v-if="data">
        <a-button icon="reload" class="button" v-if="data.InShow=='签署中'" @click="getdata">
          刷新
        </a-button>
        <a-button icon="to-top" type="primary" class="button" v-if="data.InShow=='待发起'" @click="AddContract">
          合同发起
        </a-button>
        <a-button icon="check" type="primary" class="button" v-if="data.InShow=='签署中'" @click="UpContract">
          合同归档
        </a-button>
        <a-button icon="close" type="danger" class="button" v-if="data.InShow=='待发起'||data.InShow=='签署中'"
                  @click="StopContract">
          废止合同
        </a-button>
        <a-button icon="eye" class="button" v-if="data.InShow=='签署完成'"
                  @click="openContract">
          查看签署文件
        </a-button>
      </div>

    </a-modal>
    <div class="loading" v-if="loading">
      <p>
        <a-spin/>
      </p>
      <p>处理中...</p>
    </div>
  </div>
</template>

<script>
import {findid, LookFileId, LookFlowId, startContract, stopContract, upContract} from "@/api/Contract/List";

export default {
  data() {
    return {
      loading: false,
      contract_id: 1,
      visible: false,
      steps: 0,
      data: null
    }
  },

  methods: {
    openContract() {
      let data = this.data
      let FlowId = data.FlowId

      LookFlowId({FlowId}).then(res => {
        if (res.errorCode == 200) {
          window.open(res.payload)
        } else {
          this.$message.error(res.errorMsg);
        }

      })

    },
    UpContract() {
      let that = this
      let id = this.contract_id
      this.$confirm({
        title: '是否确认归档该合同吗?',
        onOk() {
          upContract({id}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    },
    //终止并废止合同
    StopContract() {
      let that = this
      let id = this.contract_id
      this.$confirm({
        title: '是否确认终止该合同吗?',
        onOk() {
          stopContract({id}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    },
    //确认发起合同
    AddContract() {
      let that = this
      let id = this.contract_id
      this.$confirm({
        title: '是否确认发起该合同吗?',
        onOk() {
          that.loading = true
          startContract({id}).then(res => {
            that.loading = false
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    },
    //查看签署文件
    openTaskId(FileId) {
      if (this.data.InShow == '签署完成') {
        this.$message.error('请点击下方查看签署文件');
        return;
      }
      this.loading = true
      LookFileId({FileId}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload
          this.$message.success('获取成功');
          window.open(data)
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    open(id) {
      this.visible = true
      this.contract_id = id
      this.getdata()
    },
    getdata() {
      this.loading = true
      let id = this.contract_id
      findid({id}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload
          this.stepsClass(data.InShow)
          let EndTime = data.EndTime
          if (EndTime){
            data.EndTime = this.A_USA_TIME_COM(EndTime)
          }

          this.data = data;
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    handleCancel() {
      this.visible = false;
      this.$parent.getData()
    },
    stepsClass(value) {
      if (value == '待发起') {
        this.steps = 0
      } else if (value == '签署中') {
        this.steps = 1
      } else if (value == '签署完成') {
        this.steps = 2
      }
    },
    A_USA_TIME_COM(days) {
      var date = new Date(days);
      var year = date.getFullYear();  //年
      var month = date.getMonth() + 1;  //月份
      var day = date.getDate();    //天
      var hour = date.getHours();  //小时
      var minute = date.getMinutes();  //分钟
      var second = date.getSeconds();  //秒数
      return year + '-' + (String(month).length > 1 ? month : '0' + month) + '-' +
          (String(day).length > 1 ? day : '0' + day) + ' ' + (String(hour).length > 1 ? hour : '0' + hour) + ':' + (String(minute).length > 1 ? minute : '0' + minute)
          + ':' + (String(second).length > 1 ? second : '0' + second)
    }
  }
}
</script>

<style scoped lang="scss">
.steps {
  margin-top: 20px;
  margin-bottom: 40px;
}

.open {
  color: #02a7f0;
  text-indent: initial;
  cursor: pointer;
}

.operation {
  margin-top: 40px;
}

.button {
  margin-right: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  p {
    margin-right: 10px;
    height: 50px;
    line-height: 50px;
    color: #f3f3f3;
  }
}

.red {
  color: red;
  font-weight: bold;
}

.green {
  color: green;
  font-weight: bold;
}
</style>