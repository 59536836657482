<template>
<div class="ObjectDetails">
  <Main></Main>
  <Nav :TClass="TClass"></Nav>
  <Introduction v-if="TClass=='概况信息'"></Introduction>
  <Report v-if="TClass=='项目报道'"></Report>
  <Distribution v-if="TClass=='贡献值派发记录'" ref="Distribution"></Distribution>
  <Bonus v-if="TClass=='奖金派发列表'" ref="Distribution"></Bonus>
  <Contract v-if="TClass=='合同列表'"></Contract>
  <Committee v-if="TClass=='项目委员会'" ref="Committee"></Committee>
  <Message v-if="TClass=='留言反馈'"></Message>
  <Log v-if="TClass=='操作记录'"></Log>
  <Vote v-if="TClass=='投票管理'"></Vote>
</div>
</template>

<script>
import Main from '@/pages/Admin/pages/ObjectDetails/Com/Main/index.vue'
import Nav from '@/pages/Admin/pages/ObjectDetails/Com/Nav.vue'
import Introduction from '@/pages/Admin/pages/ObjectDetails/Com/Introduction/index.vue'
import Report from '@/pages/Admin/pages/ObjectDetails/Com/Report/inedx.vue'
import Distribution from '@/pages/Admin/pages/ObjectDetails/Com/Distribution/index.vue'
import Contract from '@/pages/Admin/pages/ObjectDetails/Com/Contract/index.vue'
import Committee from '@/pages/Admin/pages/ObjectDetails/Com/Committee/index.vue'
import Message from  '@/pages/Admin/pages/ObjectDetails/Com/Message/index.vue'
import Log from  '@/pages/Admin/pages/ObjectDetails/Com/Log/index.vue'
import Bonus from '@/pages/Admin/pages/ObjectDetails/Com/Bonus/index.vue'
import Vote from '@/pages/Admin/pages/ObjectDetails/Com/Vote/index.vue'
 export default {
  data(){
    return{
      TClass:'概况信息'
    }
  },
   components:{Main,Nav,Introduction,Report,Distribution,Contract,Committee,Message,Log,Bonus,Vote},
   methods: {
     switchClass(value) {
       this.TClass = value
     },
     UpCommittee(){
       this.$refs.Committee.getdata()
     },
     UpDistribution(){
       this.$refs.Distribution.getdata()
     }
   }
 }
</script>

<style scoped lang="scss">


</style>