var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"发起项目","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"项目标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Title',
                          {  rules: [{ required: true, message: '请输入项目标题' }] }
           ]),expression:"[\n                          'Title',\n                          {  rules: [{ required: true, message: '请输入项目标题' }] }\n           ]"}],attrs:{"placeholder":"请输入项目标题"}})],1),_c('a-form-item',{attrs:{"label":"项目目标及简介"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Brief',
                          {  rules: [{ required: true, message: '请输入项目目标及简介' }] }
           ]),expression:"[\n                          'Brief',\n                          {  rules: [{ required: true, message: '请输入项目目标及简介' }] }\n           ]"}],attrs:{"placeholder":"请输入项目目标及简介"}})],1),_c('a-form-item',{attrs:{"label":"共同发起人"}},[_c('div',[_c('a-button',{staticClass:"button",on:{"click":_vm.openAddUser}},[_vm._v("添加共同发起人")])],1),_vm._l((_vm.User),function(item,index){return _c('a-tag',{key:index},[_vm._v(_vm._s(item.Name)+"-"+_vm._s(item.Phone)),(item.Remarks)?_c('span',[_vm._v("("+_vm._s(item.Remarks)+")")]):_vm._e()])})],2),_c('a-form-item',{attrs:{"label":"委员会数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'CommitteeNumber',
                          {  rules: [{ required: true, message: '请输入委员会数量' }] }
           ]),expression:"[\n                          'CommitteeNumber',\n                          {  rules: [{ required: true, message: '请输入委员会数量' }] }\n           ]"}],attrs:{"min":1,"max":1000}})],1),_c('a-form-item',{attrs:{"label":"贡献值额度"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'ToNumber',
                          {  rules: [{ required: true, message: '请输入贡献值额度' }] }
           ]),expression:"[\n                          'ToNumber',\n                          {  rules: [{ required: true, message: '请输入贡献值额度' }] }\n           ]"}],attrs:{"min":1,"max":1000000000}}),_vm._v(" QB ")],1),_c('a-form-item',{attrs:{"label":"Logo"}},[_c('a-upload',{attrs:{"name":"file","multiple":false,"action":_vm.ImageUrl,"headers":{
                'X-Requested-With':null
            }},on:{"change":_vm.AddImage}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传图片 ")],1)],1)],1)],1)],1),_c('AddUser',{ref:"AddUser"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }