<template>
  <div class="Distribution">
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             :rowKey="(record, index) => { return index }"
             class="table"
             :loading="loading">
            <span slot="Operate" slot-scope="text,record">
                <a-button type="link" html-type="submit" @click="GoToDetails(record)">编辑</a-button>
                <a-button type="link" html-type="submit" class="red" @click="Deleteset(record.id)">删除</a-button>
            </span>
    </a-table>
    <edit ref="edit"></edit>
  </div>
</template>

<script>
import indexTest from "@/pages/Admin/pages/ObjectDetails/Com/Committee/moble/indexTest";
import {deleteset, find} from "@/api/Admin/ObjectDetails/Committee";
import edit from './com/edit.vue'

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  components: {edit},
  mounted() {
    this.getdata()
  },
  methods: {
    Deleteset(id){
      let that = this
      this.$confirm({
        title: '是否删除该成员吗?',
        onOk() {
          deleteset({id}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    GoToDetails(value) {
      this.$refs.edit.open(value)
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.loading = true

      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.$route.query.Id
      let Current = this.pagination.current
      find({CompanyID, ObjectID, Current}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          let Total = res.payload.Total

          for (let i in data) {
            let time = data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }

          this.pagination.total = Total
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">

.Distribution {
  width: 100%;
  height: 600px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 10px;
  }

  .table {
    width: 98%;
    margin: 0 auto;
  }
}

.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}
</style>
