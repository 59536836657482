import request from '../utils/request';

//验证账户并登录
export const verify = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Login/verify',
        method: 'post',
        data: query
    });
};

//修改账户密码
export const ModifyPow = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Login/ModifyPow',
        method: 'post',
        data: query
    });
};

//企业申请
export const ApplyCompany = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Login/ApplyCompany',
        method: 'post',
        data: query
    });
};


