import request from '../../../utils/request';

//查询概况信息
export const findInfo = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Introduction/find/Info',
        method: 'get',
        params: query
    });
};


//更新概况信息
export const briefUp = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Introduction/up/brief',
        method: 'post',
        data: query
    });
};

//更新标语
export const sloganUp = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Introduction/up/slogan',
        method: 'post',
        data: query
    });
};

//更新网站
export const websiteUrlUp = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Introduction/up/websiteUrl',
        method: 'post',
        data: query
    });
};

//添加发展历程
export const addHistory = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Introduction/add/History',
        method: 'post',
        data: query
    });
};

//添加发展历程
export const editHistory = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Introduction/edit/History',
        method: 'post',
        data: query
    });
};

//删除发展日程
export const deleteHistory = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Introduction/delete/History',
        method: 'delete',
        data: query
    });
};