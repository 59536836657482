<template>
  <div class="Object">
    <div class="topBlock"></div>
    <div class="topNav">
      <a-button class="search-btn" @click="openListAdd">申请管理</a-button>
    </div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             class="table"
             :rowKey="(record, index) => { return index }"
             :loading="loading">
        <span slot="LogoImage" slot-scope="tags,record">
        <a-avatar :size="30" icon="user" shape="square" :src="record.LogoImage"/>
       </span>
      <span slot="Operate" slot-scope="text,record">
        <a-button type="link" @click="GoToDetails(record.id)">管理</a-button>
      </span>
    </a-table>
    <list ref="list"></list>
  </div>
</template>

<script>
import indexTest from "@/pages/Admin/pages/Object/moble/indexTest";
import list from './com/list.vue'
import {fetchData} from "@/api/Admin/Object";

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  components: {list},
  mounted() {
    this.getdata()
  },
  methods: {
    openListAdd() {
      this.$refs.list.open()
    },
    GoToDetails(value) {
      this.$router.push(`/Admin/Object/Details?Id=${value}`)
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
    },
    getdata() {
      this.loading = true
      let Current=this.pagination.current
      let CompanyID = this.$store.state.user.info.CompanyID


      fetchData({Current,CompanyID}).then(res => {
        this.loading = false
        let data = res.payload

        for (let i in data) {
          let time = data[i].Time
          let day = new Date(time);
          data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
        }

        this.data = data
      })
    },
    getSumRegexData(data) {
      this.loading = false
      this.data = data
      this.pagination.current = 1
      this.pagination.total = 0
    },
    getRegexData() {
      this.loading = true
    },
    resetting() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.getdata()
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }

  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }

  .table {
    width: 98%;
    margin: 0 auto;
  }
}


.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}
</style>
