var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"企业申请","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"注册手机"}},[_c('a-input-search',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Phone',
                          {  rules: [{required: true,message: '请输入注册手机' }] }
           ]),expression:"[\n                          'Phone',\n                          {  rules: [{required: true,message: '请输入注册手机' }] }\n           ]"}],attrs:{"loading":_vm.loadingCode,"placeholder":"请输入注册手机","enter-button":_vm.StartText},on:{"search":_vm.setCode}})],1),_c('a-form-item',{attrs:{"label":"手机验证码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Code',
                          {  rules: [{required: true,message: '请填写获取的手机验证码' }] }
           ]),expression:"[\n                          'Code',\n                          {  rules: [{required: true,message: '请填写获取的手机验证码' }] }\n           ]"}],attrs:{"placeholder":"请填写获取的手机验证码"}})],1),_c('a-form-item',{attrs:{"label":"管理员姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'User',
                          {  rules: [{required: true,message: '请填写管理员姓名' }] }
           ]),expression:"[\n                          'User',\n                          {  rules: [{required: true,message: '请填写管理员姓名' }] }\n           ]"}],attrs:{"placeholder":"管理员姓名"}})],1),_c('a-form-item',{attrs:{"label":"企业logo"}},[_c('a-upload',{attrs:{"headers":{
                'X-Requested-With':null
            },"name":"file","list-type":"picture-card","show-upload-list":false,"action":_vm.ImageUrl},on:{"change":_vm.AddImage}},[(_vm.LogoImage)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.LogoImage,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上传头像 ")])],1)])],1),_c('a-form-item',{attrs:{"label":"企业昵称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'CompanyName',
                          {  rules: [{required: true,message: '请填写企业昵称' }] }
           ]),expression:"[\n                          'CompanyName',\n                          {  rules: [{required: true,message: '请填写企业昵称' }] }\n           ]"}],attrs:{"placeholder":"企业昵称"}})],1),_c('a-form-item',{attrs:{"label":"法定代表人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'UserName',
                          {  rules: [{required: true,message: '请填写法定代表人' }] }
           ]),expression:"[\n                          'UserName',\n                          {  rules: [{required: true,message: '请填写法定代表人' }] }\n           ]"}],attrs:{"placeholder":"请填写法定代表人"}})],1),_c('a-form-item',{attrs:{"label":"法定代表人联系方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'UserPhone',
                          {  rules: [{required: true,message: '请填写法定代表人联系方式' }] }
           ]),expression:"[\n                          'UserPhone',\n                          {  rules: [{required: true,message: '请填写法定代表人联系方式' }] }\n           ]"}],attrs:{"placeholder":"请填写法定代表人联系方式"}})],1),_c('a-form-item',{attrs:{"label":"社会信用代码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'ThirdPartyUserId',
                          {  rules: [{required: true,message: '请填写社会信用代码' }] }
           ]),expression:"[\n                          'ThirdPartyUserId',\n                          {  rules: [{required: true,message: '请填写社会信用代码' }] }\n           ]"}],attrs:{"placeholder":"请填写社会信用代码"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }