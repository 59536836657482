import request from '../../../utils/request';

//查询
export const find = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Vote/find',
        method: 'get',
        params: query
    });
};

//添加&编辑
export const add = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Vote/add',
        method: 'post',
        data: query
    });
};

//删除
export const deleteset = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Vote/delete',
        method: 'delete',
        data: query
    });
};

