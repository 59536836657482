import request from '../../utils/request';

export const getAllCommodityName = () => {
    return request({
        url: process.env.VUE_APP_URL + `Website/record/getAllCommodityName`,
        method: 'get'
    });
};

//查询已发货
export const getExchangeRecordList = (data) => {
    return request({
        url: process.env.VUE_APP_URL + `Website/record/getExchangeRecordList`,
        method: 'get',
        params:data
    });
};

export const getExchangeRecordListByCommodityId = (commodityId, page, size, type) => {
    return request({
        url: process.env.VUE_APP_URL + `Website/record/getExchangeRecordListByCommodityId/commodityId/${commodityId}/size/${size}/page/${page}/type/${type}`,
        method: 'get'
    });
};

export const updateExpressInfo = (recordId, data) => {
    return request({
        url: process.env.VUE_APP_URL + `Website/record/updateExpressInfo/recordId/${recordId}`,
        method: 'put',
        data: data
    });
}

export const cancelExpressById = (recordId) => {
    return request({
        url: process.env.VUE_APP_URL + `Website/record/cancelExpress/recordId/${recordId}`,
        method: 'delete'
    });
}