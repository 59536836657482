import request from '../../utils/request';

// 获取商品列表
export const getCommodityList = (page, size,CompanyID) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Commodities/List/getCommodityList/size/${size}/page/${page}/CompanyID/${CompanyID}`,
        method: 'get'
    });
};
//新增商品
export const addCommodity = (data) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Commodities/List/addCommodity`,
        method: 'post',
        data: data
    });
}
//添加盲盒商品
export const addCommodityBox = (data) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Commodities/List/addCommodityBox`,
        method: 'post',
        data: data
    });
}

//编辑
export const editCommodity = (data, id) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Commodities/List/editCommodity/commodityId/${id}`,
        method: 'put',
        data: data
    });
}

//删除商品
export const deleteCommodity = (id) => {
    return request({
        url: process.env.VUE_APP_URL + `Admin/Commodities/List/deleteCommodity/commodityId/${id}`,
        method: 'delete'
    });
}