<template>
  <a-modal v-model="visible" title="奖金发放确认单" @ok="AddBonusCie">
    <p>当前奖金比例 <b>1：{{ proportion }}</b></p>
    <p>总金额 <b>{{ tolNumber }}</b> 元</p>
    <p v-for="(item,index) in addData" :key="index">{{ item.Name }}——{{ item.Phone }}——{{ item.TransAmount }}元</p>
  </a-modal>
</template>

<script>
import {BonusAdd} from "@/api/Admin/ObjectDetails/Distribution";

export default {
  data() {
    return {
      visible: false,
      addData: [],
      proportion: 1,
      CompanyID: 0,
      ObjectID: 0,
      tolNumber: 0
    }
  },
  methods: {
    open(CompanyID, ObjectID, Cont_ID, addData) {
      this.visible = true
      this.CompanyID = CompanyID
      this.ObjectID = ObjectID
      this.Cont_ID = Cont_ID
      this.addData = addData

      let TolBonus = 0

      for (let i in addData) {
        let TransAmount = addData[i].TransAmount
        TolBonus = Number((TransAmount + TolBonus).toFixed(2))
      }

      this.tolNumber = TolBonus
    },
    //确认提交奖金发放请求
    AddBonusCie() {
      let that = this
      let CompanyID = this.CompanyID
      let ObjectID = this.ObjectID
      let Cont_ID = this.Cont_ID
      let Data = this.addData
      this.$confirm({
        title: '是否确认提交奖金发放派单?',
        onOk() {
          BonusAdd({CompanyID, ObjectID, Cont_ID, Data}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.close()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    },
    close() {
      this.visible = false
      this.$parent.getdata()
    }
  }
}
</script>

<style scoped lang="scss">

</style>