<template>
    <div class="content-header">
        <span class="green-bar"></span>
        {{ title }}
    </div>
</template>

<script>
export default {
    name: "ContentHeader",
    props: {
        title: {
            type: String
        }
    },
    data() {
        return {
            
        }
    },

}
</script>

<style scoped>
    .content-header {
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: grey;
        padding: 0 20px;
    }

    .green-bar {
        width: 4px;
        height: 14px;
        background-color: #58b99d;
        margin-right: 5px;
    }
</style>