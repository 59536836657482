<template>
  <div>
    <a-modal
        title="自提点管理"
        :visible="visible"
        :footer="null"
        :width="1000"
        @cancel="handleCancel"
    >
      <a-button type="primary" class="plus" @click="openadd" icon="plus">
        添加地址
      </a-button>
      <a-table :columns="columns" :data-source="data"
               @change="tablePaginationChange"
               class="table"
               :rowKey="(record, index) => { return index }"
               :loading="loading">


      </a-table>
    </a-modal>
  <SelfBox_Add ref="SelfBox_Add"></SelfBox_Add>
  </div>
</template>
<script>
import SelfBox_Add from './SelfBox_Add.vue'
import indexTest from '../moble/index'
let {SelfBox} = indexTest

export default {

  data() {
    return {
      visible: false,
      loading: false,
      columns:SelfBox,
      data:[]
    };
  },

  methods: {
    openadd(){
      this.$refs.SelfBox_Add.open()
    },
    getData(){

    },

    open() {
      this.visible = true;
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getData();
    },
    handleCancel(){
      this.visible = false;
    }
  },
  components:{SelfBox_Add}
};
</script>

<style lang="scss">
.plus{
  margin-bottom: 15px;
}

.table{
  width: 100%;
  height: 600px;
}
</style>