var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"添加项目报道","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Title',
                          {  rules: [{ required: true, message: '请输入标题' }] }
           ]),expression:"[\n                          'Title',\n                          {  rules: [{ required: true, message: '请输入标题' }] }\n           ]"}],attrs:{"placeholder":"请输入标题"}})],1),_c('a-form-item',{attrs:{"label":"文本"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Subtitle',
                          {  rules: [{ required: true, message: '请输入文本' }] }
           ]),expression:"[\n                          'Subtitle',\n                          {  rules: [{ required: true, message: '请输入文本' }] }\n           ]"}],attrs:{"placeholder":"请输入文本"}})],1),_c('a-form-item',{attrs:{"label":"链接地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'ToUrl',
                          {  rules: [{ required: true, message: '请输入链接地址' }] }
           ]),expression:"[\n                          'ToUrl',\n                          {  rules: [{ required: true, message: '请输入链接地址' }] }\n           ]"}],attrs:{"placeholder":"请输入链接地址"}})],1),_c('a-form-item',{attrs:{"label":"封面图"}},[_c('a-upload',{attrs:{"name":"file","multiple":false,"action":_vm.ImageUrl,"headers":{
                'X-Requested-With':null
            }},on:{"change":_vm.AddImage}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传图片 ")],1)],1)],1),_c('a-form-item',{attrs:{"label":"日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Time',
                          {  rules: [{ required: true, message: '请选择日期' }] }
           ]),expression:"[\n                          'Time',\n                          {  rules: [{ required: true, message: '请选择日期' }] }\n           ]"}]})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }