<template>
  <div>
    <a-modal
        title="添加委员会"
        :visible="visible"
        :footer="null"
        @cancel="handleCancel"
        :width="700"
    >
      <a-table :columns="columns" :data-source="data" :pagination="false"
               :rowKey="(record, index) => { return index }"
               class="table"
               :loading="loading">
        <span slot="Operate" slot-scope="tags,record">
             <a-button type="link" @click="add(record)">选择</a-button>
        </span>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import DistData from "../moble/addCommittee";
import {FindCommittee} from "@/api/Admin/ObjectDetails/Distribution";

let {columns} = DistData
export default {
  data() {
    return {
      columns,
      visible: false,
      visible_1: false,
      loading: false,
      data: [],
    };
  },
  methods: {
    add(value){
      let Name=value.Name
      let Phone=value.Phone
      let Class=value.Class
      this.$parent.addComUser(Name,Phone,Class)
      this.handleCancel()
    },
    //打开
    open(ObjectID) {
      this.visible = true;
      let CompanyID = this.$store.state.user.info.CompanyID

      FindCommittee({CompanyID, ObjectID}).then(res => {
        if (res.errorCode == 200) {
          let data = res.payload
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    handleCancel() {
      this.visible = false;
      this.data = []
    },
  },
};
</script>
