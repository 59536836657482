import request from '../../../utils/request';

//查询贡献值派发记录
export const find = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Distribution/find',
        method: 'get',
        params: query
    });
};

//查询贡献值详情（用户）
export const findContData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Distribution/find/ContData',
        method: 'get',
        params: query
    });
};

//查询项目社区对象
export const FindObject = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Distribution/find/Object',
        method: 'get',
        params: query
    });
};

//查询委员会
export const FindCommittee = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Distribution/find/Committee',
        method: 'get',
        params: query
    });
};

//生成文件
export const generateFile = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Distribution/generateFile',
        method: 'post',
        data: query
    });
};

//发起签署
export const initiateObject = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Distribution/initiateObject',
        method: 'post',
        data: query
    });
};

//查看文件内容
export const lookFileId = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Distribution/lookFileId',
        method: 'get',
        params: query
    });
};

//签署用户状态
export const ConfirmUserList = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Distribution/ConfirmUserList',
        method: 'post',
        data: query
    });
};

//确认签署并更新状态
export const Confirm = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Distribution/Confirm',
        method: 'post',
        data: query
    });
};

//终止签署流程
export const ExitConfirm = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Distribution/ConfirmExit',
        method: 'post',
        data: query
    });
};

//新增奖金发放派单
export const BonusAdd = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Distribution/Bonus/Add',
        method: 'post',
        data: query
    });
};



