<template>
  <div>
    <a-modal
        title="选择机构"
        :visible="visible"
        :footer="null"
        @cancel="handleCancel"
        width="1000px"
    >
      <a-table :columns="columns" :data-source="data" :pagination="false"
               :rowKey="(record, index) => { return index }"
               class="table"
               :loading="loading">
          <span slot="Operation" slot-scope="tags,record">
            <a-button @click="ok(record)">选择</a-button>
          </span>
      </a-table>
    </a-modal>
  </div>
</template>
<script>


import {findCompany} from "@/api/Contract/List";

let columns = [
  {
    title: '机构名称',
    dataIndex: 'CompanyName',
    key: 'CompanyName',
  },
  {
    title: '社会信用代码',
    key: 'ThirdPartyUserId',
    dataIndex: 'ThirdPartyUserId',
  },
  {
    title: '负责人',
    key: 'UserName',
    dataIndex: 'UserName',
  },
  {
    title: '手机号码',
    key: 'UserPhone',
    dataIndex: 'UserPhone',
  },
  {
    title: '操作',
    key: 'Operation',
    dataIndex: 'Operation',
    scopedSlots: {customRender: 'Operation'},
  }
]
export default {
  props: {
    InShow: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      columns,
      visible: false,
      loading: false,
      data: [],
    };
  },
  methods: {
    ok(value) {
      if (this.InShow == '参与') {
        this.$parent.addCompany_Task(value)
      } else {
        this.$parent.addCompany_Cc(value)
      }

      this.$message.success('添加企业成功');
      this.handleCancel()
    },
    //打开
    open() {
      this.visible = true;
      this.getdata()
    },
    getdata() {
      // let id = this.id
      this.loading = true
      // let CompanyID = this.$store.state.user.info.CompanyID
      findCompany().then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload
          for (let i in data) {
            let time = data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    handleCancel() {
      this.visible = false;
      this.data = []
    },
  },
};
</script>

<style lang="scss">
.table {
  width: 100%;
  height: 600px;
  overflow-y: auto;
}
</style>
