<template>
  <div>
    <a-modal
        title="发起项目"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="项目标题">
          <a-input
              placeholder="请输入项目标题"
              v-decorator="[
                            'Title',
                            {  rules: [{ required: true, message: '请输入项目标题' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="项目目标及简介">
          <a-input
              placeholder="请输入项目目标及简介"
              v-decorator="[
                            'Brief',
                            {  rules: [{ required: true, message: '请输入项目目标及简介' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="共同发起人">
          <div>
            <a-button @click="openAddUser" class="button">添加共同发起人</a-button>
          </div>
          <a-tag v-for="(item,index) in User" :key="index">{{ item.Name }}-{{ item.Phone }}<span v-if="item.Remarks">({{item.Remarks}})</span></a-tag>
        </a-form-item>

        <a-form-item label="委员会数量">
          <a-input-number
              v-decorator="[
                            'CommitteeNumber',
                            {  rules: [{ required: true, message: '请输入委员会数量' }] }
             ]"
              :min="1" :max="1000"/>
        </a-form-item>

        <a-form-item label="贡献值额度">
          <a-input-number
              v-decorator="[
                            'ToNumber',
                            {  rules: [{ required: true, message: '请输入贡献值额度' }] }
             ]"
              :min="1" :max="1000000000"/>
          QB
        </a-form-item>
        <a-form-item label="Logo">
          <a-upload
              name="file"
              :multiple="false"
              @change="AddImage"
              :action="ImageUrl"
              :headers="{
                  'X-Requested-With':null
              }"
          >
            <a-button>
              <a-icon type="upload"/>
              上传图片
            </a-button>
          </a-upload>
        </a-form-item>
      </a-form>
    </a-modal>
    <AddUser ref="AddUser"></AddUser>
  </div>
</template>
<script>
import AddUser from './moble/AddUser.vue'
import {addObjectApply} from "@/api/Admin/Object";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      User: [],
      ImageUrl: process.env.VUE_APP_URL + 'Enterprise/Company/image',
    };
  },
  components: {AddUser},
  methods: {
    AddImage(info) {
      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          let url = response.payload
          this.LogoImg = url
        }
      }
    },
    openAddUser() {
      this.$refs.AddUser.open()
    },
    AddUser(Name, Phone,Remarks) {
      let data = {Name, Phone,Remarks}
      this.User.push(data)
    },
    //打开
    open() {
      this.visible = true;
      this.User = []
      this.form.resetFields()
    },
      handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (this.User.length == 0) {
            this.$message.error('共同发起人未添加');
            return
          }
          if (!this.LogoImg) {
            this.$message.error('未添加logo图片');
            return
          }
          let PinUser = this.User
          let Title = values.Title
          let ToNumber = values.ToNumber
          let Brief = values.Brief
          let LogoImage = this.LogoImg
          let CompanyID = this.$store.state.user.info.CompanyID
          let UserID = this.$store.state.user.info.UserID
          let CommitteeNumber = values.CommitteeNumber

          addObjectApply({PinUser, Title, Brief, ToNumber, LogoImage, CompanyID, UserID, CommitteeNumber}).then(res => {
            this.confirmLoading = false;
            if (res.errorCode == 200) {
              this.visible = false;
              this.$message.success(res.payload);
              this.$parent.getdata()
            } else {
              this.$message.error(res.errorMsg);
            }
          })

        }
      })

    },
    handleCancel() {
      this.visible = false;
      this.$parent.getdata()
    },
  },
};
</script>

<style lang="scss">
.button {
  display: block;
}
</style>