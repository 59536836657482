<template>
  <div>
    <a-modal
        title="选择企业"
        :visible="visible"
        @cancel="handleCancel"
        :footer="null"
        :width="800"
    >
      <a-table :columns="columns" :data-source="data" :pagination="false"
               :rowKey="(record, index) => { return index }"
               class="table"

      >
        <span slot="CompanyLogo" slot-scope="tags">
            <a-avatar :size="30" icon="user" shape="square" :src="tags"/>
        </span>
        <span slot="Operate" slot-scope="tags,text">
             <a-button type="link" html-type="submit" @click="enter(text)">选择该企业</a-button>
        </span>
      </a-table>
    </a-modal>
  </div>
</template>
<script>


export default {
  data() {
    return {
      visible: false,
      data: [],
      columns: [
        {
          title: 'Logo',
          key: 'CompanyLogo',
          dataIndex: 'CompanyLogo',
          scopedSlots: {customRender: 'CompanyLogo'},
        },
        {
          title: '企业昵称',
          key: 'CompanyName',
          dataIndex: 'CompanyName',
        },
        {
          title: '操作',
          key: 'Operate',
          dataIndex: 'Operate',
          scopedSlots: {customRender: 'Operate'},
        },
      ]
    };
  },
  methods: {
    enter(value) {
      let info = JSON.parse(JSON.stringify(value))
      this.$store.state.user.info = info
      localStorage.setItem('auth_token', info.Token);
      localStorage.setItem('info', JSON.stringify(info));

      this.$message.success('登录成功');
      setTimeout(() => {
        this.$router.push('/')
      }, 200)
    },
    //打开
    open(data) {
      this.visible = true;
      this.data = data
    },
    //取消并关闭
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
.table{
  height: 400px;
}
</style>
