<template>
  <div class="Introduction">
    <div class="title">
      <a-icon type="caret-right" class="icon"/>
      标语
      <a-button class="btn" icon="edit" @click="openEditslogan">编辑标语</a-button>
    </div>
    <div class="Slogan">{{slogan}}</div>
    <div class="title">
      <a-icon type="caret-right" class="icon"/>
      网址
      <a-button class="btn" icon="edit" @click="openEditwebsiteUrl">编辑网址</a-button>
    </div>
    <div class="Slogan">{{websiteUrl}}</div>
    <div class="title">
      <a-icon type="caret-right" class="icon"/>
      项目简介
      <a-button class="btn" icon="edit" @click="openEditText">编辑简介</a-button>
    </div>
    <div class="main" v-html="brief"></div>
    <div class="title">
      <a-icon type="caret-right" class="icon"/>
      发展历程
      <a-button class="btn" icon="plus" @click="Addhistory">添加发展历程</a-button>
    </div>
    <div class="history">
      <div class="data" v-for="(item,index) in history" :key="index">
        <div class="icon">
<!--          <a-icon type="check-circle" class="circle"/>-->
        </div>
        <div class="link"></div>
        <div class="time">{{ item.Time }}<span class="blue" @click="openEditHistory(item)">编辑</span><span class="delete" @click="deletehistory(item.id)">删除</span></div>
        <div class="information">{{ item.Text }}</div>
      </div>
    </div>
    <addhistory ref="addhistory"></addhistory>
    <EditText ref="EditText"></EditText>
    <EditSlogan ref="EditSlogan"></EditSlogan>
    <EditdWebsiteUrl ref="EditdWebsiteUrl"></EditdWebsiteUrl>
    <Edithistory ref="Edithistory"></Edithistory>
  </div>
</template>


<script>
import addhistory from './com/addhistory.vue'
import EditText from './com/EditText.vue'
import EditSlogan from './com/EditSlogan.vue'
import EditdWebsiteUrl from './com/EditdWebsiteUrl.vue'
import Edithistory from './com/Edithistory.vue'
import {briefUp, deleteHistory, findInfo, sloganUp, websiteUrlUp} from "@/api/Admin/ObjectDetails/Introduction";

export default {
  data() {
    return {
      slogan:'',
      brief: '',
      history: [],
      websiteUrl:''
    }
  },
  components: {addhistory,EditText,EditSlogan,EditdWebsiteUrl,Edithistory},
  mounted() {
    this.getdata()
  },
  methods: {
    openEditHistory(value){
      this.$refs.Edithistory.open(value)
    },
    EditWebsiteUrl(Text){
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.$route.query.Id

      websiteUrlUp({CompanyID,ObjectID,Text}).then(res=>{
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.getdata()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    EditSlogan(Text){
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.$route.query.Id

      sloganUp({CompanyID,ObjectID,Text}).then(res=>{
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.getdata()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    openEditslogan(){
      this.$refs.EditSlogan.open()
    },
    openEditwebsiteUrl(){
      this.$refs.EditdWebsiteUrl.open()
    },
    AddTextdata(Text){
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.$route.query.Id

      briefUp({CompanyID,ObjectID,Text}).then(res=>{
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.getdata()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    openEditText(){
      let brief=this.brief
      this.$refs.EditText.open(brief)
    },
    deletehistory(id) {
      let that = this
      this.$confirm({
        title: '是否删除该数据吗?',
        onOk() {
          deleteHistory({id}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    getdata() {
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.$route.query.Id

      findInfo({CompanyID, ObjectID}).then(res => {
        if (res.errorCode == 200) {
          this.history = res.payload.history
          this.brief = res.payload.brief
          this.slogan=res.payload.slogan
          this.websiteUrl=res.payload.websiteUrl
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    Addhistory() {
      this.$refs.addhistory.open()
    }
  }
}
</script>

<style scoped lang="scss">
.Introduction {
  width: 100%;
  line-height: 40px;
  background-color: #ffffff;

  .title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-indent: 0.5rem;
    position: relative;

    .icon {
      margin-right: 15px;
    }

    .btn {
      position: absolute;
      right: 35px;
      top: 20px;
    }
  }

  .Slogan{
    width: 90%;
    margin: 0 auto;
  }

  .main {
    width: 90%;
    height: 400px;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #f2f2f2;
    color: #333333;
    overflow-y: auto;
    text-indent: 20px;
    line-height: 35px;
    padding: 10px 15px;
  }

  .history {
    padding: 20px;
    width: 90%;
    margin: 0 auto;

    .data {
      width: 100%;
      height: 110px;
      font-size: 14px;
      position: relative;

      .icon {
        width: 15px;
        height: 15px;
        border-radius: 50px;
        position: relative;
        background-color: #171722;
        .circle {
          position: absolute;
        }
      }

      .link {
        width: 3px;
        height: 100%;
        background-color: #171722;
        position: absolute;
        left: 6px;
        top: 15px;
      }

      .time {
        color: #c8c8d3;
        position: absolute;
        left: 18px;
        top: 0;
        font-weight: bold;

        .delete {
          color: red;
          margin-left: 10px;
          font-size: 12px;
          cursor: pointer;
          user-select: none;
        }
        .blue{
          color: #58b99d;
          margin-left: 10px;
          font-size: 12px;
          cursor: pointer;
          user-select: none;
        }
      }

      .information {
        color: #c8c8d3;
        position: absolute;
        left: 18px;
        top: 35px;
      }
    }
  }
}
</style>