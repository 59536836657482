var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"添加机构/企业","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"机构/企业昵称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Name',
                              {  rules: [{ required: true, message: '请输入昵称' }] },
                          ]),expression:"[\n                              'Name',\n                              {  rules: [{ required: true, message: '请输入昵称' }] },\n                          ]"}],attrs:{"placeholder":"请输入昵称"}})],1),_c('a-form-item',{attrs:{"label":"法定代表人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'UserName',
                              {  rules: [{ required: true, message: '请输入法定代表人' }] },
                          ]),expression:"[\n                              'UserName',\n                              {  rules: [{ required: true, message: '请输入法定代表人' }] },\n                          ]"}],attrs:{"placeholder":"请输入法定代表人"}})],1),_c('a-form-item',{attrs:{"label":"法定代表人联系方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'UserPhone',
                              {  rules: [{ required: true, message: '请输入法定代表人联系方式' }] },
                          ]),expression:"[\n                              'UserPhone',\n                              {  rules: [{ required: true, message: '请输入法定代表人联系方式' }] },\n                          ]"}],attrs:{"placeholder":"请输入法定代表人联系方式"}})],1),_c('a-form-item',{attrs:{"label":"工商注册号/社会信用代码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'ThirdPartyUserId',
                              {  rules: [{ required: true, message: '请输入工商注册号/社会信用代码' }] },
                          ]),expression:"[\n                              'ThirdPartyUserId',\n                              {  rules: [{ required: true, message: '请输入工商注册号/社会信用代码' }] },\n                          ]"}],attrs:{"placeholder":"工商注册号/社会信用代码"}})],1),_c('a-form-item',{attrs:{"label":"标语"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'ActivityName',
                              {  rules: [{ required: true, message: '请输入标语' }] },
                          ]),expression:"[\n                              'ActivityName',\n                              {  rules: [{ required: true, message: '请输入标语' }] },\n                          ]"}],attrs:{"placeholder":"请填写标语"}})],1),_c('a-form-item',{attrs:{"label":"Logo"}},[_c('a-upload',{attrs:{"name":"file","multiple":false,"action":_vm.ImageUrl,"headers":{
                'X-Requested-With':null
            }},on:{"change":_vm.handleChange}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传图片 ")],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }