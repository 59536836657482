import request from '../../../../utils/request';

//查询成员
export const addUser = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Main/CommitteeUserAdd/add',
        method: 'post',
        data: query
    });
};

export const upUser = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Main/CommitteeUserAdd/up',
        method: 'post',
        data: query
    });
};