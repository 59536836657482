<template>
  <a-layout id="components-layout-demo-fixed-sider">
    <a-layout-sider
        :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0,width:'120px','max-width':'auto','min-width':'auto'}">
      <div class="logo">
        3QLab
      </div>
      <a-menu theme="dark" mode="inline" :selectedKeys="selectedKeys" @select="onSelect">
        <a-menu-item v-for="(item, index) in menu" :key="'main_menu_' + index"
                     style="margin-bottom:15px;width:100px;height: 40px;line-height: 20px;text-align: center">
          <a-icon :type=item.icon style="margin-right: 0"/>
          <br>
          <span class="nav-text">{{ item.title }}</span>
          <router-link :to="item.item_link"/>
        </a-menu-item>
      </a-menu>
      <div class="selectCompany" @click="open_selectCompany">
        <a-icon type="bank" style="font-size: 25px; color: #ffffff"/>
        <div class="username">切换企业</div>
      </div>
      <div class="user-info" @click="changeDrawer(true)">
        <a-icon type="user" style="font-size: 25px; color: #ffffff"/>
        <div class="username">{{ this.$store.state.user.info.Name }}</div>
      </div>
      <div class="log-out" @click="exitUser">
        <a-icon type="logout" style="font-size: 25px; color: #ffffff"/>
      </div>
      <admin-update-modal ref="admin_update_modal" @closeModal="closeModal"/>
    </a-layout-sider>
    <a-layout :style="{ marginLeft: '120px' }">
      <router-view/>
    </a-layout>
    <selectCompany ref="selectCompany" v-if="selectCompany"></selectCompany>
  </a-layout>
</template>

<script>
import AdminUpdateModal from "@/components/AdminUpdateModal.vue";
import selectCompany from '@/components/selectCompany.vue'
import {AdminCompanyRouterData, AdminRouterData, RouterData} from "@/utils/LayoutRouter";
import notification from 'ant-design-vue/es/notification'

export default {
  name: "Layout",
  components: {
    "admin-update-modal": AdminUpdateModal,
    selectCompany
  },
  data() {
    return {
      drawerVisible: false,
      loginStates: this.$store.state.user.info,
      menu: [],
      selectedKeys: [],
      selectCompany: []
    };
  },
  mounted() {
    let selectCompany = localStorage.getItem("selectCompany");
    if (selectCompany) {
      this.selectCompany = JSON.parse(selectCompany)
    }


    let info = this.$store.state.user.info
    if (info) {
      let Jurisdiction = info.Jurisdiction
      switch (Jurisdiction) {
        case "Root":
          this.menu = RouterData()
          break;
        case "企业管理员":
          this.menu = AdminCompanyRouterData()
          break;
        case "普通管理员":
          this.menu = AdminRouterData()
          break;

      }
    }

    let curMenu = this.menu;
    let curPath = window.location.pathname;
    curMenu.forEach((val, index) => {
      // 确定菜单选择的key
      if (val.item_link === '/' + curPath.split('/')[1]) {
        this.selectedKeys = ['main_menu_' + index];
      }
    })
  },
  methods: {
    open_selectCompany() {
      let selectCompany = this.selectCompany
      if (selectCompany.length == 0) {
        notification.error({message: '不存在多个企业'})
        return;
      }

      this.$refs.selectCompany.open(selectCompany)
    },
    exitUser() {
      let that = this
      this.$confirm({
        title: '是否退出该账号吗?',
        onOk() {
          localStorage.removeItem("info");
          localStorage.removeItem("selectCompany");
          localStorage.removeItem("auth_token");
          that.$store.state.user.info = undefined
          that.$message.success('退出成功');
          that.$router.push('/Login')
        },
      });
    },
    changeDrawer(val) {
      this.drawerVisible = val;
    },
    onDrawerClose() {
      this.drawerVisible = false;
    },
    openModal() {
      this.drawerVisible = false
      this.$refs.admin_update_modal.open()
    },
    closeModal() {

    },
    onSelect(e) {
      this.selectedKeys = [e.key];
    }
  },

}
</script>

<style scoped>
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  color: #ffffff;
}

.username {
  color: #ffffff;
}

.selectCompany {
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.selectCompany:hover {
  background: #58b99d;
  cursor: pointer;
}

.user-info {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.user-info:hover {
  background: #58b99d;
  cursor: pointer;
}

.log-out {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  border-top: 1px solid #666666;
  padding: 20px;
  width: 100%;
  text-align: center;
  height: 70px;
}

.log-out:hover {
  background: #58b99d;
  cursor: pointer;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.drawer-row {
  font-size: 12px;
  color: #999999;
}

.user-setup:hover {
  cursor: pointer;
}
</style>