<template>
  <div class="Report">
    <div class="title">
      <a-icon type="caret-right" class="icon"/>
      项目报道
      <a-button class="btn" icon="edit" @click="openAdd">添加项目报道</a-button>
    </div>
    <div class="main">
      <a-spin :spinning="loading">
        <div class="data" v-for="(item,index) in data" :key="index">
          <div class="img">
            <img :src="item.ImageUrl" alt=""/>
          </div>
          <div class="titleX">{{ item.Title }}</div>
          <div class="subtitle" :title="item.Subtitles">{{ item.Subtitle }}</div>
          <div class="time">{{ item.Time }}</div>
          <div class="toUrl">
            <a :href="item.ToUrl" target="_blank">{{ item.ToUrl }}</a>
          </div>
          <div class="number">
            <a-icon type="user" class="user"/>
            <span>浏览</span>{{ item.Pv }}
          </div>
          <div class="edit" @click="EditData(item)">编辑</div>
          <div class="delete" @click="deleteData(item.id)">删除</div>
        </div>
        <a-pagination v-model="pagination.current" :total="pagination.total" show-less-items class="pagination"
                      @change="paginPage"/>
      </a-spin>

    </div>
    <add ref="add"></add>
    <edit ref="edit"></edit>
  </div>
</template>

<script>
import add from './com/add.vue'
import edit from './com/edit.vue'
import {deleteset, find} from "@/api/Admin/ObjectDetails/Report";

export default {
  data() {
    return {
      data: [],
      loading:false,
      pagination: {
        current: 1,
        total: 0
      }

    }
  },
  mounted() {
    this.getdata()
  },
  components: {add,edit},
  methods: {
    EditData(item){
      this.$refs.edit.open(item)
    },
    deleteData(id) {
      let that = this
      this.$confirm({
        title: '是否删除该数据吗?',
        onOk() {
          deleteset({id}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    paginPage(value) {
      this.pagination.current = value
      this.getdata()
    },
    getdata() {
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.$route.query.Id
      let Current = this.pagination.current

      this.loading = true

      find({CompanyID, ObjectID, Current}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          let Total = res.payload.Total

          for (let i in data) {
            let time = data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()

            let Subtitle = data[i].Subtitle
            data[i].Subtitles = Subtitle

            if (Subtitle.length >= 30) {
              Subtitle = Subtitle.slice(0, 30) + '...'
              data[i].Subtitle = Subtitle
            }
          }

          this.pagination.total = Total
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    openAdd() {
      this.$refs.add.open()
    }
  }
}
</script>

<style scoped lang="scss">
.Report {
  width: 100%;
  line-height: 40px;
  background-color: #ffffff;

  .title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-indent: 0.5rem;
    position: relative;

    .icon {
      margin-right: 15px;
    }

    .btn {
      position: absolute;
      right: 35px;
      top: 20px;
    }
  }

  .main {
    width: 100%;
    height: 600px;
    overflow-y: auto;

    .data {
      width: 90%;
      height: 90px;
      border-bottom: 1px solid #f2f2f2;
      margin: 0 auto;
      position: relative;

      .img {
        background-color: #d7d7d7;
        width: 70px;
        height: 70px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .titleX {
        color: #666666;
        position: absolute;
        left: 100px;
        top: 0;
        font-weight: bold;
      }

      .time {
        color: #666666;
        position: absolute;
        left: 100px;
        top: 55px;
        font-size: 13px;
      }

      .subtitle {
        color: #999999;
        position: absolute;
        left: 100px;
        top: 30px;
      }

      .toUrl {
        color: #666666;
        position: absolute;
        left: 200px;
        top: 55px;
        font-size: 13px;

        a {
          color: #666666;
        }
      }

      .number {
        color: #999999;
        position: absolute;
        right: 20px;
        top: 30px;
        font-size: 13px;

        .user {
          margin-right: 10px;
        }

        span {
          margin-right: 5px;
        }
      }

      .delete {
        color: red;
        font-size: 13px;
        position: absolute;
        right: 10px;
        top: 55px;
        cursor: pointer;
        user-select: none;
      }

      .edit{
        color: #02a7f0;
        font-size: 13px;
        position: absolute;
        right: 50px;
        top: 55px;
        cursor: pointer;
        user-select: none;
      }
    }

    .pagination {
      padding: 20px 50px;
    }
  }
}
</style>