
export default {
    columns:[
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '手机号码',
            key: 'Phone',
            dataIndex: 'Phone',
        },
        {
            title: '分类贡献值',
            key: 'TolType',
            dataIndex: 'TolType',
            scopedSlots: { customRender: 'TolType' },
        },
        {
            title: '总贡献值',
            key: 'ToNumber',
            dataIndex: 'ToNumber',
            scopedSlots: { customRender: 'ToNumber' },
        },
    ],
}
