export default {
    columns: [
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '手机号码',
            key: 'Phone',
            dataIndex: 'Phone',
        },
        {
            title: '委员会类型',
            key: 'Class',
            dataIndex: 'Class',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        }
    ],
}
