<template>
  <div>
    <a-modal
        title="处理留言"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="处理意见">
          <a-input
              placeholder="请输入处理意见"
              v-decorator="[
                            'Opinion',
                            {  rules: [{message: '请输入处理意见' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="处理结果">
          <a-select
              v-decorator="[
                'InShow',
              {  rules: [{ required: true, message: '请选择处理结果' }] },
          ]">
            <a-select-option value="已处理">已处理</a-select-option>
            <a-select-option value="无法处理">无法处理</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import {opinion} from "@/api/Admin/ObjectDetails/Interest";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
    };
  },
  methods: {
    openAddUser() {
      this.$refs.AddUser.open()
    },
    //打开
    open(id) {
      this.visible = true;
      this.User = []
      this.form.resetFields()
      this.id = id
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let id = this.id
          let Opinion = values.Opinion
          let InShow = values.InShow

          opinion({id, Opinion, InShow}).then(res => {
            if (res.errorCode == 200) {
              this.$message.success(res.payload);
              this.handleCancel()
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    handleCancel() {
      this.visible = false;
      this.$parent.getdata()
    },
  },
};
</script>

<style lang="scss">
.button {
  display: block;
}
</style>