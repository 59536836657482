
export default {
    columns:[
        {
            title: '活动项目',
            dataIndex: 'Title',
            key: 'Title',
        },
        {
            title: '新增时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
        },
        {
            title: '状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
