<template>
  <div class="Distribution">
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             :rowKey="(record, index) => { return index }"
             class="table"
             :loading="loading">
        <span slot="InShow" slot-scope="tags">
        <a-badge v-if="tags=='待生成'" status="warning" text="待生成"/>
          <a-badge v-if="tags=='已生成'" status="warning" text="已生成"/>
          <a-badge v-if="tags=='签署中'" status="processing" text="签署中"/>
          <a-badge v-if="tags=='已取消'" status="error" text="已取消"/>
          <a-badge v-if="tags=='签署完成'" status="success" text="签署完成"/>
    </span>
            <span slot="Operate" slot-scope="text,record" >
                <a-button  type="link" html-type="submit" @click="openFlowId(record.FlowId)">查看签署文件</a-button>
            </span>
    </a-table>
  </div>
</template>

<script>
import indexTest from "@/pages/Admin/pages/ObjectDetails/Com/Contract/moble/indexTest";
import {find, lookFlowId} from "@/api/Admin/ObjectDetails/Contract";

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  components: {},
  mounted() {
    this.getdata()
  },
  methods: {
    openFlowId(FlowId) {
      lookFlowId({FlowId}).then(res=>{
        if (res.errorCode == 200) {
          this.$message.success('获取文件成功');
          window.open(res.payload)
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.loading = true
      let CompanyID = this.$store.state.user.info.CompanyID
      let ObjectID = this.$route.query.Id
      let Current = this.pagination.current
      find({CompanyID, ObjectID, Current}).then(res=>{
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          let Total = res.payload.Total

          for (let i in data) {
            let time = data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }

          this.pagination.total = Total
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
      // let userInfo = this.$store.state.user.info
      // let HospitalID = userInfo.HospitalID
      // let Class = userInfo.Class

    },
    getSumRegexData(data) {
      this.loading = false
      this.data = data
      this.pagination.current = 1
      this.pagination.total = 0
    },
    getRegexData() {
      this.loading = true
    },
    resetting() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.getdata()
    }
  }
}
</script>

<style scoped lang="scss">

.Distribution{
  width: 100%;
  height: 600px;
  background-color: #ffffff;
  .topBlock{
    width: 100%;
    height: 10px;
  }
  .table{
    width: 98%;
    margin: 0 auto;
  }
}
.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}
</style>
