
export default {
    columns:[
        {
            title: '社会信用代码',
            dataIndex: 'ThirdPartyUserId',
            key: 'ThirdPartyUserId',
        },
        {
            title: '机构昵称',
            dataIndex: 'CompanyName',
            key: 'CompanyName',
        },
        {
            title: '负责人姓名',
            key: 'UserName',
            dataIndex: 'UserName',
        },
        {
            title: '负责人联系方式',
            key: 'UserPhone',
            dataIndex: 'UserPhone',
        },
        {
            title: '新增时间',
            key: 'Time',
            dataIndex: 'Time',
            width:100
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
