<template>
  <div>
    <a-modal
        title="详情"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="handleCancel"
        :width="1000"
    >
      <a-steps :current="1" class="steps">
        <a-step title="确认发起签字"/>
        <a-step title="等待签字完毕"/>
        <a-step title="完成"/>
      </a-steps>
      <a-descriptions bordered>
        <a-descriptions-item label="社区项目">
          万人共创
        </a-descriptions-item>
        <a-descriptions-item label="发生额度">
          100000
        </a-descriptions-item>
        <a-descriptions-item label="操作类型" :span="2">
          增加额度
        </a-descriptions-item>
        <a-descriptions-item label="申请时间">
         2023-9-14
        </a-descriptions-item>
        <a-descriptions-item label="状态" :span="3">
          <a-badge status="processing" text="审批中" />
        </a-descriptions-item>
      </a-descriptions>
      <template slot="footer">
        <a-button class="button">发起签署</a-button>
        <a-button class="button">确认签署状态</a-button>
      </template>

    </a-modal>
  </div>
</template>
<script>

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form:{}
    };
  },
  methods: {
    //打开
    open() {
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
.steps{
  margin-top: 20px;
  margin-bottom: 40px;
}
.button{
  margin-top: 10px;
}
</style>