<template>
  <div class="Distribution">
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             :rowKey="(record, index) => { return index }"
             class="table"
             :loading="loading">

    </a-table>
  </div>
</template>

<script>
import indexTest from "@/pages/Admin/pages/ObjectDetails/Com/Log/moble/indexTest";

let {columns,data} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: data,
      query: {
        name: "",
      },
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  components: {},
  mounted() {

  },
  methods: {
    GoToDetails(value) {
      console.log(value)
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
    },
    getdata() {
      this.loading = true

      // let userInfo = this.$store.state.user.info
      // let HospitalID = userInfo.HospitalID
      // let Class = userInfo.Class

    },
    getSumRegexData(data) {
      this.loading = false
      this.data = data
      this.pagination.current = 1
      this.pagination.total = 0
    },
    getRegexData() {
      this.loading = true
    },
    resetting() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.getdata()
    }
  }
}
</script>

<style scoped lang="scss">

.Distribution{
  width: 100%;
  height: 600px;
  background-color: #ffffff;
  .topBlock{
    width: 100%;
    height: 10px;
  }
  .table{
    width: 98%;
    margin: 0 auto;
  }
}
.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}
</style>
