export default {
    columns: [
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '手机号码',
            key: 'Phone',
            dataIndex: 'Phone',
        },
        {
            title: '支付工具',
            dataIndex: 'PayClass',
            key: 'PayClass',
            scopedSlots: { customRender: 'PayClass' },
        },
        {
            title: '奖金状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },
        {
            title: '奖金金额',
            key: 'TransAmount',
            dataIndex: 'TransAmount',
            scopedSlots: { customRender: 'TransAmount' },
        },
        {
            title: '支付时间',
            key: 'PayTime',
            dataIndex: 'PayTime',
            scopedSlots: { customRender: 'PayTime' },
        }
    ],
}
