import request from '../../../../utils/request';

//导出模板
export const exportTemplate = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Main/Introduction/exportTemplate',
        method: 'post',
        data: query
    });
};

export const add = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Admin/Object/Details/Main/Introduction/add',
        method: 'post',
        data: query
    });
};
