<template>
  <div>
    <a-modal
        title="奖金详情"
        :visible="visible"
        @cancel="handleCancel"
        :width="1000"
    >
      <a-steps :current="steps" class="steps" v-if="data.InShow!='已取消'">
        <a-step title="等待发起签署"/>
        <a-step title="确认发起签字"/>
        <a-step title="等待签字完毕"/>
        <a-step title="完成"/>
      </a-steps>
      <a-descriptions bordered>
        <a-descriptions-item label="社区项目">
          {{ data.ObjectName }}
        </a-descriptions-item>
        <a-descriptions-item label="活动项目">
          {{ data.ContName }}
        </a-descriptions-item>
        <a-descriptions-item label="创建时间">
          {{ data.NewTime }}
        </a-descriptions-item>
        <a-descriptions-item label="状态">
          <a-badge v-if="data.InShow=='待生成'" status="warning" text="待生成"/>
          <a-badge v-if="data.InShow=='已生成'" status="warning" text="已生成"/>
          <a-badge v-if="data.InShow=='签署中'" status="processing" text="签署中"/>
          <a-badge v-if="data.InShow=='已取消'" status="error" text="已取消"/>
          <a-badge v-if="data.InShow=='签署完成'" status="success" text="签署完成"/>
        </a-descriptions-item>
        <a-descriptions-item label="参与签署方" :span="2">
          <div class="Committee" v-for="(item,index) in data.User_Pin" :key="index">{{ item.Name }}-{{ item.Phone }}-{{
              item.Class
            }}
            <a-icon type="close" @click="handleClose(index)" class="close" v-if="data.InShow=='待生成'"/>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="奖金详情" v-if="data.InShow!='已取消'">
          <a-button type="link" @click="openCont_Data">详情</a-button>
        </a-descriptions-item>
      </a-descriptions>
      <template slot="footer">
        <a-button class="button" @click="openaddCommittee" v-if="data.InShow=='待生成'" :disabled="confirmLoading">
          添加委员会成员
        </a-button>
        <a-button class="button" @click="generateFile" v-if="data.InShow=='待生成'" :loading="confirmLoading">生成文件
        </a-button>
        <a-button class="button" @click="lookFileId" v-if="data.InShow=='已生成'">查看生成文件</a-button>
        <a-button class="button" @click="initiateObject" v-if="data.InShow=='已生成'" :loading="confirmLoading">
          发起签署
        </a-button>
        <a-button class="button" v-if="data.InShow=='签署中'" @click="ConfirmUserlist" :loading="confirmLoading">
          确认签署状态
        </a-button>
        <a-button class="button" v-if="data.InShow=='签署完成'" @click="lookflowId"
                  :loading="confirmLoading">
          查看签署文件
        </a-button>
        <a-button class="button" v-if="data.InShow=='签署中'||data.InShow=='待生成'||data.InShow=='已生成'"
                  @click="ExitConfir" :loading="confirmLoading" type="danger">
          取消&终止签署流程
        </a-button>
        <span v-if="data.InShow=='已取消'">该状态下无法进行操作</span>
      </template>
    </a-modal>
    <Cont_Data ref="Cont_Data"></Cont_Data>
    <addCommittee ref="addCommittee"></addCommittee>
  </div>
</template>
<script>
import Cont_Data from './Cont_Data.vue'
import addCommittee from './addCommittee.vue'
import {
  getFindObject, generateFile, InitiateObject, ExitConfirm, Confirm,
  ConfirmUserList, lookFlowId, LookFileId
} from "@/api/Admin/ObjectDetails/Bonus";

export default {
  data() {
    return {
      id: '',
      visible: false,
      confirmLoading: false,
      data: {},
      steps: 0,
    };
  },
  methods: {
    handleClose(index) {
      if (this.data.InShow == '待生成') {
        let Committee = JSON.parse(JSON.stringify(this.data.Committee))
        Committee.splice(index, 1)
        this.data.Committee = Committee;
      }
    },
    ExitConfir() {
      let id = this.id
      let that = this
      this.$confirm({
        title: '是否终止&取消该签署流程吗?',
        onOk() {
          that.confirmLoading = true
          ExitConfirm({id}).then(res => {
            that.confirmLoading = false
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getData()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    },
    ConfirmUserlist() {
      let id = this.id
      this.confirmLoading = true
      ConfirmUserList({id}).then(res => {
        if (res.errorCode == 200) {
          let payload = res.payload
          let userText = ''
          let Tnumber = 0
          for (let i in payload) {
            if (payload[i].class == '未签') {
              userText += payload[i].name + '-未签署' + ' '
              Tnumber++
            }
          }
          if (Tnumber > 0) {
            this.confirmLoading = false
            this.$message.error(userText);
          } else {
            Confirm({id}).then(res => {
              this.confirmLoading = false
              if (res.errorCode == 200) {
                this.$message.success(res.payload);
                this.getData()
              } else {
                this.$message.error(res.errorMsg);
              }
            })
          }
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    lookFileId() {
      let id = this.id
      LookFileId(id).then(res => {
        if (res.errorCode == 200) {
          window.open(res.payload)
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    lookflowId() {
      let id = this.id
      lookFlowId(id).then(res => {
        if (res.errorCode == 200) {
          window.open(res.payload)
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    generateFile() {
      let User_Pin = this.data.User_Pin
      let id = this.id

      if (User_Pin.length == 0) {
        this.$message.error('未添加参与签署人员');
        return;
      }

      this.confirmLoading = true
      generateFile({User_Pin, id}).then(res => {
        this.confirmLoading = false
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.getData()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    initiateObject() {
      let that = this
      let id = this.id
      this.$confirm({
        title: '是否确认发起签署吗?',
        onOk() {
          that.confirmLoading = true
          InitiateObject({id}).then(res => {
            that.confirmLoading = false
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getData()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    },
    //添加参与签署方
    addComUser(Name, Phone, Class) {
      let add = {Name, Phone, Class}
      let Committee = JSON.parse(JSON.stringify(this.data.User_Pin))
      Committee.push(add)
      this.data.User_Pin = Committee
      this.$message.success('添加成功');
    },
    openaddCommittee() {
      let ObjectID = this.data.ObjectID
      this.$refs.addCommittee.open(ObjectID)
    },
    openCont_Data() {
      let Object_ID = this.id
      this.$refs.Cont_Data.open(Object_ID, this.data.InShow)
    },
    //打开
    open(id) {
      this.id = id
      this.visible = true
      this.getData()
    },
    getData() {
      let id = this.id
      getFindObject(id).then(res => {
        if (res.errorCode == 200) {
          let data = res.payload
          let time = data.NewTime
          let day = new Date(time);
          data.NewTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          if (!data.User_Pin) {
            data.User_Pin = []
          } else {
            data.User_Pin = JSON.parse(data.User_Pin)
          }
          this.data = data
          this.stepsClass(data.InShow)


          if (data.InShow == '待生成') {
            this.steps = 0
          }
          if (data.InShow == '已生成') {
            this.steps = 1
          }
          if (data.InShow == '签署中') {
            this.steps = 2
          }
          if (data.InShow == '签署完成') {
            this.steps = 3
          }


        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    handleCancel() {
      this.visible = false;
      this.$parent.getdata()
    },
    stepsClass(value) {
      if (value == '审核中') {
        this.steps = 0
      } else if (value == '待发起') {
        this.steps = 1
      } else if (value == '待发起') {
        this.steps = 2
      } else if (value == '签署中') {
        this.steps = 3
      } else if (value == '已完成') {
        this.steps = 4
      }
    }
  },
  components: {Cont_Data, addCommittee}
};
</script>

<style lang="scss">
.steps {
  margin-top: 20px;
  margin-bottom: 40px;
}

.button {
  display: inline-block !important;
  margin-top: 10px;
}

.Committee {
  font-size: 12px;
}

.close {
  color: red;
  cursor: pointer;
  margin-left: 10px;
}
</style>