<template>
<div class="Nav">
  <div class="block" v-bind:class="{open:TClass=='概况信息'}" @click="switchClass('概况信息')">概况信息</div>
  <div class="block"  v-bind:class="{open:TClass=='项目报道'}" @click="switchClass('项目报道')">项目报道</div>
  <div class="block"  v-bind:class="{open:TClass=='贡献值派发记录'}" @click="switchClass('贡献值派发记录')">贡献值派发记录</div>
  <div class="block"  v-bind:class="{open:TClass=='奖金派发列表'}" @click="switchClass('奖金派发列表')">奖金派发列表</div>
  <div class="block"  v-bind:class="{open:TClass=='合同列表'}" @click="switchClass('合同列表')">合同列表</div>
  <div class="block"  v-bind:class="{open:TClass=='项目委员会'}" @click="switchClass('项目委员会')">项目委员会</div>
  <div class="block"  v-bind:class="{open:TClass=='留言反馈'}" @click="switchClass('留言反馈')">留言反馈</div>
  <div class="block"  v-bind:class="{open:TClass=='操作记录'}" @click="switchClass('操作记录')">操作记录</div>
  <div class="block"  v-bind:class="{open:TClass=='投票管理'}" @click="switchClass('投票管理')">投票管理</div>
</div>
</template>

<script>
export default {
  props: {
    TClass: {
      type: String
    }
  },
  methods: {
    switchClass(value) {
      if (value == this.TClass) {
        return
      }
      this.$parent.switchClass(value)
    }
  }
}
</script>

<style scoped lang="scss">
.Nav{
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  .block{
    display: inline-block;
    color: #666666;
    width: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;
    user-select: none;
  }
  .open{
    color: #02a7f0;
  }
}
</style>