var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"编辑商品详情","okText":"提交","visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"width":"550px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-form-item',{attrs:{"label":"缩略图","required":true}},[(_vm.thumbnailUrl)?_c('img',{staticStyle:{"width":"80px","height":"80px","display":"block"},attrs:{"src":_vm.thumbnailUrl,"alt":""}}):_vm._e(),_c('a-button',{attrs:{"icon":"upload"},on:{"click":_vm.openEditThumbnail}},[_vm._v("上传缩略图")])],1),_c('a-form-item',{attrs:{"label":"视频"}},[_c('a-upload',{attrs:{"name":"file","multiple":false,"before-upload":_vm.beforePosterUploadVideo,"remove":_vm.handlePosterRemoveVideo,"file-list":_vm.videoFile,"action":_vm.videoUrl,"showUploadList":false,"headers":{
                      'X-Requested-With':null
                  }},on:{"change":_vm.addCoverVideo}},[_c('a-button',[_c('a-icon',{attrs:{"type":"video-camera"}}),_vm._v(" 上传视频 ")],1)],1),(_vm.CoverVideoUrl)?_c('a-button',{staticStyle:{"margin-left":"10px","color":"red"},attrs:{"type":"link"},on:{"click":_vm.deleteVideo}},[_vm._v(" 删除视频 ")]):_vm._e(),(_vm.CoverVideoUrl)?_c('video',{staticStyle:{"width":"300px"},attrs:{"src":_vm.CoverVideoUrl,"controls":""}}):_vm._e()],1),_c('a-form-item',{attrs:{"label":"海报"}},[(_vm.posterUrl)?_c('img',{staticStyle:{"width":"300px","display":"block"},attrs:{"src":_vm.posterUrl,"alt":""}}):_vm._e(),_c('a-upload',{attrs:{"name":"file","multiple":false,"before-upload":_vm.beforePosterUpload,"remove":_vm.handlePosterRemove,"action":_vm.imageUrl,"headers":{
                      'X-Requested-With':null
                  },"show-upload-list":false},on:{"change":_vm.addPoster}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传海报 ")],1)],1),(_vm.posterUrl)?_c('a-button',{staticStyle:{"margin-left":"20px"},attrs:{"icon":"delete"},on:{"click":_vm.deleteImg}},[_vm._v("清除图片")]):_vm._e()],1),_c('a-form-item',{attrs:{"label":"商品简介","required":true}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'commodityText',
                              {  rules: [{ required: true, message: '请输入商品简介' }], initialValue: _vm.isEdit ? _vm.commodityDetail.commodityText : '' },
                          ]),expression:"[\n                              'commodityText',\n                              {  rules: [{ required: true, message: '请输入商品简介' }], initialValue: isEdit ? commodityDetail.commodityText : '' },\n                          ]"}],attrs:{"placeholder":"请输入商品简介","auto-size":{ minRows: 3, maxRows: 5 }}})],1),_c('a-form-item',{attrs:{"label":"兑换须知","required":true}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'notification',
                              {  rules: [{ required: true, message: '请输入兑换须知' }], initialValue: _vm.isEdit ? _vm.commodityDetail.notice : '' },
                          ]),expression:"[\n                              'notification',\n                              {  rules: [{ required: true, message: '请输入兑换须知' }], initialValue: isEdit ? commodityDetail.notice : '' },\n                          ]"}],attrs:{"placeholder":"请输入兑换须知","auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1),_c('editThumbnail',{ref:"editThumbnail"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }