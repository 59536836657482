import request from '../../utils/request';

//发送短信验证码
export const sendingCode = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Login/sendingCode',
        method: 'post',
        data: query
    });
};

//修改密码
export const modifyPow = query => {
    return request({
        url: process.env.VUE_APP_URL + 'SetUp/Secure/modifyPow',
        method: 'post',
        data: query
    });
};