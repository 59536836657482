<template>
  <div>
    <a-modal
        title="额度调整"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :model="form">
        <a-form-item label="发生额度">
          <a-input
              placeholder="请输入调整额度"
              v-model="form.Mobile"
          />
        </a-form-item>
        <a-form-item label="类型">
          <a-select style="width: 120px" @change="handleChange">
            <a-select-option value="增加额度">增加额度</a-select-option>
            <a-select-option value="减少额度">减少额度</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form:{}
    };
  },
  methods: {
    handleChange(info) {
      console.log(info)
    },
    //发送验证码
    onSearch(){

    },
    //打开
    open() {
      this.visible = true;
    },
    handleOk() {
      this.ModalText = 'The modal will be closed after two seconds';
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel() {

      this.visible = false;
    },
  },
};
</script>
