
export default {
    columns:[
        {
            title: '发生额度',
            dataIndex: 'Number',
            key: 'Number',
        },
        {
            title: '操作类型',
            dataIndex: 'Class',
            key: 'Class',
        },
        {
            title: '申请时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
